import { Stack, Typography } from '@mui/material';

function CopyrightFooter() {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            divider={<Typography variant={'caption'}> | </Typography>}
            alignItems="center"
            sx={{
                p: 1,
                gap: 1,
            }}
        >
            <Typography variant={'caption'}>© estateXchange Pty Ltd</Typography>
            <Typography variant={'caption'}>Patent Pending</Typography>
        </Stack>
    );
}

export default CopyrightFooter;
