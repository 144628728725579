import { Badge, Box, Chip, IconButton, TableCell, Tooltip } from '@mui/material';
import { IconMessageDots } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceMeta } from '../../api/workspace';
import { preventClickthrough } from '../../utils';
import CountBadge from '../CountBadge';
import DateTimeString from '../DateTimeString';
import TaskIcon, { TaskStatusEnum } from '../TaskIcon';
import TruncatedTableCell from '../TruncatedTableCell';
import { Column } from './WorkspacesTable';

export interface CellComponentProps {
    workspace: WorkspaceMeta;
    column: Column;
}

function WorkspaceTableRowIdentifier({ workspace }: CellComponentProps) {
    return <TruncatedTableCell>{workspace.external_id}</TruncatedTableCell>;
}

function WorkspaceTableRowDeceasedName({ workspace }: CellComponentProps) {
    return <TruncatedTableCell>{workspace.deceased_name}</TruncatedTableCell>;
}

function WorkspaceTableRowBankReference({ workspace }: CellComponentProps) {
    return <TruncatedTableCell>{workspace.membership?.participant_reference}</TruncatedTableCell>;
}

function WorkspaceTableRowReference({ workspace }: CellComponentProps) {
    return <TruncatedTableCell>{workspace.case_reference}</TruncatedTableCell>;
}

function WorkspaceTableRowCreatedAt({ workspace }: CellComponentProps) {
    return <TableCell>{DateTimeString(workspace.created_at)}</TableCell>;
}

function WorkspaceTableRowTimeSinceInvitation({ workspace }: CellComponentProps) {
    return <TableCell>{DateTimeString(workspace.membership?.invited_at)}</TableCell>;
}

function WorkspaceTableRowDeathCertificateRegistrationNumber({ workspace }: CellComponentProps) {
    return <TableCell>{workspace.death_certificate_number}</TableCell>;
}

function WorkspaceTableRowStatus({ workspace, column }: CellComponentProps) {
    const status = workspace.status;

    return (
        <TableCell>
            {status === 'draft' ? (
                <Chip label="Draft" size="small" color="default" variant="filled" />
            ) : status === 'in_progress' ? (
                <Chip label="In progress" size="small" color="secondary" variant="filled" />
            ) : status === 'closed' ? (
                <Chip label="Close" size="small" color="success" variant="filled" />
            ) : null}
        </TableCell>
    );
}

function WorkspaceStatusChip({ workspace }: { workspace: WorkspaceMeta }) {
    if (workspace.membership === null) {
        return null;
    }

    const status = workspace.membership.status;

    // switch on status to selectcolor and label
    var statusProps;
    switch (status) {
        case 'closed':
            statusProps = {
                label: 'Closed',
                color: 'default',
            };
            break;
        case 'invited':
            statusProps = {
                label: 'Invited',
                color: 'primary',
            };
            break;
        case 'declined':
            statusProps = {
                label: 'Declined',
                color: 'default',
            };
            break;
        case 'in_progress':
            statusProps = {
                label: 'In progress',
                color: 'success',
            };
            break;
        default:
            statusProps = {
                label: 'Unknown',
                color: 'default',
            };
    }

    return (
        <Chip
            label={statusProps?.label}
            size="small"
            color={statusProps?.color as any}
            variant="filled"
            sx={{ width: '100%' }}
        />
    );
}

function WorkspaceTableRowProofOfDeathStatus({ workspace, column }: CellComponentProps) {
    const navigate = useNavigate();
    if (workspace.membership?.status !== 'in_progress') {
        return (
            <TableCell colSpan={5} sx={{ px: 'rem !important' }}>
                <WorkspaceStatusChip workspace={workspace} />
            </TableCell>
        );
    }

    return (
        <TableCell>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 100,
                }}
            >
                <TaskIcon
                    onClick={(e) => {
                        preventClickthrough(e);
                        navigate(`/workspace/m/${workspace.id}/pod`);
                    }}
                    status={workspace.membership.proof_of_death_status as TaskStatusEnum}
                    sx={{ alignSelf: 'center' }}
                />
            </Box>
        </TableCell>
    );
}

function WorkspaceTableRowProofOfAuthorityStatus({ workspace, column }: CellComponentProps) {
    const navigate = useNavigate();

    if (workspace.membership?.status !== 'in_progress') {
        return null;
    }

    return (
        <TableCell>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 120,
                }}
            >
                <TaskIcon
                    onClick={(e) => {
                        preventClickthrough(e);
                        navigate(`/workspace/m/${workspace.id}/poa`);
                    }}
                    status={workspace.membership.proof_of_authority_status as TaskStatusEnum}
                />
            </Box>
        </TableCell>
    );
}

function WorkspaceTableRowProofOfIdentityStatus({ workspace, column }: CellComponentProps) {
    const navigate = useNavigate();
    if (workspace.membership?.status !== 'in_progress') {
        return null;
    }

    return (
        <TableCell align="center">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 115,
                }}
            >
                <TaskIcon
                    status={workspace.membership.proof_of_identity_status as TaskStatusEnum}
                    onClick={(e) => {
                        preventClickthrough(e);
                        navigate(`/workspace/m/${workspace.id}/poi`);
                    }}
                />
            </Box>
        </TableCell>
    );
}

function WorkspaceTableRowAccountInstructionsStatus({ workspace, column }: CellComponentProps) {
    const navigate = useNavigate();
    if (workspace.membership?.status !== 'in_progress') {
        return null;
    }

    return (
        <TableCell align="center">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 115,
                }}
            >
                <TaskIcon
                    onClick={(e) => {
                        preventClickthrough(e);
                        navigate(`/workspace/m/${workspace.id}/account-instructions`);
                    }}
                    status={workspace.membership.forms_and_indemnities_status as TaskStatusEnum}
                />
            </Box>
        </TableCell>
    );
}

function WorkspaceTableRowParticipantRequests({ workspace, column }: CellComponentProps) {
    const navigate = useNavigate();

    if (workspace.membership?.status !== 'in_progress') {
        return null;
    }

    const showBadge = workspace.membership?.requests_require_action > 0;

    return (
        <TableCell padding="none" sx={{ right: 0, p: '0 !important' }}>
            <Box sx={{ display: 'flex', justifyContent: 'right', p: 0, pr: 2.5 }}>
                <Badge variant="dot" color="secondary" overlap="circular" invisible={!showBadge}>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            preventClickthrough(e);
                            navigate(`/workspace/m/${workspace.id}/requests?`);
                        }}
                        sx={{ p: 0.5 }}
                    >
                        <IconMessageDots />
                    </IconButton>
                </Badge>
            </Box>
        </TableCell>
    );
}

function WorkspaceTableRowCountBadge({ workspace }: { workspace: WorkspaceMeta }) {
    if (workspace.all_action_required_items_count === 0) return <TableCell />;

    const plural = workspace.all_action_required_items_count > 1;
    const message = `${workspace.all_action_required_items_count} item${plural ? 's' : ''} requires your attention`;

    return (
        <TableCell>
            <Tooltip title={message} disableInteractive>
                <Box sx={{ display: 'inline-block' }}>
                    <CountBadge
                        count={workspace.all_action_required_items_count}
                        sx={{
                            ml: 1,
                        }}
                    />
                </Box>
            </Tooltip>
        </TableCell>
    );
}

export {
    WorkspaceTableRowAccountInstructionsStatus,
    WorkspaceTableRowBankReference,
    WorkspaceTableRowCountBadge,
    WorkspaceTableRowCreatedAt,
    WorkspaceTableRowDeathCertificateRegistrationNumber,
    WorkspaceTableRowDeceasedName,
    WorkspaceTableRowIdentifier,
    WorkspaceTableRowParticipantRequests,
    WorkspaceTableRowProofOfAuthorityStatus,
    WorkspaceTableRowProofOfDeathStatus,
    WorkspaceTableRowProofOfIdentityStatus,
    WorkspaceTableRowReference,
    WorkspaceTableRowStatus,
    WorkspaceTableRowTimeSinceInvitation,
};
