import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Collapse, Grid, Paper, Stack, Typography } from '@mui/material';
import { IconExternalLink, IconFileCertificate } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { DatePickerElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import { createDeathCertificateVerificationMutation } from '../../api/standaloneTools/DeathCertificateVerification';
import ADCBadge from '../../components/ADCBadge';
import DateTimeString from '../../components/DateTimeString';
import DownloadButton from '../../components/DownloadButton';
import FormGrid from '../../components/FormGrid';
import StateSelectField from '../../components/fields/StateSelectField';
import {
    characterLimit,
    noNumbers,
    noSpecialChars,
    onlyLettersAndNumbers,
    requiredDate,
    requiredString,
} from '../../validationRules';
import ToolPageLayout from './ToolPageLayout';

const validationSchema = z.object({
    given_names: requiredString.and(characterLimit(70)).and(noNumbers).and(noSpecialChars),
    family_name: requiredString.and(characterLimit(40)).and(noNumbers).and(noSpecialChars),
    registration_number: requiredString.and(characterLimit(10)).and(onlyLettersAndNumbers),
    registration_year: requiredDate,
    registration_state: z.enum(['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT', 'ACT']),
});

type DeathCertificateVerificationFields = z.infer<typeof validationSchema>;

const DeathCertificateVerificationTool = () => {
    const formMethods = useForm<DeathCertificateVerificationFields>({
        mode: 'onChange',
        defaultValues: {
            given_names: '',
            family_name: '',
            registration_number: '',
            registration_year: undefined,
            registration_state: undefined,
        },
        resolver: zodResolver(validationSchema),
    });

    const {
        reset,
        formState: { isDirty },
    } = formMethods;

    const verifyDeathCertificate = useMutation(createDeathCertificateVerificationMutation());

    const handleSubmit = () => {
        const values = formMethods.getValues();

        verifyDeathCertificate.mutate({
            given_names: values.given_names,
            family_name: values.family_name,
            registration_number: values.registration_number,
            registration_year: (values.registration_year as Date).getFullYear(),
            registration_state: values.registration_state,
        });
    };

    const handleReset = () => {
        reset();
        verifyDeathCertificate.reset();
    };

    return (
        <ToolPageLayout
            title="Death Certificate Verification"
            description="Confirm Death Certificate fields match official records"
        >
            <Paper
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    borderRadius: 5,
                    maxWidth: '100%',
                    p: 7,
                }}
            >
                <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                    <Stack gap={4}>
                        <Alert severity="info">
                            Complete all fields exactly as they appear in the Death Certificate
                        </Alert>

                        <FormGrid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    name="given_names"
                                    label="Given names"
                                    autoFocus
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    name="family_name"
                                    label="Family name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StateSelectField
                                    id="state"
                                    name="registration_state"
                                    label="Registration state or territory"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerElement
                                    name="registration_year"
                                    label="Registration year"
                                    views={['year']}
                                    maxDate={new Date()}
                                    sx={{
                                        width: '100%',
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    name="registration_number"
                                    label="Registration number"
                                    fullWidth
                                    helperText="Registration number format varies by state"
                                    required
                                />
                            </Grid>
                        </FormGrid>
                        <Stack
                            direction="row"
                            alignItems="baseline"
                            justifyContent="space-between"
                            gap={1}
                            sx={{ mt: 4 }}
                        >
                            <Stack direction="row" gap={1}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={verifyDeathCertificate.status === 'pending'}
                                    disabled={verifyDeathCertificate.status === 'pending'}
                                >
                                    Submit
                                </LoadingButton>
                                {isDirty ? (
                                    <Button color="inherit" onClick={handleReset}>
                                        Reset
                                    </Button>
                                ) : null}
                            </Stack>
                            <ADCBadge />
                        </Stack>
                    </Stack>
                    <Collapse in={!!verifyDeathCertificate.data?.valid}>
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{ mt: 5 }}
                            icon={<IconFileCertificate />}
                            action={
                                <DownloadButton
                                    url={verifyDeathCertificate.data?.result || ''}
                                    startIcon={<IconExternalLink />}
                                >
                                    View results
                                </DownloadButton>
                            }
                        >
                            Death Certificate detail verified
                            <Typography variant="body3">
                                Lookup conducted {DateTimeString(new Date())}
                            </Typography>
                        </Alert>
                    </Collapse>
                    <Collapse in={verifyDeathCertificate.data?.valid === false}>
                        <Alert variant="filled" severity="warning" sx={{ mt: 5 }}>
                            Death Certificate detail verification failed
                            <Typography variant="body3">
                                Lookup conducted {DateTimeString(new Date())}
                            </Typography>
                        </Alert>
                    </Collapse>
                </FormContainer>
            </Paper>
        </ToolPageLayout>
    );
};

export default DeathCertificateVerificationTool;
