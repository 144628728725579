import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    InputAdornment,
    LinearProgress,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { IconArrowBackUp, IconHistory } from '@tabler/icons-react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';
import { queryClient } from '../../api/queryClient';
import {
    DeachCheckDataSet,
    DeathCheckBulkTransaction,
    DeathCheckBulkTransactionList,
    createDeathCheckTransactionMutation,
    deathCheckTransactionListQuery,
} from '../../api/standaloneTools/FactOfDeath';
import DateTimeString from '../../components/DateTimeString';
import DownloadButton from '../../components/DownloadButton';
import FormGrid from '../../components/FormGrid';
import InfoTooltip from '../../components/InfoTooltip';
import Placeholder from '../../components/Placeholder';
import QueryProgress from '../../components/QueryProgress';
import DragAndDropFileField from '../../components/fields/DragAndDropFileField';
import {
    characterLimit,
    fileName,
    noSpecialChars,
    optionalString,
    requiredFile,
    requiredString,
} from '../../validationRules';
import { dataSets } from './FactOfDeathDataSets';
import ToolPageLayout from './ToolPageLayout';

function FactOfDeathBulkTool() {
    const [showHistory, setShowHistory] = useState(false);
    return (
        <ToolPageLayout
            title={
                <Stack direction="row" gap={1}>
                    Fact of Death Lookup (Bulk)
                </Stack>
            }
            right={
                showHistory ? (
                    <Button
                        variant="contained"
                        onClick={() => setShowHistory(false)}
                        startIcon={<IconArrowBackUp />}
                    >
                        New lookup
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        color="inherit"
                        startIcon=<IconHistory />
                        onClick={() => setShowHistory(true)}
                    >
                        History
                    </Button>
                )
            }
            description="Confirm whether a death has been registered in Australia - bulk lookup (10+)"
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                    borderRadius: 5,
                    gap: 3,
                }}
            >
                {showHistory ? (
                    <FactOfDeathQueryTable />
                ) : (
                    <FactOfDeathBulkQueryForm onSubmit={() => setShowHistory(true)} />
                )}
            </Paper>
        </ToolPageLayout>
    );
}

const validationSchema = z.object({
    dataSet: requiredString,
    description: optionalString.and(characterLimit(100)).and(noSpecialChars),
    fileName: requiredString.and(characterLimit(20)).and(fileName),
    file: requiredFile,
});

type CreateFactOfDeathQueryFields = z.infer<typeof validationSchema>;

function FactOfDeathBulkQueryForm({ onSubmit }: { onSubmit?: () => void }) {
    const formMethods = useForm<CreateFactOfDeathQueryFields>({
        mode: 'onChange',
        defaultValues: {
            dataSet: 'ADCEXACTNAMEDOB',
            fileName: '',
            file: undefined,
            description: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const handleReset = formMethods.reset;
    const isDirty = formMethods.formState.isDirty;
    const { showModal } = useModal();

    const watchDataMatchingMethod = formMethods.watch('dataSet') as DeachCheckDataSet;

    const createTransactionMutation = useMutation(createDeathCheckTransactionMutation());

    const handleSubmit = () => {
        const values = formMethods.getValues();
        createTransactionMutation
            .mutateAsync({
                dataSet: values.dataSet as DeachCheckDataSet,
                outputFileName: values.fileName,
                uploadedFile: values.file,
                description: values.description,
            })
            .then(() => {
                queryClient.invalidateQueries(deathCheckTransactionListQuery());
                enqueueSnackbar('Query submitted.', { variant: 'success' });
                onSubmit?.();
            });
    };

    return (
        <FormContainer
            formContext={formMethods}
            onSuccess={handleSubmit}
            onError={(error) => {
                console.log(error);
            }}
        >
            <Stack gap={3} sx={{ p: 7 }}>
                <Stack gap={1}>
                    <Typography variant="subtitle1">1. Select the data matching method</Typography>
                    <Typography variant="body2">
                        The data matching method dictates the required data input fields.{'  '}
                        <Button
                            size="small"
                            color="inherit"
                            onClick={() => showModal(DataMatchingHelpDialog)}
                        >
                            Learn more
                        </Button>
                    </Typography>
                </Stack>

                <SelectElement
                    size="small"
                    name="dataSet"
                    label="Data matching method"
                    valueKey="value"
                    options={[
                        {
                            id: '1',
                            label: dataSets.ADCEXACTNAMEDOB.title,
                            value: 'ADCEXACTNAMEDOB',
                        },
                        {
                            id: '2',
                            label: dataSets.ADCEXACTNAMEPARTDOB.title,
                            value: 'ADCEXACTNAMEPARTDOB',
                        },
                        {
                            id: '3',
                            label: dataSets.ADCEXACTNAMEDOD.title,
                            value: 'ADCEXACTNAMEDOD',
                        },
                        {
                            id: '4',
                            label: dataSets.ADCEXACTNAMEPARTDOD.title,
                            value: 'ADCEXACTNAMEPARTDOD',
                        },
                        {
                            id: '5',
                            label: dataSets.ADCPARTNAMEEXACTDOB.title,
                            value: 'ADCPARTNAMEEXACTDOB',
                        },
                    ]}
                    fullWidth
                    required
                    SelectProps={{
                        sx: { minWidth: 200, width: '50%' },
                        endAdornment: (
                            <InfoTooltip
                                sx={{ mr: 3, mt: 1 }}
                                title={
                                    watchDataMatchingMethod
                                        ? dataSets[watchDataMatchingMethod].description
                                        : ''
                                }
                            />
                        ),
                    }}
                />

                <Stack gap={1} sx={{ pt: 4 }}>
                    <Typography variant="subtitle1"> 2. Collate your query data</Typography>
                    <Typography variant="body2">
                        Fact of Death Lookup (Bulk) requires query data to be structured correctly.
                    </Typography>
                </Stack>

                <Alert
                    severity="info"
                    action={
                        <DownloadButton
                            url="/static/exc/xlsx_templates/Bulk ADC Input Template.xlsx"
                            filename="Fact of Death Bulk Query Input Template.xlsx"
                        >
                            Download template
                        </DownloadButton>
                    }
                >
                    Please download and use the provided template to structure your query data.
                </Alert>

                <Stack gap={1} sx={{ pt: 4 }}>
                    <Typography variant="subtitle1">3. Submit the query</Typography>
                </Stack>
                <FormGrid>
                    <Grid item xs={12}>
                        <DragAndDropFileField name="file" label="Upload file" required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldElement
                            name="fileName"
                            label="Output file name"
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <InputAdornment position="end" sx={{ ml: 2, opacity: 1 }}>
                                            .xlsx
                                        </InputAdornment>
                                        <InfoTooltip title="Tell us what to call the results file." />
                                    </Stack>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldElement
                            name="description"
                            label="Description"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InfoTooltip
                                        title={
                                            'Include a short description of the lookup data for easy reference later on.'
                                        }
                                    />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="row" gap={1}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={createTransactionMutation.status === 'pending'}
                                disabled={createTransactionMutation.status === 'pending'}
                            >
                                Submit
                            </LoadingButton>
                            {isDirty ? (
                                <Button color="inherit" onClick={() => handleReset()}>
                                    Reset
                                </Button>
                            ) : null}
                        </Stack>
                    </Grid>
                </FormGrid>
            </Stack>
        </FormContainer>
    );
}

function FactOfDeathQueryTable() {
    const query = useInfiniteQuery<DeathCheckBulkTransactionList>({
        ...deathCheckTransactionListQuery(),
        placeholderData: (previousData, previousQuery) => previousData,
        refetchInterval: (query) => {
            const shouldRefetch = query.state.data?.pages.some((page) =>
                page.results.some(
                    (transaction: DeathCheckBulkTransaction) => !transaction.result_file
                )
            );

            return shouldRefetch ? 5000 : false;
        },
    });
    const transactions = (query.data?.pages.flatMap((page) => page.results) ||
        []) as DeathCheckBulkTransaction[];

    return (
        <QueryProgress query={query}>
            {transactions.length === 0 ? (
                <Placeholder
                    title="No queries yet"
                    description="Historic fact of death queries will appear here"
                />
            ) : (
                <TableContainer component={Paper} sx={{ p: 1 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Result</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Matching data set</TableCell>
                                <TableCell>Line items</TableCell>
                                <TableCell>Initiated</TableCell>
                                <TableCell>Initiated by</TableCell>
                                <TableCell>Expires</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell sx={{ mr: 4, pr: 4 }}>
                                        {!transaction.result_file ? (
                                            <LinearProgress
                                                sx={{
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: 'primary.dark',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Link
                                                component={RouterLink}
                                                target="_blank"
                                                underline="hover"
                                                to={transaction.result_file}
                                            >
                                                {transaction.output_file_name + '.xlsx'}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>{transaction.description}</TableCell>
                                    <TableCell>{dataSets[transaction.data_set].title}</TableCell>
                                    <TableCell>{transaction.entries_number}</TableCell>
                                    <TableCell>{DateTimeString(transaction.created_at)}</TableCell>
                                    <TableCell>{transaction.created_by.name}</TableCell>
                                    <TableCell>{DateTimeString(transaction.expires_at)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </QueryProgress>
    );
}

interface DataMatchingHelpDialogProps extends DialogProps {
    onClose: () => void;
}

function DataMatchingHelpDialog({ onClose, ...props }: DataMatchingHelpDialogProps) {
    return (
        <Dialog {...props} onClose={onClose} scroll="body" fullWidth maxWidth="md">
            <DialogTitle sx={{ pb: 1 }}>Data Matching Options</DialogTitle>
            <DialogContent
                sx={{
                    p: 1,
                }}
            >
                <ol>
                    {Object.entries(dataSets).map(([key, option]) => (
                        <li key={option.id}>
                            <strong>{option.title}</strong>
                            <p>{option.description}</p>
                        </li>
                    ))}
                </ol>
                <p>
                    <em>
                        *For high volume data where you are checking for previously unknown deaths,
                        option #5 is recommended.
                    </em>
                </p>
            </DialogContent>
            <DialogActions sx={{ pb: 0 }}>
                <Button variant="contained" onClick={() => onClose?.()}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FactOfDeathBulkTool;
