import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { IconBook, IconHelp, IconShield } from '@tabler/icons-react';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { appSettings } from '../appSettings';

function HelpMenu() {
    const popupState = usePopupState({ variant: 'popover', popupId: 'help-menu' });

    return (
        <>
            <Tooltip title="Help">
                <IconButton {...bindTrigger(popupState)} sx={{ color: 'white' }}>
                    <IconHelp size={26} />
                </IconButton>
            </Tooltip>

            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    component="a"
                    href="https://customer-hub.estatexchange.com.au/"
                    target="_blank"
                >
                    <ListItemIcon>
                        <IconBook />
                    </ListItemIcon>
                    <ListItemText>Customer Hub</ListItemText>
                </MenuItem>
                <MenuItem
                    component="a"
                    href="https://www.estatexchange.com.au/privacy-policy"
                    target="_blank"
                >
                    <ListItemIcon>
                        <IconShield />
                    </ListItemIcon>
                    <ListItemText>Privacy Policy</ListItemText>
                </MenuItem>
                <MenuItem
                    disableRipple
                    sx={{ '&:hover': { backgroundColor: 'transparent' }, cursor: 'default' }}
                >
                    <ListItemText
                        primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                    >
                        v{appSettings.releaseVersion}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}

export default HelpMenu;
