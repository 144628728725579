import { Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface PageLayoutProps extends PropsWithChildren {
    title?: ReactNode;
    subtitle?: ReactNode;
    actions?: ReactNode;
}

function PageLayout({ title, actions, children }: PageLayoutProps) {
    return (
        <Stack
            sx={{
                p: 8,
                pt: 6,
                mb: 0,
                borderRadius: 5,
                flexGrow: 1,
                backgroundColor: 'background.paper',
                overflowX: 'hidden',
            }}
        >
            <Stack
                justifyContent="space-between"
                sx={{
                    py: 1,
                    mb: 2,
                }}
            >
                <Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="h2" noWrap sx={{ flexGrow: 1, pl: -1 }}>
                            {title}
                        </Typography>
                        <Stack direction="row"></Stack>
                        {actions}
                    </Stack>
                </Stack>
            </Stack>

            <Stack sx={{ pl: 0.5 }} gap={4}>
                {children}
            </Stack>
        </Stack>
    );
}

export default PageLayout;
