export const dataSets = {
    ADCEXACTNAMEDOB: {
        id: 1,
        title: 'Exact name and date of birth',
        description:
            'Record will match if all of given name, family name and date of birth match exactly',
    },
    ADCEXACTNAMEPARTDOB: {
        id: 2,
        title: 'Exact name and partial date of birth',
        description:
            'Record will match if all of given name, family name and year of birth match exactly',
    },
    ADCEXACTNAMEDOD: {
        id: 3,
        title: 'Exact name and date of death',
        description:
            'Record will match if all of given name, family name and date of death match exactly',
    },
    ADCEXACTNAMEPARTDOD: {
        id: 4,
        title: 'Exact name and partial date of death',
        description:
            'Record will match if all of given name, family name and year of death match exactly',
    },
    ADCPARTNAMEEXACTDOB: {
        id: 5,
        title: 'Partial given name and exact date of birth',
        description:
            'Record will match if both the family name and date of birth match exactly, and the given name matches one of the given names on record',
    },
};
