import { LoadingButton } from '@mui/lab';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    Grow,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { IconPencil } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

interface InlineTextFieldProps {
    defaultValue?: string;
    required?: boolean;
    onSave: (value: string) => void;
    validationRule?: z.ZodString;
    typographyProps?: TypographyProps;
}

function InlineTextField({
    defaultValue,
    required,
    onSave,
    typographyProps,
    validationRule,
}: InlineTextFieldProps) {
    const [value, setValue] = useState(defaultValue);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState<any | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        try {
            validationRule?.parse(value);
        } catch (error: any) {
            const errorString = error.errors[0].message;
            setError(errorString);
            return;
        }
        setError(null);
    }, [value]);

    const handleSave = () => {
        if (required && !value) {
            setError('This field is required');
            return;
        }

        if (error) {
            return;
        }

        onSave(value || '');
        setIsEditing(false);
    };

    const handleCancel = () => {
        setValue(defaultValue);
        setIsEditing(false);
        setIsFocused(false);
    };

    // Select input text when entering edit mode
    const inputRef = useRef<HTMLInputElement>();
    useEffect(() => {
        isEditing && inputRef.current?.select();
    }, [isEditing]);

    // Close edit mode on escape key press
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setValue(defaultValue);
                setIsEditing(false);
            }
        };

        if (isEditing) {
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isEditing, defaultValue]);

    // Save on enter key press
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleSave();
            }
        };

        if (isEditing) {
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [value, isEditing]);

    return (
        <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
            sx={{
                position: 'relative',
                height: '100%',
            }}
        >
            {isEditing ? (
                <>
                    <FormControl component={Stack} sx={{ zIndex: 9999 }}>
                        <FilledInput
                            autoFocus
                            inputRef={inputRef}
                            size="small"
                            value={value}
                            error={Boolean(error)}
                            hiddenLabel
                            sx={{
                                p: 0,
                                m: 0,
                                mt: -0.75,
                                backgroundColor: 'background.default',
                                width: '200px',
                            }}
                            inputProps={{
                                style: {
                                    lineHeight: '0.70',
                                    padding: isFocused ? '0.3rem 0.5rem' : '0.3rem 0',
                                    transition: 'padding 0.3s ease-in-out',
                                },
                            }}
                            onChange={(e) => setValue(e.target.value)}
                            onFocus={() => setIsFocused(true)}
                        />
                        <FormHelperText
                            error
                            sx={{
                                position: 'absolute',
                                bottom: -19,
                                left: -6,
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {error}
                        </FormHelperText>
                    </FormControl>
                    <Grow in={value !== defaultValue}>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleSave();
                            }}
                            sx={{
                                zIndex: 9999,
                            }}
                        >
                            Save
                        </LoadingButton>
                    </Grow>
                    {/* overlay that closes the edit mode on click */}
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 9998,
                        }}
                        onClick={handleCancel}
                    />
                </>
            ) : (
                <>
                    <Typography variant="body2" color="text.secondary" {...typographyProps}>
                        {value}
                    </Typography>
                    <Tooltip title="Edit">
                        <IconButton size="small" onClick={() => setIsEditing(true)}>
                            <IconPencil size={18} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Stack>
    );
}

export default InlineTextField;
