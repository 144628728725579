import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { IconEye } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import { deceasedDetailQuery } from '../../../api/deceasedDetail';
import {
    proofOfAuthorityTaskQuery,
    updateProofOfAuthorityTask,
} from '../../../api/proofOfAuthority';
import { queryClient } from '../../../api/queryClient';
import { relatedPeopleQuery, relatedPersonRoleLabels } from '../../../api/relatedPerson';
import { components } from '../../../api/schema';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import { Card } from '../../../components/Card';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import InfoTooltip from '../../../components/InfoTooltip';
import LoadingArea from '../../../components/LoadingArea';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowJurisdiction,
    DocumentTableRowPoAUploadedBy,
    DocumentTableRowType,
} from '../../../components/documentsTable/DocumentsTableCells';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import RelatedPersonDrawer from '../../../components/relatedPeople/RelatedPersonDrawer';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

function ProofOfAuthority() {
    const workspace = useWorkspace();
    const poaTask = workspace.tasks.proof_of_authority;
    invariant(poaTask);

    const { data: deceasedDetails } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const updateMutation = useMutation(updateProofOfAuthorityTask(poaTask));
    const handleAccept = () => {
        updateMutation.mutate(
            {
                status: 'completed',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Proof of Authority accepted.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoAccept = () => {
        updateMutation.mutate(
            {
                status: 'ready_for_action',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const query = useQuery(proofOfAuthorityTaskQuery(poaTask));
    if (!query.data) return <LoadingArea />;

    return (
        <PageLayout
            title="Proof of Authority"
            actions={
                <CompleteTaskButton
                    completed={query.data?.status === 'completed'}
                    onComplete={handleAccept}
                    onUndo={handleUndoAccept}
                    sx={{ pl: 2 }}
                />
            }
        >
            <Stack gap={5}>
                {deceasedDetails?.add_details === true ? (
                    <>
                        <Will />
                        <LegalPersonRepresentatives />
                        <GrantOfRepresentation />
                        <OtherProofofAuthority />
                    </>
                ) : (
                    <Placeholder title="Proof of Authority detail has not been provided" />
                )}
            </Stack>
        </PageLayout>
    );
}

function LegalPersonRepresentatives() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const lprs = peopleQuery.data?.results.filter((person) => person.is_lpr);

    return (
        <Card title="Personal Representatives">
            <QueryProgress query={peopleQuery}>
                {lprs?.length ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell sx={{ width: '3.5rem' }} />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {lprs.map((person) => (
                                    <TableRow
                                        key={person.id}
                                        sx={{
                                            // position: relative so that a button pseudo-element can cover
                                            // the entire row. But this is buggy in Safari and requires a
                                            // workaround using clip-path.
                                            // https://github.com/w3c/csswg-drafts/issues/1899#issuecomment-1232707455
                                            position: 'relative',
                                            clipPath: 'inset(0)',
                                        }}
                                    >
                                        <TableCell>{person.full_name}</TableCell>
                                        <TableCell>
                                            {person.role === 'other'
                                                ? person.role_detail
                                                : relatedPersonRoleLabels[person.role]}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disableRipple
                                                sx={{
                                                    position: 'static',
                                                    'tr:not(:hover) &:not(:focus-visible)': {
                                                        opacity: 0,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        inset: 0,
                                                        zIndex: 1,
                                                    },
                                                }}
                                                onClick={() =>
                                                    showModal(RelatedPersonDrawer, {
                                                        workspace,
                                                        person,
                                                    })
                                                }
                                            >
                                                <IconEye />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : null}
            </QueryProgress>
        </Card>
    );
}

type HasWillEnum = components['schemas']['HasWillEnum'];

const hasWillLabels: Record<HasWillEnum, string> = {
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
    yes_but: 'Yes, but...',
};

function Will() {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    return (
        <Card title="Will">
            <ReadonlyField
                label="Did the deceased leave a will?"
                value={details?.has_will ? hasWillLabels[details?.has_will] : undefined}
            />
            {!!details?.will_additional_details && (
                <ReadonlyField label="Additional detail" value={details?.will_additional_details} />
            )}
            <ReadonlyField
                xs={12}
                label="Will documents"
                value={!!workspace.will && <DocumentsTable documents={[workspace.will]} />}
            />
        </Card>
    );
}

type GorStatusEnum = components['schemas']['GorStatusEnum'];

const gorStatusLabels: Record<GorStatusEnum, string> = {
    unsure: 'Not sure yet',
    no_intention: 'No',
    not_applied: 'Yes - Intend to apply',
    pending: 'Yes - Application submitted',
    approved: 'Yes - Grant already issued',
    rejected: 'Yes - Application rejected',
};

function GrantOfRepresentation() {
    const workspace = useWorkspace();

    return (
        <Card title="Grant of representation">
            <ReadonlyField
                label={
                    <Stack direction="row" gap={1}>
                        Intention to apply
                        <InfoTooltip
                            title="Indication by the Professional Representative of their intention to apply for
                        grant of representation."
                            size={20}
                        />
                    </Stack>
                }
                value={workspace.gor_status ? gorStatusLabels[workspace.gor_status] : undefined}
            />

            <ReadonlyField
                label="Grant of representation documents"
                notProvided="Not applicable"
                value={
                    workspace.grants_of_representation?.length > 0 && (
                        <DocumentsTable
                            documents={[...workspace.grants_of_representation]}
                            columns={[
                                {
                                    id: 'file',
                                    label: 'File',
                                    Component: DocumentTableRowFile,
                                },
                                {
                                    id: 'jursidiction',
                                    label: 'Jurisdiction',
                                    Component: DocumentTableRowJurisdiction,
                                },
                                {
                                    id: 'createdAt',
                                    label: 'Uploaded',
                                    Component: DocumentTableRowCreatedAt,
                                },
                                {
                                    id: 'uploadedBy',
                                    label: 'Uploaded by',
                                    Component: DocumentTableRowPoAUploadedBy,
                                },
                            ]}
                        />
                    )
                }
            />
        </Card>
    );
}

function OtherProofofAuthority() {
    const workspace = useWorkspace();

    return (
        <Card title="Other Proof of Authority" disablePadding>
            <ReadonlyField
                label="Other Proof of Authority files"
                value={
                    workspace.other_poa_docs.length > 0 && (
                        <DocumentsTable
                            documents={[...workspace.other_poa_docs]}
                            extraColumns={[
                                {
                                    id: 'type',
                                    label: 'Type',
                                    Component: DocumentTableRowType,
                                },
                            ]}
                        />
                    )
                }
            />
        </Card>
    );
}

export default ProofOfAuthority;
