import { Box, BoxProps } from '@mui/material';

export interface CountBadgeProps extends BoxProps {
    count: number;
    size?: 'medium' | 'small';
    color?: 'primary' | 'secondary' | 'neutral';
}

export default function CountBadge(props: CountBadgeProps) {
    const { count, size = 'medium', color = 'secondary', ...boxProps } = props;

    if (!count) return null;

    const bgColor = color === 'neutral' ? 'action.selected' : `${color}.main`;
    const textColor = color === 'neutral' ? 'text.secondary' : 'text.primary';

    return (
        <Box
            {...boxProps}
            sx={{
                bgcolor: bgColor,
                color: textColor,
                fontSize: '0.8125rem',
                fontWeight: 'medium',
                lineHeight: 1,
                mr: -0.5,
                borderRadius: '99px',
                ...(size === 'medium' ? { px: 1, py: 0.5 } : { px: 0.75, py: 0.25 }),
                ...boxProps.sx,
            }}
        >
            {count}
        </Box>
    );
}
