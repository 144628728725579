import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { canViewStandaloneTools } from '../../api/user';
import CopyrightFooter from '../../components/CopyrightFooter';
import NotFoundPlaceholder from '../../components/NotFoundPlaceholder';
import useUser from '../../hooks/useUser';

function StandaloneToolsLayout() {
    const user = useUser();

    if (!canViewStandaloneTools(user)) {
        return <NotFoundPlaceholder />;
    }

    return (
        <Stack
            sx={{
                px: 10,
                py: 0,
                pb: 0,
                flexGrow: 1,
                backgroundColor: 'background.default',
                overflow: 'auto',
            }}
        >
            <Stack flexGrow={1}>
                <Outlet />
            </Stack>
            <CopyrightFooter />
        </Stack>
    );
}

export default StandaloneToolsLayout;
