import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@mui/material';

interface DeleteDocumentDialogProps extends DialogProps {
    onClose?: () => void;
    onDelete: () => void;
}

function DeleteDocumentDialog({ onClose, onDelete, ...dialogProps }: DeleteDocumentDialogProps) {
    return (
        <Dialog onClose={onClose} {...dialogProps}>
            <DialogTitle>Delete document</DialogTitle>
            <DialogContent>
                Are you sure you want to remove this document? This action cannot be undone.
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        onDelete();
                        onClose?.();
                    }}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDocumentDialog;
