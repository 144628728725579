import { Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface cardProps extends Omit<StackProps, 'title'> {
    title: ReactNode;
    description?: ReactNode;
    disablePadding?: boolean;
}

function Card({ title, children, description, ...stackProps }: cardProps) {
    return (
        <Stack gap={2} sx={{ mb: 3 }}>
            {title ? (
                <Typography variant="h5" sx={{ ml: 0 }}>
                    {title}
                </Typography>
            ) : null}

            {children}
        </Stack>
    );
}

export { Card };
