import { useSearchParams } from 'react-router-dom';

function useSort(defaultSortParam?: string) {
    const [searchParams] = useSearchParams();
    const sortParam = searchParams.get('sort')?.toString() || defaultSortParam || '';

    const defaultSortColumn = defaultSortParam?.replace('-', '');
    const sortColumn = sortParam.replace('-', '');
    const sortDirection: 'asc' | 'desc' = !sortParam.startsWith('-') ? 'asc' : 'desc';

    function sortLink(column: string) {
        const params = new URLSearchParams(searchParams);

        // If already sorted desc and clicking again, toggle to asc
        if (sortColumn === column && sortDirection === 'desc') {
            if (column === defaultSortColumn) {
                params.set('sort', column);
            } else {
                params.delete('sort');
            }
        }
        // If already sorted asc, change to desc
        else if (sortColumn === column && sortDirection === 'asc') {
            params.set('sort', '-' + column);
        }
        // Otherwise set to asc sort
        else {
            const sort = column;
            if (sort === defaultSortParam) params.delete('sort');
            else params.set('sort', sort);
        }

        return `?${params}`;
    }

    return { sortParam, sortColumn, sortDirection, sortLink };
}

export default useSort;
