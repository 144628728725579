import { Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { IconAlertTriangle, IconExternalLink, IconRefresh } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { eVoITransactionQuery, livenessVideoQuery } from '../../api/eVoiTransaction';
import DateTimeString from '../DateTimeString';
import QueryProgress from '../QueryProgress';
import ReadonlyField from '../fields/ReadonlyField';
import VoiStatusChip from './VoIStatusChip';
import { FlowTypeNormal, FlowTypeV2 } from './terms';

interface VoIResultsProps {
    eVoI: string;
    retrigger?: () => any;
    columns?: number;
}

function VoIResults({ eVoI, columns, retrigger }: VoIResultsProps) {
    const transactionQuery = useQuery(eVoITransactionQuery(eVoI));
    const eVoiTransaction = transactionQuery.data;

    return (
        <QueryProgress query={transactionQuery}>
            <Stack gap={1} sx={{ flexGrow: 1 }}>
                <Stack direction="row" gap={3} alignItems="center">
                    <VoiStatusChip status={eVoiTransaction?.status} showInfoTooltip />
                    {retrigger ? (
                        <Button
                            variant="outlined"
                            size="small"
                            color="inherit"
                            startIcon={<IconRefresh />}
                            onClick={retrigger}
                        >
                            Retrigger
                        </Button>
                    ) : null}
                    {eVoiTransaction?.name_mismatched ? <NameMismatchWarning /> : null}
                    {eVoiTransaction?.flagged_checks?.length ? <ContentFlaggedWarning /> : null}
                </Stack>

                <Stack gap={3} sx={{ pt: 3 }}>
                    <ReadonlyField
                        label="Flow type"
                        value={
                            eVoiTransaction?.flow_type === 'NORMAL2' ? FlowTypeNormal : FlowTypeV2
                        }
                    />

                    {eVoiTransaction?.status === 'pending' ? (
                        <ReadonlyField
                            label="Initiated"
                            value={DateTimeString(eVoiTransaction?.created_at || new Date())}
                        />
                    ) : null}

                    {eVoiTransaction?.status === 'completed' ? (
                        <>
                            <ReadonlyField
                                label="Completed"
                                value={DateTimeString(eVoiTransaction?.completed_at || new Date())}
                            />

                            <ReadonlyField
                                label="Report expires"
                                value={DateTimeString(eVoiTransaction?.archives_at || new Date())}
                                sx={{ pb: 3 }}
                            />

                            <VoILinks eVoI={eVoI} />
                        </>
                    ) : null}
                </Stack>
            </Stack>
        </QueryProgress>
    );
}

function VoILinks({ eVoI }: VoIResultsProps) {
    const query = useQuery(livenessVideoQuery(eVoI));
    const livenessVideoUrl = query.data?.url;

    return (
        <Stack gap={2}>
            <Button
                component="a"
                href={`/api/e_voi_transaction/${eVoI}/pdf_report`}
                target="_blank"
                startIcon={<IconExternalLink />}
                variant="inverse"
                color="inherit"
                sx={{ whiteSpace: 'nowrap', flexGrow: 1, px: 2 }}
            >
                View Report
            </Button>

            <Button
                component="a"
                href={livenessVideoUrl}
                target="_blank"
                startIcon={
                    livenessVideoUrl ? (
                        <IconExternalLink />
                    ) : (
                        <CircularProgress size={17} sx={{ color: 'white' }} />
                    )
                }
                disabled={!livenessVideoUrl}
                variant="inverse"
                sx={{ whiteSpace: 'nowrap', flexGrow: 1 }}
            >
                View Liveness Video
            </Button>
        </Stack>
    );
}

function NameMismatchWarning() {
    return (
        <Tooltip
            title="Warning: The name of the individual detailed in this report is not an exact
    match with the provided name."
        >
            <Stack direction="row" gap={1} alignItems="center" width="fit-content">
                <Box
                    color="warning.main"
                    component={IconAlertTriangle}
                    size={20}
                    strokeWidth={1.5}
                />
                <Typography variant="body2" color="warning.main" noWrap>
                    Name mismatch
                </Typography>
            </Stack>
        </Tooltip>
    );
}

function ContentFlaggedWarning() {
    return (
        <Tooltip title="Warning: Content flagged. Please manually check the report and liveness video.">
            <Stack direction="row" gap={1} alignItems="center" width="fit-content">
                <Box
                    color="warning.main"
                    component={IconAlertTriangle}
                    size={20}
                    strokeWidth={1.5}
                />
                <Typography variant="body2" color="warning.main" noWrap>
                    Content flagged
                </Typography>
            </Stack>
        </Tooltip>
    );
}

export default VoIResults;
