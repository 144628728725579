import { Box, TableSortLabel as MuiTableSortLabel } from '@mui/material';
import { IconCaretDownFilled, IconCaretUpDown, IconCaretUpFilled } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface TableSortLabelProps {
    label?: string;
    sortLink: string;
    sortDirection?: 'asc' | 'desc';
    isActive: boolean;
}

function TableSortLabel({ label, sortDirection, sortLink, isActive }: TableSortLabelProps) {
    return (
        <MuiTableSortLabel
            component={Link}
            state={{ keepPreviousData: 'true' }}
            to={sortLink}
            active={isActive}
            direction={sortDirection}
            IconComponent={() => (
                <Box
                    component={
                        !isActive
                            ? IconCaretUpDown
                            : sortDirection === 'asc'
                              ? IconCaretUpFilled
                              : IconCaretDownFilled
                    }
                    sx={{
                        opacity: isActive ? 0.8 : 0.5,
                        ml: 0.25,
                        strokeWidth: '1.25px',
                        flexShrink: 0,
                    }}
                    size={22}
                />
            )}
            hideSortIcon={false}
        >
            {label}
        </MuiTableSortLabel>
    );
}

export default TableSortLabel;
