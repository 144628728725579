import { useQuery } from '@tanstack/react-query';
import { createContext } from 'react';
import { WorkspaceMembership, workspaceMembershipQuery } from '../../api/workspaceMembership';
import useUser from '../../hooks/useUser';
import { useWorkspace } from './workspaceContext';

const WorkspaceMembershipContext = createContext<WorkspaceMembership | undefined>(undefined);

export const WorkspaceMembershipProvider = WorkspaceMembershipContext.Provider;

// Get the workspace membership for the currently logged in user. May be undefined if the user is the owner of the workspace.
export function useWorkspaceMembership() {
    const workspace = useWorkspace();
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    if (isWorkspaceOwner) {
        return undefined;
    }

    const { data: workspaceMembership } = useQuery({
        ...workspaceMembershipQuery(workspace.memberships[0]),
    });

    return workspaceMembership;
}
