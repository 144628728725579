import { LoadingButton } from '@mui/lab';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useSubmit } from 'react-router-dom';
import { queryClient } from '../../api/queryClient';
import { User, userQuery } from '../../api/user';
import { updateUserConsentMutation, userConsentQuery } from '../../api/userConsent';
import AuthLayout from '../auth/AuthLayout';

function ConsentForm() {
    const [consent, setConsent] = useState(false);
    const navigate = useNavigate();
    const submit = useSubmit();

    const consentDetailQuery = useQuery(userConsentQuery());
    const consentDetail = consentDetailQuery.data;

    const mutation = useMutation(updateUserConsentMutation());

    const onSubmit = () => {
        mutation.mutate(
            {
                consent_detail: consentDetail?.id || '',
                accepted: true,
            },
            {
                onSuccess: () => {
                    queryClient.setQueryData(userQuery().queryKey, (oldData: User) => ({
                        ...oldData,
                        consent_pending: false,
                    }));
                    navigate('/');
                },
            }
        );
    };

    const handleCancel = () => {
        submit(null, { method: 'post', action: '/logout' });
    };

    return (
        <AuthLayout>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    minHeight: '100vh',
                }}
                flexGrow={1}
            >
                <Paper sx={{ p: 4, maxWidth: 'md', width: '100%', borderRadius: 5 }}>
                    <DialogTitle sx={{ pb: 2 }}>Privacy Notification and Consent</DialogTitle>
                    <DialogContent>
                        <Typography
                            variant="body1"
                            paragraph
                            dangerouslySetInnerHTML={{ __html: consentDetail?.detail || '' }}
                        />
                    </DialogContent>
                    <DialogContent>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={consent}
                                    onChange={(e) => setConsent(e.target.checked)}
                                    sx={{
                                        '&.Mui-checked': {
                                            '&:before': {
                                                background: 'white',
                                            },
                                        },
                                    }}
                                    name="consent"
                                />
                            }
                            label={consentDetail?.privacy_consent}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={onSubmit}
                            variant="contained"
                            color="primary"
                            loading={mutation.status === 'pending'}
                            disabled={!consent || mutation.status === 'pending'}
                        >
                            Consent
                        </LoadingButton>
                    </DialogActions>
                </Paper>
            </Stack>
        </AuthLayout>
    );
}

export default ConsentForm;
