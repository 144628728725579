// Copyright (C) 2024, Affinda

import * as Sentry from '@sentry/react';

// Passed in at runtime via the django template
export interface SentryData {
    release: string;
    dsn: string;
    environment: string;
}

export function initSentry(data: SentryData) {
    if (!data.release || !data.dsn) return;

    Sentry.init({
        dsn: data.dsn,
        environment: data.environment,
        release: data.release,
        integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],
        allowUrls: [/https?:\/\/(.+\.)?estatexchange\.com\.au/, /http?:\/\/localhost/],
        ignoreErrors: ['AbortError', 'RestError', 'AxiosError'],
    });
}
