import {
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import React from 'react';
import { WorkspaceMeta } from '../../api/workspace';
import useSort from '../../hooks/useSort';
import useUser from '../../hooks/useUser';
import TableSortLabel from '../TableSortLabel';
import {
    CellComponentProps,
    WorkspaceTableRowAccountInstructionsStatus,
    WorkspaceTableRowBankReference,
    WorkspaceTableRowCountBadge,
    WorkspaceTableRowCreatedAt,
    WorkspaceTableRowDeceasedName,
    WorkspaceTableRowIdentifier,
    WorkspaceTableRowParticipantRequests,
    WorkspaceTableRowProofOfAuthorityStatus,
    WorkspaceTableRowProofOfDeathStatus,
    WorkspaceTableRowProofOfIdentityStatus,
    WorkspaceTableRowReference,
    WorkspaceTableRowStatus,
    WorkspaceTableRowTimeSinceInvitation,
} from './WorkspaceTableCells';
import WorkspaceTableRow from './WorkspacesTableRow';

export interface Column {
    id: string;
    label?: string;
    Component: React.ComponentType<CellComponentProps>;
    sortable?: string;
    sx?: SxProps<Theme>;
}

interface WorkspacesTableProps {
    defaultSort: string;
    workspaces: WorkspaceMeta[];
}

function WorkspacesTable({ defaultSort, workspaces }: WorkspacesTableProps) {
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    const columns: Column[] = [
        {
            id: 'countBadge',
            label: '',
            Component: WorkspaceTableRowCountBadge,
        },
        {
            id: 'deceasedName',
            label: 'Deceased name',
            Component: WorkspaceTableRowDeceasedName,
            sortable: 'deceased_name',
        },
        {
            id: 'eXcId',
            label: 'eXc ID',
            Component: WorkspaceTableRowIdentifier,
            sortable: 'external_id',
        },
    ];

    if (!isWorkspaceOwner) {
        columns.push({
            id: 'bankReference',
            label: 'Reference',
            Component: WorkspaceTableRowBankReference,
            sortable: 'participant_reference',
        });
    } else {
        columns.push({
            id: 'reference',
            label: 'Reference',
            Component: WorkspaceTableRowReference,
            sortable: 'case_reference',
        });
    }

    if (isWorkspaceOwner) {
        columns.push(
            {
                id: 'created_at',
                label: 'Created',
                Component: WorkspaceTableRowCreatedAt,
                sortable: 'created_at',
            },
            {
                id: 'status',
                label: 'Status',
                Component: WorkspaceTableRowStatus,
            }
        );
    } else {
        columns.push(
            {
                id: 'timeSinceInvitation',
                label: 'Invited',
                Component: WorkspaceTableRowTimeSinceInvitation,
                sortable: 'invited_at',
            },
            {
                id: 'pod',
                label: 'Proof of Death',
                Component: WorkspaceTableRowProofOfDeathStatus,
                sx: {
                    width: 140,
                    maxWidth: '140px !important',
                },
            },
            {
                id: 'poa',
                label: 'Proof of Authority',
                Component: WorkspaceTableRowProofOfAuthorityStatus,
                sx: {
                    width: 152,
                    maxWidth: '152px !important',
                    p: 0,
                },
            },
            {
                id: 'poi',
                label: 'Proof of Identity',
                Component: WorkspaceTableRowProofOfIdentityStatus,
                sx: {
                    width: 152,
                    maxWidth: '152px !important',
                    p: 0,
                },
            },
            {
                id: 'poi',
                label: 'Account Instructions',
                Component: WorkspaceTableRowAccountInstructionsStatus,
                sx: {
                    width: 160,
                    maxWidth: 'fit-content',
                    p: 0,
                },
            },

            {
                id: 'requests',
                label: '',
                Component: WorkspaceTableRowParticipantRequests,
            }
        );
    }

    const { sortColumn, sortDirection, sortLink } = useSort(defaultSort);

    const isActive = (column: Column) => sortColumn === column.sortable;

    return (
        <TableContainer
            sx={{
                height: '100%',
                overflowX: 'initial',
            }}
        >
            <Table
                stickyHeader
                sx={{
                    whiteSpace: 'nowrap',

                    '& .MuiTableCell-root': {
                        p: 0.75,
                        py: 1.75,
                    },
                    '& .MuiTableCell-stickyHeader': {
                        bgcolor: 'background.paper',
                        py: 1,
                    },
                    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
                        pl: 2,
                    },
                }}
            >
                <TableHead
                    sx={{
                        '& .MuiTableCell-root': {
                            py: 2,
                        },
                    }}
                >
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} sx={column.sx}>
                                {column.sortable ? (
                                    <TableSortLabel
                                        label={column.label}
                                        sortLink={sortLink(column.sortable)}
                                        sortDirection={sortDirection}
                                        isActive={isActive(column)}
                                    />
                                ) : (
                                    column.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody sx={{ p: 0 }}>
                    {workspaces.map((workspace) => (
                        <WorkspaceTableRow
                            key={workspace.id}
                            columns={columns}
                            workspace={workspace}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WorkspacesTable;
