// Copyright (C) 2024, Affinda

import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';

export type Upload = {
    id: number;
    message: string;
    promise?: Promise<any>;
    progress: number;
    error?: string;
    controller?: AbortController;
};

export class UploadStore {
    uploads: Upload[] = [];
    private index: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get all() {
        return this.uploads;
    }

    async uploadDocument(
        file: File,
        mutationFn: (file: File) => Promise<any>,
        onSuccess?: () => void
    ) {
        const upload: Upload = makeAutoObservable({
            id: this.index++,
            message: `Uploading ${file.name}`,
            progress: 0,
            error: undefined,
        });

        this.uploads.push(upload);

        // Maximum size allowed by backend is 20MB, we'll implement a more lenient check in the frontend,
        // if the file passes this check, it will be checked again in the backend.
        if (file.size > 30_000_000) {
            enqueueSnackbar('File size exceeds the maximum allowed size of 20MB.', {
                variant: 'error',
            });
            this.remove(upload.id);
            return;
        }

        upload.controller = new AbortController();
        upload.promise = mutationFn(file);

        upload.promise.then(
            () => {
                this.remove(upload.id);
                onSuccess?.();
            },
            action((error) => {
                this.remove(upload.id);
                const errorMsg = error.response?.data?.errors?.[0]?.detail || 'Unknown error';
                enqueueSnackbar(errorMsg, { variant: 'error' });
            })
        );
    }

    remove(id: number) {
        const k = this.uploads.findIndex((u) => u.id === id);
        if (k !== -1) {
            this.uploads[k].controller?.abort();
            this.uploads.splice(k, 1);
        }
    }
}
