// Copyright (C) 2024, Affinda

import { Box, BoxProps } from '@mui/material';
import { DragEvent, useRef, useState } from 'react';

interface UploadDropAreaProps extends BoxProps {
    onUpload?: (file: File) => void;
    multiple?: boolean;
    disabled?: boolean;
}

export default function UploadDropArea(props: UploadDropAreaProps) {
    const { onUpload, multiple, disabled, ...boxProps } = props;
    const [dragging, setDragging] = useState<boolean>(false);
    const dragTimeout = useRef<number | null>(null);

    const handleDrop = (e: DragEvent) => {
        if (disabled) return;
        e.preventDefault();
        setDragging(false);
        let files = Array.from(e.dataTransfer.files);
        if (!multiple) {
            const file = files.slice(0, 1)[0];
            onUpload?.(file);
        } else {
            files.map((file) => onUpload?.(file));
        }
    };

    const handleDragOver = (e: DragEvent) => {
        if (disabled) return;
        e.preventDefault();
        if (dragTimeout.current) {
            window.clearTimeout(dragTimeout.current);
        }
        setDragging(true);
    };

    const handleDragLeave = () => {
        dragTimeout.current = window.setTimeout(() => setDragging(false), 100);
    };

    return (
        <Box
            data-test="uploadDropArea"
            sx={{
                flexGrow: 1,
                minHeight: 0,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                ...(dragging
                    ? {
                          '&:before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              border: 3,
                              borderColor: 'primary.dark',
                              borderRadius: 1,
                              zIndex: 10000,
                              pointerEvents: 'none',
                          },
                      }
                    : {}),
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            {...boxProps}
        />
    );
}
