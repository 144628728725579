// Copyright (C) 2024, Affinda

import { Box } from '@mui/material';
import { IconAlertCircle } from '@tabler/icons-react';
import { useRouteError } from 'react-router-dom';
import NotFoundPlaceholder from './NotFoundPlaceholder';
import Placeholder from './Placeholder';

function Error() {
    const error = useRouteError() as any;

    const is404 =
        error.status === 404 || error.statusCode === 404 || error.response?.status === 404;

    return (
        <Box sx={{ height: '100vh', display: 'flex', backgroundColor: 'background.main' }}>
            {is404 ? (
                <NotFoundPlaceholder />
            ) : (
                <Placeholder
                    IconComponent={IconAlertCircle}
                    title="Something went wrong"
                    description={error.statusText}
                />
            )}
        </Box>
    );
}

export default Error;
