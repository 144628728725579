import { UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type NoteMeta = components['schemas']['Note'];
export type CreateNoteMeta = components['schemas']['NoteRequest'];
export type NoteParams = operations['notes_list']['parameters']['query'];
export type PaginatedNoteList = components['schemas']['PaginatedNoteList'];

export function notesQuery(options?: NoteParams) {
    return {
        queryKey: ['notes', options],
        queryFn: async () =>
            await api<PaginatedNoteList>({
                url: '/notes',
                params: options,
                method: 'GET',
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedNoteList>;
}

export function createNoteMutation() {
    return {
        mutationFn: (data: any) =>
            api<NoteMeta>({
                url: `/notes`,
                method: 'POST',
                data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notes'] });
        },
    } satisfies UseMutationOptions<NoteMeta, any, CreateNoteMeta, any>;
}

export function deleteNoteMutation(noteId: string) {
    return {
        mutationFn: () =>
            api<NoteMeta>({
                url: `/notes/${noteId}`,
                method: 'DELETE',
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notes'] });
        },
    } satisfies UseMutationOptions<NoteMeta, any, CreateNoteMeta, any>;
}
