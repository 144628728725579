import { Box, BoxProps, Tooltip } from '@mui/material';
import { IconCircleCheckFilled, IconCircleDashed } from '@tabler/icons-react';
import { ReactElement, useMemo } from 'react';
import { components } from '../api/schema';
import { colors } from '../theme/palette';

export type TaskStatusEnum = components['schemas']['TaskStatusEnum'];

export function iconProps(status: TaskStatusEnum): {
    label: string;
    variant?: 'success' | 'warning' | 'secondary' | 'default';
    color?: string;
    icon?: ReactElement;
} {
    switch (status) {
        case 'completed':
            return {
                label: 'Complete',
                variant: 'success',
                color: colors.green,
                icon: (
                    <Box
                        component={IconCircleCheckFilled}
                        size={24}
                        sx={{ height: 30, width: 30 }}
                    />
                ),
            };
        default:
            return {
                label: 'Ready For Action',
                variant: 'default',
                color: 'text.disabled',
                icon: <Box component={IconCircleDashed} size={30} sx={{ opacity: 0.5 }} />,
            };
    }
}

interface TaskIconProps extends BoxProps {
    status: TaskStatusEnum;
    tooltip?: string;
}

function TaskIcon({ status, tooltip, ...boxProps }: TaskIconProps) {
    const { label, color, icon } = useMemo(() => iconProps(status), [status]);

    return (
        <Tooltip title={tooltip ?? label}>
            <Box
                {...boxProps}
                sx={{
                    color: color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 24,
                    height: 24,
                    ...boxProps.sx,
                }}
            >
                {icon}
            </Box>
        </Tooltip>
    );
}

export default TaskIcon;
