// Copyright (C) 2024, Affinda

import { Box } from '@mui/material';
import { useIntervalEffect, useTimeoutEffect } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import { useNavigation } from 'react-router-dom';

const animationDuration = 300;

export default function ProgressBar() {
    const navigation = useNavigation();
    const loading = navigation.state === 'loading';
    const [value, setValue] = useState(0);

    useIntervalEffect(
        () => setValue((value) => value + Math.random() / 100),
        loading ? animationDuration : undefined
    );

    useTimeoutEffect(() => setValue(0), loading ? undefined : animationDuration);

    useEffect(() => {
        if (!loading) {
            setValue(1);
        }
    }, [loading]);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: 4,
                bgcolor: 'primary.main',
                zIndex: 2147483647,
                transition: `width ${animationDuration}ms ease-out, opacity ${
                    animationDuration / 2
                }ms ${animationDuration / 2}ms ease-in`,
                transform: 'translate3d(0, 0, 0)',
            }}
            style={{ width: 10 + value * 90 + '%', opacity: loading ? 1 : 0 }}
        />
    );
}
