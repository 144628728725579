import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api } from './utils';
import { workspacesQuery } from './workspace';

export type WorkspaceMembership = components['schemas']['WorkspaceMembership'];
export type WorkspaceMembershipsRequest =
    operations['workspace_memberships_list']['parameters']['query'];

export function workspaceMembershipsQuery(options: WorkspaceMembershipsRequest) {
    return {
        queryKey: ['workspaceMembership', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<Array<WorkspaceMembership>>({
                url: `/workspace_memberships`,
                params: options,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function workspaceMembershipQuery(id: string) {
    return {
        queryKey: ['workspaceMembership', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<WorkspaceMembership>({
                url: `/workspace_memberships/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

type CreateWorkspaceMembershipRequest = { letter_file?: File } & Omit<
    components['schemas']['WorkspaceMembershipRequest'],
    'letter_file'
>;

export function createWorkspaceMembershipMutation() {
    function _(data: CreateWorkspaceMembershipRequest): FormData {
        const formdata = new FormData();
        if (data.letter_file) formdata.append('letter_file', data.letter_file);

        if (data.known_holdings && data.known_holdings.length > 0) {
            for (const holding of data.known_holdings) {
                formdata.append('known_holdings', holding);
            }
        }

        Object.keys(data).forEach((key) => {
            const value = data[key as keyof CreateWorkspaceMembershipRequest];
            if (!!value && typeof value !== 'object') {
                formdata.append(key, value);
            }
        });
        return formdata;
    }
    return {
        mutationFn: (data) =>
            api<WorkspaceMembership>({
                url: `/workspace_memberships`,
                method: 'POST',
                data: _(data),
            }).then((response) => response.data),

        onSuccess: (_, vars) => {
            queryClient.invalidateQueries({ queryKey: ['workspaceMembership'] });
        },
    } satisfies UseMutationOptions<WorkspaceMembership, any, CreateWorkspaceMembershipRequest, any>;
}

type UpdateWorkspaceMembershipRequest = { id: string; letter_file?: File } & Omit<
    components['schemas']['PatchedWorkspaceMembershipRequest'],
    'letter_file'
>;

export function updateWorkspaceMembershipMutation() {
    function _(data: UpdateWorkspaceMembershipRequest): FormData {
        const formdata = new FormData();
        if (data.letter_file) formdata.append('letter_file', data.letter_file);

        if (data.known_holdings && data.known_holdings.length > 0) {
            for (const holding of data.known_holdings) {
                formdata.append('known_holdings', holding);
            }
        }

        Object.keys(data).forEach((key) => {
            const value = data[key as keyof UpdateWorkspaceMembershipRequest];
            if (!!value && typeof value !== 'object') {
                formdata.append(key, value);
            }
        });
        return formdata;
    }
    return {
        mutationFn: (data) =>
            api<WorkspaceMembership>({
                url: `/workspace_memberships/${data.id}`,
                method: 'PATCH',
                data: _(data),
            }).then((response) => response.data),

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: workspacesQuery().queryKey });
            queryClient.invalidateQueries({ queryKey: workspaceMembershipQuery(data.id).queryKey });
        },
    } satisfies UseMutationOptions<WorkspaceMembership, any, UpdateWorkspaceMembershipRequest, any>;
}
