// Copyright (C) 2024, Affinda

import { Box, Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import { IconAlertCircle } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';
import Placeholder from './Placeholder';

export default function ErrorBoundary({ children }: PropsWithChildren) {
    return (
        <Sentry.ErrorBoundary
            fallback={
                <Box sx={{ height: '100vh', display: 'flex' }}>
                    <Placeholder IconComponent={IconAlertCircle} title="Something went wrong">
                        <p>Please reload the page and try again.</p>

                        <Button onClick={() => window.location.reload()}>Try Again</Button>
                    </Placeholder>
                </Box>
            }
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}
