// Copyright (C) 2024, Affinda

import {
    Alert,
    Box,
    IconButton,
    LinearProgress,
    Paper,
    Snackbar,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { IconAlertCircle, IconX } from '@tabler/icons-react';
import { observer } from 'mobx-react';
import { useStore } from '../store/storeContext';
import IconLabel from './IconLabel';

function UploadsSnackbar() {
    const store = useStore();
    const count = store.uploads.length;

    return (
        <Snackbar
            open={count > 0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            data-test="uploadFiles"
        >
            <Paper sx={{ width: '50ch', overflow: 'hidden' }} elevation={20}>
                <Alert variant="filled" severity="info" icon={false} sx={{ borderRadius: 0 }}>
                    Uploads
                </Alert>

                <Box sx={{ overflow: 'auto', maxHeight: 400 }}>
                    {store.uploads.map((upload) => (
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ p: 1, pl: 2, typography: 'body2' }}
                            key={upload.id}
                        >
                            <Typography variant="body2" noWrap sx={{ flexGrow: 1 }}>
                                {upload.message}
                            </Typography>

                            {upload.error ? (
                                <Tooltip title={upload.error} describeChild>
                                    <IconLabel
                                        icon={<IconAlertCircle />}
                                        label={upload.error}
                                        sx={{
                                            color: 'error.main',
                                            cursor: 'help',
                                            fontWeight: 'bold',
                                            maxWidth: '20ch',
                                        }}
                                    />
                                </Tooltip>
                            ) : (
                                <LinearProgress
                                    variant={
                                        upload.progress === 0 || upload.progress === 1
                                            ? 'indeterminate'
                                            : 'determinate'
                                    }
                                    value={upload.progress * 100}
                                    sx={{ width: 100, flexShrink: 0 }}
                                />
                            )}

                            <Tooltip title="Cancel" aria-label="Cancel">
                                <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={() => store.remove(upload.id)}
                                >
                                    <IconX />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    ))}
                </Box>
            </Paper>
        </Snackbar>
    );
}

export default observer(UploadsSnackbar);
