import { z } from 'zod';

export enum ValidationMessages {
    REQUIRED = 'This field is required.',
    REQUIRED_FILE = 'Document upload is required.',
    MAX_LENGTH = 'Must be ${maxLength} characters or less.',
    NO_NUMBERS = 'No numbers are allowed.',
    NO_SPECIAL_CHARS = 'No special characters are allowed.',
}

export const validateString = (
    value: string,
    options?: {
        required?: boolean;
        maxLength?: number;
        noNumbers?: boolean;
        noSpecialChars?: boolean;
    }
) => {
    const { required, maxLength, noNumbers, noSpecialChars } = options ?? {};
    const val = value || '';

    if (required && !val) {
        return ValidationMessages.REQUIRED;
    }

    if (maxLength && val.length > maxLength) {
        return ValidationMessages.MAX_LENGTH.replace('${maxLength}', maxLength.toString());
    }

    if (noNumbers && /[0-9]/.test(val)) {
        return ValidationMessages.NO_NUMBERS;
    }

    if (noSpecialChars && !/^[a-zA-Z0-9.,:;\/?!@#$%&()"'~+\-=_ \n]*$/.test(val)) {
        return ValidationMessages.NO_SPECIAL_CHARS;
    }

    return true;
};

/**
 * Everything below this comment is deprecated.
 */

const characterLimit = (limit: number) =>
    z.string().max(limit, `Must be ${limit} characters or less.`);

const noNumbers = z.string().regex(/^[^0-9]+$/, 'No numbers are allowed.');

const noSpecialChars = z
    .string()
    .regex(/^[a-zA-Z0-9.,:;\/?!@#$%&()"'~+\-=_ \n]*$/, 'No special characters are allowed.');

const fileName = z
    .string()
    .regex(
        /^[^\\/:*?"<>|]*$/,
        'A file name can\'t contain any of the following characters: \\ / : * ? " < > |'
    )
    .and(noSpecialChars);

const onlyNumbers = z.string().regex(/^[0-9]+$/, 'Only numbers are allowed.');

const onlyLettersAndNumbers = z
    .string()
    .regex(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed.');

const optionalFile = z.instanceof(File).optional();

const optionalString = z.string().optional().nullable();

const phoneNumber = z
    .string()
    .regex(/^[0-9.,:;\/?!@#$%&()"'~+\-=_ ]*$/, 'Only numbers and punctuation are allowed.');

const requiredDate = z.date({
    // Workaround to add custom message when date is missing https://github.com/colinhacks/zod/issues/1526
    errorMap: (issue, { defaultError }) => ({
        message: issue.code === 'invalid_type' ? ValidationMessages.REQUIRED : defaultError,
    }),
});

const optionalDate = z.date().optional().nullable();

const requiredFile = z.instanceof(File, {
    message: ValidationMessages.REQUIRED_FILE,
});

const requiredFileArray = z.array(z.instanceof(File)).min(1, ValidationMessages.REQUIRED_FILE);

const requiredString = z
    .string({
        errorMap: (issue, { defaultError }) => ({
            message: issue.code === 'invalid_type' ? ValidationMessages.REQUIRED : defaultError,
        }),
    })
    .min(1, ValidationMessages.REQUIRED);

export {
    characterLimit,
    fileName,
    noNumbers,
    noSpecialChars,
    onlyLettersAndNumbers,
    onlyNumbers,
    optionalDate,
    optionalFile,
    optionalString,
    phoneNumber,
    requiredDate,
    requiredFile,
    requiredFileArray,
    requiredString,
};
