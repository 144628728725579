import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type PoaDocument = components['schemas']['ProofOfAuthorityDocument'];
export type GrantOfRepresentation = components['schemas']['GrantOfRepresentation'];
export type PoaTypeEnum = components['schemas']['PoaTypeEnum'];
export type PaginatedFinalisationFormDocumentList =
    components['schemas']['PaginatedFinalisationFormDocumentList'];
export type FinalisationFormDocument = components['schemas']['FinalisationFormDocument'];
export type FinalisationFormReplyDocument = components['schemas']['FinalisationFormReplyDocument'];
export type GenericDocument = components['schemas']['GenericDocument'];
export type DeathCertificateDocument = components['schemas']['DeathCertificateDocument'];
export type Document = components['schemas']['Document'];

export type DocumentUnion =
    | PoaDocument
    | DeathCertificateDocument
    | GenericDocument
    | Document
    | GrantOfRepresentation;

export function finalisationFormQuery(workspaceId: string, filter?: { membership: string }) {
    return {
        queryKey: ['finalisationForm', workspaceId, filter],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedFinalisationFormDocumentList>({
                url: `/documents/finalisation_form`,
                params: {
                    workspace: workspaceId,
                    membership: filter?.membership,
                },
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

type PaginatedDocumentList = components['schemas']['PaginatedDocumentList'];
type ListDocumentsRequest = operations['documents_list']['parameters']['query'];

export function listAllDocumentsQuery(options: ListDocumentsRequest) {
    return {
        queryKey: ['documents', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedDocumentList>({
                url: `/documents`,
                params: options,
                method: 'GET',
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedDocumentList>;
}
