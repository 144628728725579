import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { DocumentUnion } from '../api/document';
import useUser from '../hooks/useUser';
import { useWorkspace } from '../pages/workspace/workspaceContext';

interface DocumentInfectedDialogProps extends DialogProps {
    document: DocumentUnion;
    onClose: () => void;
    onDelete?: () => void;
}

function DocumentInfectedDialog({
    document,
    onClose,
    onDelete,
    ...dialogProps
}: DocumentInfectedDialogProps) {
    const user = useUser();
    const workspace = useWorkspace();

    const isUploader =
        document?.uploaded_by?.organisation_name ===
        (user?.institution_organisation?.name || user?.de_rep_organisation?.name);

    return (
        <Dialog maxWidth="md" onClose={onClose} {...dialogProps}>
            <DialogTitle>Document error</DialogTitle>
            <DialogContent>
                <Alert severity="error" sx={{ mb: 4 }}>
                    estateXchange&apos;s document security scanner has detected an issue with the
                    uploaded document.
                </Alert>
                <Typography sx={{ mt: 2 }}>
                    {isUploader
                        ? 'Please delete and re-upload a clean version of the file. If this error occurs multiple times, we suggest saving the file in a different format.'
                        : 'Please raise a Request to the document owner if this is not replaced with a legitimate document in due course.'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Close
                </Button>
                {isUploader ? (
                    <Button
                        onClick={() => {
                            onDelete?.();
                            onClose();
                        }}
                        variant="contained"
                        color="error"
                    >
                        Delete document
                    </Button>
                ) : (
                    <Button
                        component={RouterLink}
                        to={`/workspace/m/${workspace.id}/requests`}
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                    >
                        Raise Request
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DocumentInfectedDialog;
