import { Box, Stack, StackProps, Typography } from '@mui/material';
import ADCLogo from '../assets/australian-death-check.png';

function ADCBadge(props: StackProps) {
    return (
        <Stack direction="row" gap={2} alignItems="center" {...props}>
            <Typography variant="body2">
                <i>Powered by</i>
            </Typography>
            <Box
                sx={{
                    width: 95,
                    height: 25,
                    background: 'rgba(255, 255, 255, 0.5)',
                    backgroundImage: `url(${ADCLogo})`,
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        </Stack>
    );
}

export default ADCBadge;
