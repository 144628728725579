import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { components } from './schema';
import { api } from './utils';

export type DeathCertificateDocument = components['schemas']['DeathCertificateDocument'];
export type DeathCertificateDocumentError =
    components['schemas']['DocumentsDeathCertificateCreateValidationError'];

export type DeathCertificateDocumentRequest = {
    workspace: string;
    file: File;
    registrationNumber: string;
    registrationYear: number;
    registrationState: string;
};

export function uploadDeathCertificateMutation() {
    return {
        mutationFn: (request) => uploadDeathCertificate(request),
    } satisfies UseMutationOptions<
        DeathCertificateDocument,
        AxiosError<DeathCertificateDocumentError, any>,
        DeathCertificateDocumentRequest,
        any
    >;
}

export function uploadDeathCertificate(request: DeathCertificateDocumentRequest) {
    const formData = new FormData();

    formData.append('file', request.file!);
    formData.append('file_name', request.file!.name);
    formData.append('workspace', request.workspace);
    formData.append('registration_number', request.registrationNumber);
    formData.append('registration_year', request.registrationYear.toString());
    formData.append('registration_state', request.registrationState);

    return api<DeathCertificateDocument>({
        url: `/documents/death_certificate`,
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deleteDeathCertificateMutation() {
    return {
        mutationFn: (id: string) => {
            return api<string>({
                url: `/documents/death_certificate/${id}`,
                method: 'DELETE',
            }).then(() => id);
        },
    } satisfies UseMutationOptions<string, any, string, any>;
}
