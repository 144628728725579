import { Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { deceasedDetailQuery } from '../../../api/deceasedDetail';
import { Card } from '../../../components/Card';
import DateString from '../../../components/DateString';
import FormGrid from '../../../components/FormGrid';
import LoadingArea from '../../../components/LoadingArea';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import ClientSpecificContent from './ClientSpecific';

export enum tabs {
    DeceasedDetails = 'deceased-details',
    ClientSpecific = 'client-specific',
}

function EstateDetails() {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab') || tabs.DeceasedDetails;

    function tabLink(tab?: string) {
        const params = new URLSearchParams(searchParams);
        tab ? params.set('tab', tab) : params.delete('tab');
        return `?${params}`;
    }

    return (
        <PageLayout
            title={
                <Stack direction="row" gap={3} alignItems="flex-end">
                    Estate Details
                    <Tabs value={tab} sx={{ pb: 1 }} className="underlined">
                        <Tab
                            key={tabs.DeceasedDetails}
                            label="Deceased Information"
                            className="underlined"
                            value={tabs.DeceasedDetails}
                            component={RouterLink}
                            to={tabLink(tabs.DeceasedDetails)}
                        />

                        <Tab
                            key={tabs.ClientSpecific}
                            label="Participant Specific"
                            className="underlined"
                            value={tabs.ClientSpecific}
                            component={RouterLink}
                            to={tabLink(tabs.ClientSpecific)}
                        />
                    </Tabs>
                </Stack>
            }
        >
            {tab === tabs.DeceasedDetails && <DeceasedDetailsContent />}
            {tab === tabs.ClientSpecific && <ClientSpecificContent />}
        </PageLayout>
    );
}

function DeceasedDetailsContent() {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    if (details === undefined) return <LoadingArea />;

    return (
        <Stack gap={4}>
            <Card title="Deceased Information">
                <Grid container columnSpacing={4} rowSpacing={4} sx={{ width: '50%' }}>
                    <ReadonlyField label="Family name" value={details.family_name || ''} />
                    <ReadonlyField label="Given name(s)" value={details.given_names || ''} />
                    <ReadonlyField
                        label="Other known aliases"
                        value={
                            details.aliases &&
                            details.aliases.length > 0 && (
                                <Stack>
                                    {details.aliases?.map((alias) => (
                                        <Typography>{alias}</Typography>
                                    ))}
                                </Stack>
                            )
                        }
                    />

                    <ReadonlyField
                        label="Date of birth"
                        value={DateString(details.date_of_birth || '')}
                    />
                </Grid>
            </Card>
            <Card title="Addresses">
                <Stack gap={4}>
                    <ReadonlyField label="Phone number" value={details.phone_number || ''} />
                    <ReadonlyField
                        xs={12}
                        label="Last known residential address"
                        value={details.residential_addresses.current?.full_address}
                    />
                    <ReadonlyField
                        xs={12}
                        label="Other known addresses"
                        value={
                            details.residential_addresses.others.length > 0 && (
                                <Stack>
                                    {details.residential_addresses.others.map((address) => (
                                        <Typography>{address.full_address}</Typography>
                                    ))}
                                </Stack>
                            )
                        }
                    />
                </Stack>
            </Card>
            <Card title="Other">
                <FormGrid>
                    <ReadonlyField
                        label="Other useful identifying information"
                        value={details.other_info || ''}
                    />
                </FormGrid>
            </Card>
        </Stack>
    );
}

export default EstateDetails;
