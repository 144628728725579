import { CssVarsThemeOptions, lighten } from '@mui/material';

export const colors = {
    charcoal: '#252629',
    charcoal40: '#A8A8A9', // linework
    charcoal60: '#494849', // linework
    white: '#FFFFFF',
    snowLight: '#FAF8F5',
    snow: '#f2f0ed',
    sand: '#E9E5E1',
    sandDark: '#7d695a',
    turquoise: '#BBEBD7', // accent
    turquoiseUp: '#79ecce',
    turquoiseDark: '#53d0af',
    cold: '#BEF4F3',
    warm: '#fae0d8',
    info: '#79ECEC',
    green: '#39C188', // success
    redLight: '#eecabf', // error
    redDarker: '#C6502A', // error
    orangeLight: '#f8e6c2', // warning
    orange: '#ff9700', // warning
    orangeDarker: '#593c12', // warning
    grey: '#CCCCCC', // disabled
    greylight: '#E3E3E3',
    pink: '#ebbbcf',
};

const colorSchemes = {
    light: {
        palette: {
            primary: {
                main: colors.turquoise,
                dark: colors.turquoiseUp,
                contrastText: colors.charcoal,
            },
            secondary: {
                main: colors.info,
            },
            info: {
                light: lighten(colors.info, 0.6),
                main: colors.info,
                contrastText: colors.white,
            },
            success: {
                main: colors.turquoise,
                dark: colors.turquoiseUp,
                contrastText: colors.charcoal,
            },
            error: {
                light: colors.redLight,
                main: colors.redDarker,
            },
            warning: {
                light: colors.orangeLight,
                main: colors.orange,
            },
            divider: colors.charcoal40,
            background: { default: colors.snow, paper: colors.white },
            AppBar: { defaultBg: colors.charcoal },
            text: {
                primary: colors.charcoal,
                secondary: 'rgb(var(--mui-palette-text-primaryChannel) / 0.7)',
                disabled: 'rgb(var(--mui-palette-text-primaryChannel) / 0.5)',
            },
            action: {
                selectedOpacity: 0.2, // TODO
            },
            Switch: {
                defaultColor: colors.snow, // TODO
            },
            Avatar: {
                defaultBg: '#aaaa',
            },
            Button: {
                inheritContainedBg: colors.snow,
                inheritContainedHoverBg: colors.sand,
            },
            Chip: {
                defaultIconColor: 'inherit',
            },
        },
    },
} satisfies CssVarsThemeOptions['colorSchemes'];

export default colorSchemes;
