import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api, perPage } from './utils';

export type RelatedPersonMeta = components['schemas']['RelatedPersonMeta'];
export type RelatedPersonDetail = components['schemas']['RelatedPersonDetail'];
export type CreateRelatedPerson = components['schemas']['RelatedPersonMetaRequest'];
export type UpdateRelatedPerson = components['schemas']['PatchedRelatedPersonMetaRequest'];
export type PaginatedRelatedPersonList = components['schemas']['PaginatedRelatedPersonMetaList'];
export type VoiDocument = components['schemas']['VoiDocument'];
export type VoiRequestBulkCreateRequest = components['schemas']['VoiRequestBulkCreateRequest'];

// TODO: Implement this as a generated type from the Rest spec

export type ParticipantMeta = {
    id: string;
    name: string;
    date_invited: string;
    status: string;
    forms_and_indemnities: boolean;
    statement_of_position: boolean;
    finalised: boolean;
};

export type RequestMeta = {
    id: string;
    recipient: string;
    theme: string;
    issue: string;
    comment: string;
    status: string;
    response: string;
};

export type StatementOfPositionMeta = {
    id: string;
    workspace: string;
    membership: string | null;
    file: string;
    file_name?: string | null | undefined;
    created_at: string;
    uploaded_by: {
        id: string;
        name: string;
        email: string;
        organisation_name: string | null;
    };
};

export type FinalisationMeta = {
    id: string;
    workspace: string;
    membership: string | null;
    file: string;
    file_name?: string | null | undefined;
    created_at: string;
    uploaded_by: {
        id: string;
        name: string;
        email: string;
        organisation_name: string | null;
    };
};

export type RoleEnum = components['schemas']['RoleEnum'];

export const relatedPersonRoleLabels: Record<RoleEnum, string> = {
    executor: 'Executor',
    administrator: 'Administrator',
    proposed_administrator: 'Proposed administrator',
    next_of_kin: 'Next of kin',
    trustee: 'Trustee',
    estate_beneficiary: 'Estate beneficiary',
    superannuation_beneficiary: 'Superannuation beneficiary',
    life_insurance_beneficiary: 'Life insurance beneficiary',
    claimant: 'Claimant',
    other: 'Other',
};

export function relatedPeopleQuery(workspaceId: string, ordering: string = '-is_subscribed') {
    return {
        queryKey: ['relatedPeople', workspaceId, ordering],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedRelatedPersonList>({
                url:
                    '/related_people?' +
                    new URLSearchParams({ workspace: workspaceId, ordering: ordering }),
                method: 'GET',
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedRelatedPersonList>;
}

export function relatedPersonQuery(id: string) {
    return {
        queryKey: ['relatedPerson', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<RelatedPersonDetail>({
                url: `/related_people/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions<RelatedPersonDetail>;
}

export function createRelatedPersonMutation() {
    return {
        mutationFn: (data) =>
            api<RelatedPersonMeta>({
                url: '/related_people',
                method: 'POST',
                data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<RelatedPersonMeta, any, CreateRelatedPerson, any>;
}

export function updateRelatedPersonMutation(id: string) {
    return {
        mutationFn: (data) =>
            api<RelatedPersonMeta>({
                url: `/related_people/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<RelatedPersonMeta, any, UpdateRelatedPerson, any>;
}

export function deleteRelatedPersonMutation(id: string) {
    return {
        mutationFn: () =>
            api<RelatedPersonMeta>({
                url: `/related_people/${id}`,
                method: 'DELETE',
            }),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<any, any, void, any>;
}

export function bulkRequestVoIMutation() {
    return {
        mutationFn: (data) =>
            api({
                url: '/voi_requests/bulk_create',
                method: 'POST',
                data,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<any, any, VoiRequestBulkCreateRequest, any>;
}

export function uploadVoIDocumentMutation() {
    return {
        mutationFn: (data) => uploadVoIDocument(data.id, data.file),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<
        VoiDocument,
        any,
        {
            id: string;
            file: File;
        },
        any
    >;
}

export function uploadVoIDocument(id: string, file: File) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('related_person', id);

    return api<VoiDocument>({
        url: `/documents/voi`,
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deleteVoIDocumentMutation() {
    return {
        mutationFn: (id: string) => {
            return api<string>({
                url: `/documents/voi/${id}`,
                method: 'DELETE',
            }).then(() => id);
        },
    } satisfies UseMutationOptions<string, any, string, any>;
}

export function subscribeToRelatedPersonMutation() {
    return {
        mutationFn: (id: string) =>
            api<RelatedPersonMeta>({
                url: `/related_people/${id}/subscribe`,
                method: 'POST',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<RelatedPersonMeta, any, string, any>;
}

export function unsubscribeFromRelatedPersonMutation() {
    return {
        mutationFn: (id: string) =>
            api<RelatedPersonMeta>({
                url: `/related_people/${id}/unsubscribe`,
                method: 'POST',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<RelatedPersonMeta, any, string, any>;
}

export function cancelVoIRequestMutation() {
    return {
        mutationFn: (id: string) =>
            api({
                url: `/voi_requests/${id}`,
                method: 'DELETE',
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
        },
    } satisfies UseMutationOptions<any, any, string, any>;
}
