// Copyright (C) 2024, Affinda

import { CircularProgress, Stack, Typography } from '@mui/material';

export default function LoadingArea({ label }: { label?: string }) {
    return (
        <Stack
            sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                m: 'auto',
                p: 3,
            }}
            gap={1}
        >
            <CircularProgress size={36} />
            <Typography fontWeight="bold">{label}</Typography>
        </Stack>
    );
}
