import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    CheckboxElement,
    FormContainer,
    SelectElement,
    TextFieldElement,
} from 'react-hook-form-mui';
import {
    createRelatedPersonMutation,
    relatedPersonRoleLabels,
    updateRelatedPersonMutation,
} from '../../api/relatedPerson';
import { validateString } from '../../validationRules';
import RadioButtonGroupField from '../fields/RadioButtonGroupField';
import ReadonlyField from '../fields/ReadonlyField';
import { TabProps } from './RelatedPersonDrawer';

type RoleOption = keyof typeof relatedPersonRoleLabels;

const ROLE_OPTIONS: readonly RoleOption[] = [
    'trustee',
    'estate_beneficiary',
    'superannuation_beneficiary',
    'life_insurance_beneficiary',
    'claimant',
    'other',
] as const;

const LPR_ROLE_OPTIONS: readonly RoleOption[] = [
    'executor',
    'administrator',
    'proposed_administrator',
    'next_of_kin',
] as const;

const PROFESSIONAL_TRUSTEE_ROLE_OPTIONS: readonly RoleOption[] = [
    'executor',
    'administrator',
    'proposed_administrator',
] as const;

export const RelatedPersonDetails: FC<TabProps> = ({
    workspace,
    person,
    readOnly,
    assumeLpr,
    onCreated,
    onClose,
}) => {
    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            family_name: person?.family_name ?? '',
            given_names: person?.given_names ?? '',
            organisation_name: workspace.created_by.organisation_name,
            is_professional_trustee: !!person?.is_professional_trustee,
            is_lpr: person?.is_lpr ? 'yes' : 'no',
            role: person?.role ?? '',
            role_detail: person?.role_detail ?? '',
        },
    });

    const { setValue, watch, getValues } = form;

    const watchIsProfessionalTrustee = watch('is_professional_trustee');
    const watchRole = watch('role');
    const watchIsLpr = watch('is_lpr');

    const roleOptions = useMemo(() => {
        if (watchIsProfessionalTrustee) {
            return PROFESSIONAL_TRUSTEE_ROLE_OPTIONS;
        }

        if (watchIsLpr === 'yes' || assumeLpr) {
            return LPR_ROLE_OPTIONS;
        }

        return ROLE_OPTIONS;
    }, [watchIsProfessionalTrustee, watchIsLpr]);

    // Reset role when role options change
    useUpdateEffect(() => {
        setValue('role', '');
    }, [roleOptions, setValue]);

    const createMutation = useMutation(createRelatedPersonMutation());
    const updateMutation = useMutation(person ? updateRelatedPersonMutation(person.id) : {});
    const isLoading = createMutation.isPending || updateMutation.isPending;

    const handleSubmit = () => {
        const values = getValues();

        if (!person) {
            createMutation
                .mutateAsync({
                    workspace: workspace.id,
                    family_name: values.family_name,
                    given_names: values.given_names,
                    role: values.role as RoleOption,
                    role_detail: values.role_detail,
                    is_professional_trustee: values.is_professional_trustee,
                })
                .then((person) => {
                    onCreated?.(person);
                    enqueueSnackbar({ variant: 'success', message: 'Associated person created.' });
                });
            return;
        }

        updateMutation
            .mutateAsync({
                role: values.role as RoleOption,
                role_detail: values.role_detail,
            })
            .then(() => {
                enqueueSnackbar({ variant: 'success', message: 'Associated person saved.' });
                onClose?.();
            });
    };

    if (readOnly) {
        return (
            <Stack gap={4}>
                {person?.is_professional_trustee ? (
                    <ReadonlyField
                        label="Organisation name"
                        value={workspace.created_by.organisation_name}
                    />
                ) : (
                    <>
                        <ReadonlyField label="Family name" value={person?.family_name} />
                        <ReadonlyField label="Given name(s)" value={person?.given_names} />
                    </>
                )}
                <ReadonlyField
                    label="Role"
                    value={person?.role ? relatedPersonRoleLabels[person.role] : undefined}
                />

                {person?.role === 'other' && (
                    <ReadonlyField label="Role detail" value={person.role_detail} />
                )}
            </Stack>
        );
    }

    return (
        <FormContainer formContext={form} disabled={isLoading} onSuccess={handleSubmit}>
            <Stack gap={2}>
                {!person && assumeLpr && workspace.can_add_org_as_personal_rep ? (
                    <CheckboxElement
                        name="is_professional_trustee"
                        label="Add your organisation as a Personal Representative of the estate."
                    />
                ) : null}

                {watchIsProfessionalTrustee ? (
                    <TextField
                        label="Organisation name"
                        value={workspace.created_by.organisation_name}
                        variant="filled"
                        fullWidth
                        required
                        disabled
                    />
                ) : (
                    <>
                        <TextFieldElement
                            required
                            label="Given names"
                            name="given_names"
                            fullWidth
                            validation={{
                                validate: (value) =>
                                    validateString(value, {
                                        required: true,
                                        maxLength: 70,
                                        noNumbers: true,
                                        noSpecialChars: true,
                                    }),
                            }}
                            InputProps={{
                                disabled: !!person,
                            }}
                        />
                        <TextFieldElement
                            label="Family name"
                            name="family_name"
                            required
                            validation={{
                                validate: (value) =>
                                    validateString(value, {
                                        required: true,
                                        maxLength: 40,
                                        noNumbers: true,
                                        noSpecialChars: true,
                                    }),
                            }}
                            fullWidth
                            InputProps={{
                                disabled: !!person,
                            }}
                        />
                    </>
                )}

                {!assumeLpr && (
                    <RadioButtonGroupField
                        name="is_lpr"
                        required
                        label={`Is this ${
                            person?.is_professional_trustee ? 'Organisation' : 'person'
                        } a Personal Representative?`}
                        options={[
                            { id: 1, label: 'Yes', value: 'yes' },
                            { id: 2, label: 'No', value: 'no' },
                        ]}
                        valueKey="value"
                    />
                )}

                {watchIsLpr !== undefined && (
                    <SelectElement
                        label="Role"
                        name="role"
                        required
                        options={roleOptions.map((id) => ({
                            id,
                            label: relatedPersonRoleLabels[id],
                        }))}
                    />
                )}

                {watchRole === 'other' && (
                    <TextFieldElement
                        label="Additional detail"
                        name="role_detail"
                        validation={{
                            validate: (value) =>
                                validateString(value, {
                                    required: true,
                                    maxLength: 20,
                                    noSpecialChars: true,
                                }),
                        }}
                        fullWidth
                        required
                    />
                )}

                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{ alignSelf: 'flex-end' }}
                >
                    {person ? 'Save changes' : 'Next'}
                </LoadingButton>
            </Stack>
        </FormContainer>
    );
};
