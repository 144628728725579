import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type EVoiTransaction = components['schemas']['EVoiTransaction'];
type PaginatedEVoiTransactionListRequest =
    operations['e_voi_transaction_list']['parameters']['query'];
type PaginatedEVoiTransactionListResults = components['schemas']['PaginatedEVoiTransactionList'];
export type EVoiStatusEnum = components['schemas']['EVoiStatusEnum'];
export type EVoiTransactionRequest = components['schemas']['EVoiTransactionRequest'];

export function eVoITransactionsQuery(options?: PaginatedEVoiTransactionListRequest) {
    return {
        queryKey: ['e_voi_transactions', options],
        queryFn: async () =>
            await api<PaginatedEVoiTransactionListResults>({
                url: '/e_voi_transaction',
                method: 'GET',
                params: { ...options, per_page: perPage },
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedEVoiTransactionListResults>;
}

export function eVoITransactionQuery(id: string) {
    return {
        queryKey: ['e_voi_transaction', id],
        queryFn: async () =>
            await api<EVoiTransaction>({
                url: `/e_voi_transaction/${id}`,
                method: 'GET',
            }).then((response) => response.data),
    };
}

export function livenessVideoQuery(voiTransactionID: string) {
    return {
        queryKey: ['livenessVideo', voiTransactionID],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<{ url: string }>({
                url: `/e_voi_transaction/${voiTransactionID}/liveness_video`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions<{ url: string }>;
}

export function initiateVoITransactionMutation() {
    return {
        mutationFn: (request) =>
            api<void>({
                url: `/e_voi_transaction`,
                method: 'POST',
                data: request,
            }),
    } satisfies UseMutationOptions<any, any, EVoiTransactionRequest, any>;
}

export function updateVoITransactionMutation(personID: string, transactionID: string) {
    return {
        mutationFn: (status: EVoiStatusEnum) =>
            api<void>({
                url: `/e_voi_transaction/${transactionID}`,
                method: 'PATCH',
                data: { status },
            }),
    } satisfies UseMutationOptions<any, void, EVoiStatusEnum, any>;
}

export function retriggerVoITransactionMutation(id: string) {
    return {
        mutationFn: () =>
            api<void>({
                url: `/e_voi_transaction/${id}/retrigger`,
                method: 'POST',
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['relatedPeople'] });
            queryClient.invalidateQueries({ queryKey: ['relatedPerson', id] });
        },
    } satisfies UseMutationOptions<any, any, void, any>;
}
