import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { z } from 'zod';
import { updateWorkspaceMutation } from '../api/workspace';
import { updateWorkspaceMembershipMutation } from '../api/workspaceMembership';
import useUser from '../hooks/useUser';
import { useWorkspace } from '../pages/workspace/workspaceContext';
import { useWorkspaceMembership } from '../pages/workspace/workspaceMembershipContext';
import { isWorkspaceOwner } from '../utils';
import InlineTextField from './fields/InlineTextField';

function WorkspaceReference() {
    const workspace = useWorkspace();
    const user = useUser();
    const membership = useWorkspaceMembership();

    const value = isWorkspaceOwner(user)
        ? workspace?.case_reference
        : membership?.participant_reference;

    const workspaceUpdate = useMutation(updateWorkspaceMutation(workspace.id));
    const membershipUpdate = useMutation(updateWorkspaceMembershipMutation());

    const handleSave = (newValue: string) => {
        if (isWorkspaceOwner(user)) {
            workspaceUpdate.mutateAsync({ case_reference: newValue }).then(() => {
                enqueueSnackbar('Reference saved', { variant: 'success' });
            });
        } else {
            membershipUpdate
                .mutateAsync({
                    id: membership?.id || '',
                    participant_reference: newValue,
                })
                .then(() => {
                    enqueueSnackbar('Reference saved', { variant: 'success' });
                });
        }
    };

    return (
        <InlineTextField
            defaultValue={value || ''}
            onSave={handleSave}
            validationRule={z
                .string()
                .max(16, 'Must be 16 characters or less.')
                .regex(
                    /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/,
                    'Only letters, numbers, and symbols are allowed.'
                )}
            typographyProps={{
                variant: 'inherit',
                color: 'inherit',
                noWrap: true,
            }}
        />
    );
}

export default WorkspaceReference;
