import { IconButton, Tooltip } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { IconExternalLink, IconTrash } from '@tabler/icons-react';
import { useModal } from 'mui-modal-provider';
import { createElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DocumentUnion } from '../../api/document';
import DeleteDocumentDialog from '../DeleteDocumentDialog';
import DocumentInfectedDialog from '../DocumentInfectedDialog';
import TableRowControls from '../TableRowControls';
import { Column } from './DocumentsTable';

interface DocumentTableRowProps {
    columns: Column[];
    document: DocumentUnion;
    onDelete?: (id: string) => void;
}

function DocumentTableRow({ columns, document, onDelete }: DocumentTableRowProps) {
    const { showModal } = useModal();

    const handleRowClick = () => {
        if (document.is_infected) {
            showModal(DocumentInfectedDialog, {
                document,
                onClose: () => {},
                onDelete: () => onDelete?.(document.id),
            });
        } else {
            window.open(document.file || '', '_blank');
        }
    };

    return (
        <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleRowClick}>
            {columns.map((column) =>
                createElement(column.Component, {
                    document,
                    column,
                    key: column.id,
                })
            )}

            <TableRowControls>
                {!document.is_infected ? (
                    <Tooltip title="Open in new tab">
                        <IconButton
                            component={RouterLink}
                            target="_blank"
                            to={document.file || ''}
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <IconExternalLink />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {onDelete ? (
                    <Tooltip title="Delete document">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                showModal(DeleteDocumentDialog, {
                                    onDelete: () => onDelete(document.id),
                                });
                            }}
                            size="small"
                        >
                            <IconTrash />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </TableRowControls>
        </TableRow>
    );
}

export default DocumentTableRow;
