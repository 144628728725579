import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import { IconShare2 } from '@tabler/icons-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router-dom';
import { listAllDocumentsQuery } from '../api/document';
import { useWorkspace } from '../pages/workspace/workspaceContext';
import Placeholder from './Placeholder';
import QueryProgress from './QueryProgress';
import DocumentsTable from './documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowUploadedByOrganisation,
} from './documentsTable/DocumentsTableCells';

interface ExportDocumentsDialogProps extends DialogProps {
    onClose?: () => void;
}

function ExportDocumentsDialog({ onClose, ...dialogProps }: ExportDocumentsDialogProps) {
    const workspace = useWorkspace();
    const [searchParams] = useSearchParams();

    const sortParam = searchParams.get('sort')?.toString();

    const params = {
        workspace: workspace.id,
        ordering: sortParam,
        exclude_infected: true,
    };

    const query = useQuery({
        ...listAllDocumentsQuery(params),
        placeholderData: keepPreviousData,
    });
    const documents = query.data?.results || [];

    const queryParams = new URLSearchParams({
        ...params,
        ordering: params.ordering ?? '',
        exclude_infected: params.exclude_infected.toString(),
    }).toString();

    return (
        <Dialog onClose={onClose} {...dialogProps} maxWidth="lg" fullWidth>
            <DialogTitle>Export Workspace Documents</DialogTitle>

            <DialogContent
                sx={{
                    overflow: 'hidden',
                    display: 'flex',
                }}
            >
                <QueryProgress query={query}>
                    <DocumentsTable
                        sx={{ overflow: 'auto' }}
                        documents={documents}
                        placeholder={
                            <Placeholder
                                title="No files yet"
                                description="Documents uploaded to the Workspace will appear here"
                            />
                        }
                        columns={[
                            {
                                id: 'file',
                                label: 'File',
                                Component: DocumentTableRowFile,
                            },
                            {
                                id: 'uploadedByOrganisation',
                                label: 'Uploaded by',
                                sortable: 'uploaded_by',
                                Component: DocumentTableRowUploadedByOrganisation,
                            },
                            {
                                id: 'createdAt',
                                label: 'Uploaded',
                                sortable: 'created_at',
                                Component: DocumentTableRowCreatedAt,
                            },
                        ]}
                    />
                </QueryProgress>
            </DialogContent>

            <DialogActions>
                <Button color="inherit" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton
                    component={Link}
                    target="_blank"
                    to={`/api/documents/download?${queryParams}&format=zip`}
                    variant="outlined"
                    color="inherit"
                    loading={false}
                    startIcon={<IconShare2 />}
                >
                    Export all
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ExportDocumentsDialog;
