import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormHelperText,
    Grid,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { DatePickerElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import {
    DeathCertificateDocument,
    DeathCertificateDocumentError,
    DeathCertificateDocumentRequest,
    uploadDeathCertificateMutation,
} from '../../../api/deathCertificate';
import { queryClient } from '../../../api/queryClient';
import { workspaceQuery } from '../../../api/workspace';
import FormGrid from '../../../components/FormGrid';
import DragAndDropFileField from '../../../components/fields/DragAndDropFileField';
import StateSelectField from '../../../components/fields/StateSelectField';
import {
    characterLimit,
    requiredDate,
    requiredFile,
    requiredString,
} from '../../../validationRules';
import { useWorkspace } from '../workspaceContext';

interface DeathCertificateUploadDialogProps extends DialogProps {
    onClose: () => void;
}

const validationSchema = z.object({
    death_cert_reg_no: requiredString.and(characterLimit(12)),
    death_cert_reg_year: requiredDate,
    state: requiredString,
    death_cert: requiredFile,
});

function DeathCertificateUploadDialog({
    onClose,
    ...dialogProps
}: DeathCertificateUploadDialogProps) {
    const workspace = useWorkspace();

    const deathCertificateMutation = useMutation<
        DeathCertificateDocument,
        AxiosError<DeathCertificateDocumentError, any>,
        DeathCertificateDocumentRequest,
        any
    >(uploadDeathCertificateMutation());

    const createDeathCertificateError =
        deathCertificateMutation.error?.response?.data?.type === 'validation_error'
            ? deathCertificateMutation.error?.response?.data?.errors[0].detail
            : '';

    const handleSubmit = (values: any) => {
        deathCertificateMutation.mutateAsync(
            {
                workspace: workspace.id,
                file: values.death_cert,
                registrationNumber: values.death_cert_reg_no,
                registrationYear: (values.death_cert_reg_year as Date).getFullYear(),
                registrationState: values.state,
            },
            {
                onError: (e: any) => {
                    if (e?.response?.status === 400) {
                        e.preventAlert?.();
                    }
                },
                onSuccess: (deathCert: DeathCertificateDocument) => {
                    queryClient.invalidateQueries(workspaceQuery(workspace.id));
                    enqueueSnackbar('Death Certificate uploaded successfully', {
                        variant: 'success',
                    });
                    onClose();
                },
            }
        );
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            state: '',
            death_cert_reg_no: '',
            death_cert_reg_year: null,
        },
        resolver: zodResolver(validationSchema),
    });

    return (
        <Dialog onClose={onClose} scroll="body" fullWidth {...dialogProps} maxWidth="md">
            <DialogTitle>Upload the Death Certificate</DialogTitle>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <FormGrid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                name="death_cert_reg_no"
                                label="Registration number"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                name="death_cert_reg_year"
                                label="Registration year"
                                views={['year']}
                                maxDate={new Date()}
                                sx={{ width: '100%' }}
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <StateSelectField
                                id="state"
                                name="state"
                                label="Registration state or territory"
                                required
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DragAndDropFileField
                                name="death_cert"
                                label="Death Certificate"
                                required
                            />
                        </Grid>
                    </FormGrid>
                </DialogContent>

                <DialogActions>
                    <FormHelperText error>{createDeathCertificateError}</FormHelperText>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" color="primary">
                        Save
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default DeathCertificateUploadDialog;
