import { CreateRequestMeta } from '../../../api/requests';
import { User } from '../../../api/user';

export const selectUserType = (
    u: User,
    type: Exclude<User['user_type'], 'admin'>
): User['de_rep_organisation'] | User['institution_organisation'] => {
    switch (type) {
        case 'de_representative':
            return u.de_rep_organisation;

        case 'institution_employee':
            return u.institution_organisation;

        default:
            // exhaustive pattern match
            return type satisfies never;
    }
};

export type Issue = { label: string; guidance: string };
export type ThemeDetails = {
    label: string;
    issues: Array<Issue>;
};

export type ThemesIssuesMap = { [k in CreateRequestMeta['theme']]?: ThemeDetails };

type ParticipantThemes = Extract<
    CreateRequestMeta['theme'],
    | 'estate_details'
    | 'proof_of_death'
    | 'proof_of_authority'
    | 'proof_of_identity'
    | 'instructions_and_indemnities'
    | 'statement_of_position'
    | 'finalisation'
>;

export const themeLabels: { [k in ParticipantThemes]: string } = {
    estate_details: 'Estate Details',
    proof_of_death: 'Proof of Death',
    proof_of_authority: 'Proof of Authority',
    proof_of_identity: 'Proof of Identity',
    instructions_and_indemnities: 'Account Instructions',
    statement_of_position: 'Statement of Position',
    finalisation: 'Account Finalisation',
};

export const participantIssues: { [k in ParticipantThemes]: ThemeDetails } = {
    estate_details: {
        label: 'Estate Details',
        issues: [
            { label: 'DOB not aligned to our records', guidance: 'Provide detail of discrepancy' },
            {
                label: 'Name not aligned to our records',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Address not aligned to our records',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Reference no. does not match other detail',
                guidance: 'Provide detail of discrepancy',
            },
        ],
    },
    proof_of_death: {
        label: 'Proof of Death',
        issues: [
            {
                label: 'ADC validation does not match attached Death Certificate',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Document illegible/corrupted',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'English translation required',
                guidance: 'Confirm which document, and nature of issue',
            },
            { label: 'Other', guidance: 'Confirm which document, and nature of issue' },
        ],
    },
    proof_of_authority: {
        label: 'Proof of Authority',
        issues: [
            { label: 'Proof of Authority documents required and not provided', guidance: '' },
            { label: 'Personal Representative detail missing', guidance: '' },
            {
                label: 'Grant of representation will be required',
                guidance: 'Provide rationale for request',
            },
            {
                label: 'Reseal of grant will be required',
                guidance: 'Provide rationale for request',
            },
            { label: 'Detail does not match documents', guidance: 'Provide detail of discrepancy' },
            { label: "SA Registrar's Certificate required", guidance: '' },
            {
                label: 'Missing/incorrect detail (NSW/SA grants)',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Renunciation of probate required',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Document illegible/corrupted/missing pages',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'English translation required',
                guidance: 'Confirm which document requires translation',
            },
            { label: 'Other', guidance: 'Provide as much detail as possible' },
        ],
    },
    proof_of_identity: {
        label: 'Proof of Identity',
        issues: [
            {
                label: 'Associated Person missing',
                guidance: 'Provide detail of missing individual',
            },
            { label: 'ID documents expired/ineligible', guidance: 'Provide detail of discrepancy' },
            {
                label: 'ID document mismatch',
                guidance: 'Provide detail of discrepancy and what is required to resolve',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Document illegible/corrupted',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'English translation required',
                guidance: 'Confirm which document requires translation',
            },
            { label: 'Other', guidance: 'Provide as much detail as possible' },
        ],
    },
    instructions_and_indemnities: {
        label: 'Account Instructions',
        issues: [
            { label: 'Document not executed correctly', guidance: 'Provide detail of discrepancy' },
            {
                label: 'Signature mismatch',
                guidance: 'Provide detail of discrepancy and what is required to resolve',
            },
            {
                label: 'Document illegible/corrupted',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            { label: 'Other', guidance: 'Provide as much detail as possible' },
        ],
    },
    statement_of_position: {
        label: 'Statement of Position',
        issues: [{ label: 'Other', guidance: 'Provide as much detail as possible' }],
    },
    finalisation: {
        label: 'Account Finalisation',
        issues: [{ label: 'Other', guidance: 'Provide as much detail as possible' }],
    },
};

type ProfessionalRepThemes = Extract<
    CreateRequestMeta['theme'],
    'statement_of_position' | 'instructions_and_indemnities' | 'finalisation'
>;
export const professionalRepIssues: { [k in ProfessionalRepThemes]: ThemeDetails } = {
    statement_of_position: {
        label: 'Statement of Position',
        issues: [
            { label: 'Missing asset details', guidance: 'Provide detail of discrepancy' },
            { label: 'Missing other detail', guidance: 'Provide detail of discrepancy' },
            { label: 'Document incomplete', guidance: 'Provide detail of discrepancy' },
            {
                label: 'Incorrectly addressed',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Document illegible/corrupted',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            { label: 'Other', guidance: 'Provide as much detail as possible' },
        ],
    },
    instructions_and_indemnities: {
        label: 'Account Instructions',
        issues: [
            { label: 'Document incomplete', guidance: '' },
            { label: 'Document illegible/corrupted', guidance: '' },
            { label: 'Incorrect document attached', guidance: '' },
            { label: 'Other', guidance: '' },
        ],
    },
    finalisation: {
        label: 'Account Finalisation',
        issues: [
            { label: 'Missing asset details ', guidance: 'Provide detail of discrepancy' },
            { label: 'Missing other detail ', guidance: 'Provide detail of discrepancy' },
            { label: 'Document incomplete', guidance: 'Provide detail of discrepancy' },
            {
                label: 'Incorrectly addressed',
                guidance: 'Provide detail of discrepancy',
            },
            {
                label: 'Document illegible/corrupted',
                guidance: 'Confirm which document, and nature of issue',
            },
            {
                label: 'Incorrect document attached',
                guidance: 'Confirm which document, and nature of issue',
            },
            { label: 'Other', guidance: 'Provide as much detail as possible' },
        ],
    },
};
