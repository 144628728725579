import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormLabel, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { LoaderFunction, Link as RouterLink, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { deleteGenericDocumentMutation } from '../../../../api/genericDocument';
import { queryClient } from '../../../../api/queryClient';
import {
    updateWorkspaceMembershipMutation,
    workspaceMembershipQuery,
} from '../../../../api/workspaceMembership';
import InfoTooltip from '../../../../components/InfoTooltip';
import QueryProgress from '../../../../components/QueryProgress';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import DragAndDropFileField from '../../../../components/fields/DragAndDropFileField';
import ReadonlyField from '../../../../components/fields/ReadonlyField';
import TextFieldArray from '../../../../components/fields/TextFieldArray';
import {
    characterLimit,
    noSpecialChars,
    optionalFile,
    optionalString,
} from '../../../../validationRules';
import PageLayout from '../../PageLayout';

const validationRules = z.object({
    customer_number: optionalString.and(characterLimit(30)).and(noSpecialChars),
    comments: optionalString.and(characterLimit(800)).and(noSpecialChars),
    letter_file: optionalFile,
    known_holdings: z.array(z.string()),
});

type UpdateParticipantFields = z.infer<typeof validationRules>;

export const loader: LoaderFunction = async ({ params: { workspaceId, participantId } }) => {
    invariant(workspaceId);
    invariant(participantId);

    const participantQ = workspaceMembershipQuery(participantId);

    return await queryClient.fetchQuery(participantQ);
};

export default function ParticipantDetail() {
    const { participantId } = useParams();
    const query = useQuery(workspaceMembershipQuery(participantId as string));
    const participant = query.data;

    const updateParticipant = useMutation(updateWorkspaceMembershipMutation());

    const formMethods = useForm<UpdateParticipantFields>({
        mode: 'onChange',
        defaultValues: {
            customer_number: participant?.customer_number,
            comments: participant?.comments,
            letter_file: undefined,
            known_holdings: participant?.known_holdings || [],
        },
        resolver: zodResolver(validationRules),
    });

    const handleSuccess = () => {
        const values = formMethods.getValues();
        updateParticipant
            .mutateAsync({
                id: participantId as string,
                customer_number: values.customer_number,
                comments: values.comments,
                letter_file: values.letter_file,
                known_holdings: values.known_holdings,
            })
            .then(() => {
                enqueueSnackbar('Participant updated', { variant: 'success' });
                queryClient.invalidateQueries(workspaceMembershipQuery(participantId as string));
            });
    };

    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceMembershipQuery(participantId as string));
            },
        });
    };

    return (
        <PageLayout
            title={
                <Stack
                    direction="row"
                    gap={2}
                    sx={{
                        alignItems: 'center',
                        pl: 1,
                    }}
                >
                    <Tooltip title={`Back to Summary`} placement="top">
                        <IconButton component={RouterLink} to=".." edge="start">
                            <IconArrowLeft />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h3" noWrap>
                        {query.data?.organisation_name}
                    </Typography>
                </Stack>
            }
        >
            <QueryProgress query={query}>
                <FormContainer formContext={formMethods} onSuccess={handleSuccess}>
                    <Stack gap={4} sx={{ px: 0.5 }}>
                        <Stack direction="row" gap={6}>
                            <ReadonlyField
                                label="Status"
                                value={`${participant?.status_label}${
                                    participant?.status === 'declined'
                                        ? ' - ' + participant.decline_reason
                                        : ''
                                }`}
                            />
                        </Stack>
                        <TextFieldElement
                            fullWidth
                            name="customer_number"
                            label={
                                <Stack direction="row" gap={1}>
                                    <FormLabel id="customer-identifier-label">
                                        Customer identifier
                                    </FormLabel>
                                </Stack>
                            }
                            InputProps={{
                                endAdornment: (
                                    <InfoTooltip
                                        title={
                                            participant?.industry_code_label === 'Share Registries'
                                                ? "Add any known SRN, HIN or other security holder identifier to support easy identification of the deceased's customer record."
                                                : "Add any known account or customer number to support easy identification of the deceased's customer record."
                                        }
                                    />
                                ),
                            }}
                        />
                        {participant?.industry_code_label === 'Share Registries' ? (
                            <TextFieldArray
                                label={
                                    <Stack direction="row" gap={1}>
                                        <FormLabel id="known-holdings-label">
                                            Known holdings
                                        </FormLabel>
                                        <InfoTooltip title="Add company (or similar) name of any known security holdings to support easy identification of the deceased's customer record." />
                                    </Stack>
                                }
                                itemLabel={(index) => `Known holding ${index + 1}`}
                                name="known_holdings"
                                addMessage="Add a known holding"
                            />
                        ) : null}
                        {participant?.status === 'invited' ? (
                            <TextFieldElement
                                name="comments"
                                fullWidth
                                multiline
                                minRows={5}
                                maxRows={10}
                                label="Comments/Queries/Requests (optional)"
                                placeholder="Add here any relevant comments specific to this Participant that are not covered in any attached document."
                            />
                        ) : (
                            <ReadonlyField
                                label="Comments/Queries/Requests"
                                value={participant?.comments}
                            />
                        )}
                        <Stack gap={0.75}>
                            <FormLabel id="upload-letter-label">
                                Initial/notification letter
                            </FormLabel>

                            {!!participant?.letter && (
                                <DocumentsTable
                                    documents={[participant?.letter]}
                                    onDelete={
                                        participant?.status === 'invited' ? handleDelete : undefined
                                    }
                                />
                            )}

                            {!participant?.letter && participant?.status !== 'invited' && (
                                <Typography variant="body2">Not provided</Typography>
                            )}

                            {!participant?.letter && participant?.status === 'invited' && (
                                <DragAndDropFileField name="letter_file" multiple={false} />
                            )}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            sx={{
                                pb: 4,
                                bottom: 0,
                                zIndex: 3,
                            }}
                        >
                            <Stack direction="row" width="fit-content">
                                <Button
                                    sx={{ pb: -4 }}
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </FormContainer>
            </QueryProgress>
        </PageLayout>
    );
}
