import { format, isValid, parseISO } from 'date-fns';

// Format datetime as DD Mon YY (e.g. 20 Mar 2024)
function DateString(date?: Date | string | number | null): string {
    if (date === null || date === undefined) return '';

    date = typeof date === 'string' ? parseISO(date) : date;
    if (!isValid(date)) {
        return '';
    }

    return format(date, 'dd MMM yyyy');
}

export default DateString;
