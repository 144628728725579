// Copyright (C) 2024, Affinda

import { IconButton, IconButtonProps, ListItemButton, ListItemButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Link, useMatch, useNavigation, useResolvedPath } from 'react-router-dom';

export interface NavListItemProps extends ListItemButtonProps {
    to: string;
    end?: boolean;
}

function useNavButton(to: string, end?: boolean) {
    const pathname = useResolvedPath(to).pathname;
    const match = useMatch({ path: pathname + (end ? '' : '/*') });
    const navigation = useNavigation();
    const nextLocationPathname = navigation.location?.pathname;

    const isActive = Boolean(match);
    const isPending =
        nextLocationPathname != null &&
        (nextLocationPathname === pathname ||
            (!end &&
                nextLocationPathname.startsWith(pathname) &&
                nextLocationPathname.charAt(pathname.length) === '/'));

    return {
        isActive,
        isPending,
    };
}

function NavListItem(props: NavListItemProps) {
    const { to, end, ...listItemButtonProps } = props;
    const { isActive, isPending } = useNavButton(to, end);

    return (
        <ListItemButton
            component={Link}
            to={to}
            selected={isActive}
            aria-current={isActive ? 'page' : undefined}
            className={!isActive && isPending ? 'Mui-focusVisible' : undefined}
            {...(listItemButtonProps as any)}
            sx={{
                ...listItemButtonProps.sx,
            }}
        />
    );
}

interface NavListIconProps extends IconButtonProps {
    to: string;
    end?: boolean;
}

// uses icon buttons instead of list item buttons
const NavListIcon = forwardRef<HTMLButtonElement, NavListIconProps>(
    function NavListIcon(props, ref) {
        const { to, end, ...listItemButtonProps } = props;
        const { isActive, isPending } = useNavButton(to, end);

        return (
            <IconButton
                ref={ref}
                component={Link}
                to={to}
                selected={isActive}
                aria-current={isActive ? 'page' : undefined}
                className={!isActive && isPending ? 'Mui-focusVisible' : undefined}
                sx={{
                    color: isActive ? 'text.primary' : 'text.secondary',
                    backgroundColor: isActive ? '--mui-action-selected' : 'transparent',
                }}
                {...(listItemButtonProps as any)}
            />
        );
    }
);

export { NavListIcon, NavListItem };
