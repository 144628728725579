import { MutationCache, QueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,

            // Just enough stale time so that a query can be fetched in a route
            // loader, and then when it is used in the rendered component, it
            // won't be refetched immediately.
            staleTime: 5000,
        },
    },
    mutationCache: new MutationCache({
        onError: (error: any) => {
            const alertTimeout = setTimeout(() => {
                const data = 'details' in error ? error.details : error.response?.data;
                const message = data?.errors?.[0]?.detail || 'Something went wrong!';
                enqueueSnackbar(message, { variant: 'error' });
            });
            if (error) {
                error.preventAlert = () => clearTimeout(alertTimeout);
            }
        },
    }),
});
