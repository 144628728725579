import {
    Box,
    List,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { IconHome, IconTools } from '@tabler/icons-react';
import { Outlet } from 'react-router-dom';
import { canViewStandaloneTools, canViewWorkspaces } from '../../api/user';
import { NavListIcon, NavListItem } from '../../components/NavListItem';
import useUser from '../../hooks/useUser';

function Sidebar() {
    return (
        <Box
            flexGrow={1}
            sx={{
                display: 'grid',
                gridTemplateColumns: 'fit-content(6ch) 1fr',
                overflowX: 'hidden',
            }}
        >
            <SidebarContent />
            <Outlet />
        </Box>
    );
}

function SidebarContent() {
    const theme = useTheme();
    const expanded = useMediaQuery(theme.breakpoints.up('xl'));
    const user = useUser();

    return (
        <Stack
            component="nav"
            sx={{
                p: 2,
                mr: 2,
                px: 1,
            }}
        >
            {expanded ? (
                <List>
                    {canViewWorkspaces(user) && (
                        <NavListItem sx={{ borderRadius: 5, p: 2, px: 3 }} to="" end>
                            <ListItemIcon>
                                <IconHome />
                            </ListItemIcon>
                            <ListItemText>Workspaces</ListItemText>
                        </NavListItem>
                    )}
                    {canViewStandaloneTools(user) && (
                        <NavListItem
                            sx={{ marginY: 1, borderRadius: 5, padding: 2, pl: 3, pr: 4 }}
                            to="/standalone-tools"
                            end
                        >
                            <ListItemIcon>
                                <IconTools />
                            </ListItemIcon>
                            <ListItemText>Standalone Tools</ListItemText>
                        </NavListItem>
                    )}
                </List>
            ) : (
                <Stack gap={1}>
                    {canViewWorkspaces(user) && (
                        <Tooltip title="Workspaces" placement="right">
                            <NavListIcon to={''} size="large">
                                <IconHome />
                            </NavListIcon>
                        </Tooltip>
                    )}
                    {canViewStandaloneTools(user) && (
                        <Tooltip title="Standalone tools" placement="right">
                            <NavListIcon to="/standalone-tools" size="large">
                                <IconTools />
                            </NavListIcon>
                        </Tooltip>
                    )}
                </Stack>
            )}
        </Stack>
    );
}

export default Sidebar;
