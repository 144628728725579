import { Box, BoxProps, Stack, Typography } from '@mui/material';
import UploadButton from './UploadButton';

interface UploadPlaceholderProps extends BoxProps {
    onUpload: (file: File) => void;
    multiple?: boolean;
    disabled?: boolean;
}

export function UploadPlaceholder({
    onUpload,
    multiple,
    disabled,
    children,
    ...boxProps
}: UploadPlaceholderProps) {
    return (
        <Box
            {...boxProps}
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.paper',
                border: 2,
                borderStyle: 'dashed',
                borderColor: 'divider',
                borderRadius: 1,
                padding: 4,
                color: disabled ? 'text.disabled' : 'inherit',
                ...boxProps.sx,
            }}
        >
            <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
                <Typography> Drag and drop file{multiple ? 's' : ''} here or </Typography>
                <UploadButton
                    onUpload={onUpload}
                    multiple={multiple}
                    variant="outlined"
                    color="inherit"
                    disabled={disabled}
                >
                    Select file{multiple ? 's' : ''}
                </UploadButton>
            </Stack>
        </Box>
    );
}

export default UploadPlaceholder;
