import { Alert, Box, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import {
    proofOfIdentityTaskQuery,
    updateProofOfIdentityTask,
} from '../../../../api/proofOfIdentity';
import { queryClient } from '../../../../api/queryClient';
import {
    PaginatedRelatedPersonList,
    RelatedPersonMeta,
    cancelVoIRequestMutation,
    relatedPeopleQuery,
    subscribeToRelatedPersonMutation,
    unsubscribeFromRelatedPersonMutation,
} from '../../../../api/relatedPerson';
import { workspaceMembershipQuery } from '../../../../api/workspaceMembership';
import CompleteTaskButton from '../../../../components/CompleteTaskButton';
import QueryProgress from '../../../../components/QueryProgress';
import { RelatedPeopleTable } from '../../../../components/relatedPeople/RelatedPeopleTable';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';
import RequestVoISnackbar from './RequestVoISnackbar';

function ProofOfIdentity() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const poiTaskID = workspace.tasks.proof_of_identity;
    invariant(poiTaskID);

    const taskQueryResult = useQuery(proofOfIdentityTaskQuery(poiTaskID));
    const poiTask = taskQueryResult.data;

    const relatedPeopleQueryResult = useQuery(relatedPeopleQuery(workspace.id, '-is_subscribed'));
    const relatedPeople = relatedPeopleQueryResult.data?.results ?? [];

    const updateMutation = useMutation(updateProofOfIdentityTask(poiTaskID));
    const subscribeMutation = useMutation(subscribeToRelatedPersonMutation());
    const unsubscribeMutation = useMutation(unsubscribeFromRelatedPersonMutation());
    const cancelVoIMutation = useMutation(cancelVoIRequestMutation());

    const acceptTask = () => updateStatus('completed', 'Proof of Identity Accepted.');
    const undoAcceptTask = () => updateStatus('ready_for_action', 'Action undone.');

    const updateStatus = (status: string, successMessage: string) => {
        updateMutation.mutate(
            { status: status as any },
            {
                onSuccess: () => {
                    enqueueSnackbar(successMessage, { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleToggleSubscription = useCallback(
        (person: RelatedPersonMeta) => {
            const mutation = person.is_subscribed ? unsubscribeMutation : subscribeMutation;

            setSelectedForRequest((prev) => prev.filter((p) => p !== person));

            // optimistically update the query data
            queryClient.setQueryData<PaginatedRelatedPersonList>(
                relatedPeopleQuery(workspace.id, '-is_subscribed').queryKey,
                (oldData) => {
                    if (!oldData) return oldData;
                    return {
                        ...oldData,
                        results: oldData.results.map((p) =>
                            p.id === person.id ? { ...p, is_subscribed: !p.is_subscribed } : p
                        ),
                    };
                }
            );

            mutation.mutate(person.id, {
                onError: () => {
                    // revert optimistic update
                    queryClient.setQueryData<PaginatedRelatedPersonList>(
                        relatedPeopleQuery(workspace.id, '-is_subscribed').queryKey,
                        (oldData) => {
                            if (!oldData) return oldData;
                            return {
                                ...oldData,
                                results: oldData.results.map((p) =>
                                    p.id === person.id
                                        ? { ...p, is_subscribed: !p.is_subscribed }
                                        : p
                                ),
                            };
                        }
                    );
                },
            });
        },
        [subscribeMutation, unsubscribeMutation, queryClient, workspace.id]
    );

    const [selectedForRequest, setSelectedForRequest] = useState<RelatedPersonMeta[]>([]);

    const handleToggleRequest = (person: RelatedPersonMeta) => {
        setSelectedForRequest((prev) => {
            const isSelected = prev.includes(person);
            if (isSelected) {
                const updated = prev.filter((p) => p !== person);
                return updated;
            } else {
                return [...prev, person];
            }
        });
    };

    const isPersonSelectedForRequest = useMemo(
        () => (person: RelatedPersonMeta) => selectedForRequest.includes(person),
        [selectedForRequest]
    );

    const handleCancelVoIRequest = useCallback(
        (person: RelatedPersonMeta) => {
            if (person.voi_request) {
                cancelVoIMutation.mutate(person.voi_request, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(relatedPeopleQuery(workspace.id));
                        enqueueSnackbar('VoI request cancelled successfully', {
                            variant: 'success',
                        });
                    },
                });
            }
        },
        [cancelVoIMutation, workspace.id]
    );

    return (
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <QueryProgress query={relatedPeopleQueryResult}>
                <PageLayout
                    title="Proof of Identity"
                    actions={
                        <Stack direction="row" gap={1.5} alignItems="center">
                            <CompleteTaskButton
                                completed={poiTask?.status === 'completed'}
                                onComplete={acceptTask}
                                onUndo={undoAcceptTask}
                            />
                        </Stack>
                    }
                >
                    <Stack gap={2}>
                        <Alert severity="info">
                            Select the Associated People relevant to your customer scenario and
                            identity requirements.
                        </Alert>
                        <RelatedPeopleTable
                            relatedPeople={relatedPeople}
                            rowProps={{
                                onClick: (person) => {
                                    showModal(RelatedPersonDrawer, {
                                        workspace,
                                        person,
                                        readOnly: true,
                                    });
                                },
                                onToggleSubscription: handleToggleSubscription,
                                onRequest: handleToggleRequest,
                                isPersonSelectedForRequest: isPersonSelectedForRequest,
                                onCancelVoIRequest: handleCancelVoIRequest,
                            }}
                        />
                    </Stack>
                </PageLayout>
            </QueryProgress>
            {selectedForRequest.length > 0 && (
                <RequestVoISnackbar
                    selectedPeople={selectedForRequest}
                    onClose={() => setSelectedForRequest([])}
                />
            )}
        </Box>
    );
}

export default ProofOfIdentity;
