import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import {
    RelatedPersonMeta,
    VoiRequestBulkCreateRequest,
    bulkRequestVoIMutation,
} from '../../../../api/relatedPerson';
import ActionsSnackbar from '../../../../components/ActionsSnackbar';
import useUser from '../../../../hooks/useUser';

export interface RequestVoISnackbarProps {
    selectedPeople: RelatedPersonMeta[];
    onClose: () => void;
}

function RequestVoISnackbar({ selectedPeople, onClose }: RequestVoISnackbarProps) {
    const user = useUser();
    const requestVoIMutation = useMutation(bulkRequestVoIMutation());

    const handleSubmit = () => {
        const request: VoiRequestBulkCreateRequest = {
            voi_requests: selectedPeople.map((person) => ({
                related_person: person.id,
                organisation: user?.institution_organisation.id || '',
            })),
        };

        requestVoIMutation.mutateAsync(request).then(() => {
            enqueueSnackbar(`Requested verification for ${request.voi_requests.length} people`, {
                variant: 'success',
            });
            onClose?.();
        });
    };

    return (
        <ActionsSnackbar
            open={selectedPeople.length > 0}
            icon={false}
            action={
                <Stack direction="row" gap={1}>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={requestVoIMutation.status === 'pending'}
                        variant="outlined"
                        color="inherit"
                        onClick={handleSubmit}
                    >
                        Send
                    </LoadingButton>
                </Stack>
            }
        >
            Request Electronic Verification for {selectedPeople.length}{' '}
            {selectedPeople.length === 1 ? 'person' : 'people'}
        </ActionsSnackbar>
    );
}

export default RequestVoISnackbar;
