import { FormControlLabel, FormControlLabelProps, Radio, RadioProps } from '@mui/material';

export interface SmallRadioProps extends Omit<FormControlLabelProps, 'onChange' | 'control'> {
    checked?: boolean;
    indeterminate?: boolean;
    onChange?: RadioProps['onChange'];
    checkboxProps?: RadioProps;
}

export default function SmallRadio(props: SmallRadioProps) {
    const { checked, indeterminate, onChange, checkboxProps, ...formControlLabelProps } = props;

    return (
        <FormControlLabel
            control={
                <Radio
                    size="small"
                    checked={checked}
                    onChange={onChange}
                    sx={{ mr: -0.5 }}
                    {...checkboxProps}
                />
            }
            {...formControlLabelProps}
            componentsProps={{ typography: { variant: 'body2' } }}
            sx={{
                ...formControlLabelProps.sx,
            }}
        />
    );
}
