import { useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { WorkspaceDetail, workspaceQuery } from '../../api/workspace';

const WorkspaceContext = createContext<WorkspaceDetail | undefined>(undefined);

export const WorkspaceProvider = WorkspaceContext.Provider;

export function useWorkspace() {
    const initialData = useContext(WorkspaceContext);
    if (initialData === undefined) {
        throw new Error('useWorkspace must be used within a WorkspaceProvider');
    }

    const { data: workspace } = useQuery({
        ...workspaceQuery(initialData.id),
        initialData: initialData,
    });

    return workspace;
}
