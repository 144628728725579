import { CircularProgress } from '@mui/material';
import { Outlet, redirect, RouteObject } from 'react-router-dom';
import { fetchUser, isLoggedIn } from '../api/user';
import { appSettings } from '../appSettings';
import AuthLayout from '../pages/auth/AuthLayout';
import Login from '../pages/auth/Login';

const authRoutes: RouteObject = {
    path: '/auth',
    loader: async () => {
        const user = await fetchUser();
        if (isLoggedIn(user)) return redirect('/');
        if (appSettings.auth0Enabled && !appSettings.showDisclaimer) {
            // We can't use React Router's redirect here because it only handles internal routing.
            // We need to redirect to a server-side API endpoint URL to initiate Auth0 authentication.
            window.location.href = '/api/auth/auth0_login/';
            // Return a promise that never resolves to prevent the children from being rendered and
            // the login page from flashing on the screen.
            return new Promise(() => {});
        }
        return user;
    },
    element: <Outlet />,
    children: [
        {
            path: '',
            element: <CircularProgress />,
        },
        {
            path: 'login',
            element: (
                <AuthLayout>
                    <Login />
                </AuthLayout>
            ),
        },
    ],
};

export default authRoutes;
