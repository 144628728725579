import {
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    Paper,
    Stack,
    StackProps,
} from '@mui/material';
import { IconFile, IconTrash } from '@tabler/icons-react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form-mui';
import { colors } from '../../theme/palette';
import IconLabel from '../IconLabel';
import UploadDropArea from '../UploadDropArea';
import { UploadPlaceholder } from '../UploadPlaceholder';
import DragAndDropFileFieldArray from './DragAndDropFileFieldArray';

interface FileFieldProps extends StackProps {
    name: string;
    label?: string;
    multiple?: boolean;
    required?: boolean;
}

function DragAndDropFileField({
    name,
    label,
    multiple = false,
    required = false,
    ...stackProps
}: FileFieldProps) {
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const field = watch(name);

    if (multiple)
        return <DragAndDropFileFieldArray name={name} label={label} required={required} />;

    const fieldError = get(errors, name) as FieldError;
    // The error will either be on the field itself if applied by the zod validation schema,
    // or on the root error if applied via useFieldArray.
    const errorMessage = fieldError?.message || (fieldError?.root as FieldError)?.message;

    const onUpload = (file: File) => {
        setValue(name, file);
    };

    return (
        <Controller
            name={name}
            render={() => (
                <FormControl required={required} error={!!fieldError} fullWidth>
                    <Stack gap={1} {...stackProps}>
                        {label ? <FormLabel required={required}>{label}</FormLabel> : null}

                        <UploadDropArea onUpload={onUpload} multiple={multiple}>
                            <UploadPlaceholder onUpload={onUpload} multiple={multiple} />
                        </UploadDropArea>

                        {errorMessage ? (
                            <FormHelperText error>{errorMessage}</FormHelperText>
                        ) : null}

                        {!!field ? (
                            <FileRow
                                name={field.name || ''}
                                onDelete={() => setValue(name, undefined)}
                            />
                        ) : null}
                    </Stack>
                </FormControl>
            )}
        />
    );
}

export function FileRow({ name, onDelete }: { name: string; onDelete?: () => void }) {
    return (
        <Paper sx={{ backgroundColor: colors.sand, borderRadius: 5 }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1, px: 2, width: '100%' }}
            >
                <IconLabel icon={<IconFile />} label={name} />
                <IconButton aria-label="Remove" onClick={onDelete}>
                    <IconTrash />
                </IconButton>
            </Stack>
        </Paper>
    );
}

export default DragAndDropFileField;
