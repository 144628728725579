import { FormControl, FormHelperText, FormLabel, Stack, StackProps } from '@mui/material';
import { Controller, FieldError, get, useFieldArray, useFormContext } from 'react-hook-form-mui';
import UploadDropArea from '../UploadDropArea';
import { UploadPlaceholder } from '../UploadPlaceholder';
import { FileRow } from './DragAndDropFileField';

interface FileFieldProps extends StackProps {
    name: string;
    label?: string;
    required?: boolean;
}

function DragAndDropFileFieldArray({
    name,
    label,
    required = false,
    ...stackProps
}: FileFieldProps) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        shouldUnregister: true,
        name,
        rules: required ? { required: 'This field is required' } : undefined,
    });

    const fieldError = get(errors, name) as FieldError;
    // The error will either be on the field itself if applied by the zod validation schema,
    // or on the root error if applied via useFieldArray.
    const errorMessage = fieldError?.message || (fieldError?.root as FieldError)?.message;

    const onUpload = (file: File) => {
        append(file);
    };

    return (
        <FormControl required={required} error={!!fieldError} fullWidth>
            <Stack gap={1} {...stackProps}>
                {label ? <FormLabel required={required}>{label}</FormLabel> : null}

                <UploadDropArea onUpload={onUpload} multiple>
                    <UploadPlaceholder onUpload={onUpload} multiple />
                </UploadDropArea>

                {errorMessage ? <FormHelperText error>{errorMessage}</FormHelperText> : null}

                {fields.map((field, index) => (
                    <Controller
                        key={field.id}
                        control={control}
                        name={`${name}.${index}`}
                        render={({ field }) => (
                            <FileRow name={field.value?.name} onDelete={() => remove(index)} />
                        )}
                    />
                ))}
            </Stack>
        </FormControl>
    );
}

export default DragAndDropFileFieldArray;
