import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { NoteMeta, NoteParams, notesQuery } from '../../../api/notes';
import DateTimeString from '../../../components/DateTimeString';
import InfoTooltip from '../../../components/InfoTooltip';
import Placeholder from '../../../components/Placeholder';
import TableRowControls from '../../../components/TableRowControls';
import useUser from '../../../hooks/useUser';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import CreateNoteDialog from './CreateNoteDialog';
import DeleteNoteDialog from './DeleteNoteDialog';
import NoteDrawer from './NoteDrawer';

function Notes() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const queryOptions: NoteParams = {
        workspace: workspace.id,
    };

    const query = useQuery({
        ...notesQuery(queryOptions),
        placeholderData: (previousData, _) => previousData,
    });

    return (
        <PageLayout
            title={
                <Stack direction="row" gap={3} alignItems="center">
                    Notes
                    <InfoTooltip title="Notes left here are only visible to other users from your organisation." />
                </Stack>
            }
            actions={
                <Button variant="contained" onClick={() => showModal(CreateNoteDialog)}>
                    Create Note
                </Button>
            }
        >
            <Stack direction="row" gap={2} alignItems="center">
                <Box sx={{ flexGrow: 1 }} />
                {query.isRefetching ? <CircularProgress size={20} /> : null}
            </Stack>
            {!query.data?.results.length ? (
                <Placeholder
                    title="No Notes yet"
                    description={`Create a Note to keep track of important information.`}
                />
            ) : (
                <Stack gap={2} sx={{ mt: -1 }}>
                    <TableContainer sx={{ overflowX: 'hidden' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Theme</TableCell>
                                    <TableCell>Content</TableCell>
                                    <TableCell>Left By</TableCell>
                                    <TableCell>Updated</TableCell>
                                    {/* Spacer for row action icon */}
                                    <TableCell sx={{ width: '3.5rem' }} />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {query.data?.results.map((note) => (
                                    <NoteRow key={note.id} note={note} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            )}
        </PageLayout>
    );
}

interface NoteRow {
    note: NoteMeta;
}

function NoteRow({ note }: NoteRow) {
    const user = useUser();
    const isNoteCreatedByUser = note.created_by.email === user?.email;
    const { showModal } = useModal();

    return (
        <>
            <TableRow
                key={note.id}
                hover
                onClick={() => showModal(NoteDrawer, { note })}
                sx={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    backgroundColor: 'inherit',
                    '&:hover': {
                        backgroundColor: 'background.paper',
                    },
                }}
            >
                <TableCell>{note.theme}</TableCell>
                <TableCell
                    sx={{
                        maxWidth: '15rem',
                        maxHeight: '2rem',
                    }}
                >
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2' /* number of lines to show */,
                            lineClamp: '2' /* fallback for other browsers */,
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                        }}
                    >
                        {note.content}
                    </div>
                </TableCell>
                <TableCell>{note.created_by.name}</TableCell>
                <TableCell>{DateTimeString(note.created_at)}</TableCell>
                <TableRowControls>
                    {isNoteCreatedByUser ? (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                return showModal(DeleteNoteDialog, {
                                    note,
                                });
                            }}
                        >
                            <IconTrash />
                        </IconButton>
                    ) : null}
                </TableRowControls>
            </TableRow>
        </>
    );
}

export default Notes;
