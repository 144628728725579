import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { NoteMeta, deleteNoteMutation } from '../../../api/notes';

interface DeleteNoteDialogProps extends DialogProps {
    note: NoteMeta;
    onClose?: () => void;
}

function DeleteNoteDialog({ onClose, note, ...dialogProps }: DeleteNoteDialogProps) {
    const deleteNote = useMutation(deleteNoteMutation(note.id));
    const handleDelete = () => {
        deleteNote.mutateAsync().then(() => {
            onClose?.();
        });
    };
    return (
        <Dialog onClose={onClose} {...dialogProps}>
            <DialogTitle>Delete Note</DialogTitle>
            <DialogContent>
                <Card>Are you sure you want to delete this Note?</Card>
            </DialogContent>

            <DialogActions>
                <Button color="inherit" onClick={onClose}>
                    Close
                </Button>
                <Button variant="contained" color="error" onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteNoteDialog;
