import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Grid, Grow, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import * as z from 'zod';
import { updateWorkspaceMembershipMutation } from '../../../api/workspaceMembership';
import { Card } from '../../../components/Card';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import useUser from '../../../hooks/useUser';
import { characterLimit, noSpecialChars, requiredString } from '../../../validationRules';
import { useWorkspaceMembership } from '../workspaceMembershipContext';

const validationSchema = z.object({
    participant_reference: requiredString.and(characterLimit(16)).and(noSpecialChars),
});

function ClientSpecificContent() {
    const user = useUser();
    const industryCode = user?.institution_organisation?.industry_code;
    const membership = useWorkspaceMembership()!;
    const updateWorkspaceMembership = useMutation(updateWorkspaceMembershipMutation());

    const handleSubmit = (values: any) => {
        updateWorkspaceMembership
            .mutateAsync({
                id: membership?.id,
                participant_reference: values.participant_reference,
            })
            .then(() => {
                enqueueSnackbar('Case reference saved.', { variant: 'success' });
            });
    };

    const isEditable = !!membership?.status && membership.status !== 'invited';

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            participant_reference: membership?.participant_reference,
        },
        resolver: zodResolver(validationSchema),
    });

    const {
        formState: { isDirty },
    } = formMethods;

    return (
        <Stack gap={4}>
            <Card title="Notes from the Professional Representative">
                <Grid container columnSpacing={4} rowSpacing={4} sx={{ width: '50%' }} columns={1}>
                    <ReadonlyField
                        label="Customer identifier"
                        value={membership?.customer_number}
                    />

                    {industryCode === '22' && (
                        <ReadonlyField
                            label="Known holdings"
                            value={
                                membership?.known_holdings &&
                                membership.known_holdings?.length > 0 &&
                                membership.known_holdings.join(', ')
                            }
                        />
                    )}

                    <ReadonlyField label="Comments" value={membership?.comments} />

                    <ReadonlyField
                        label="Letter"
                        value={
                            membership?.letter ? (
                                <DocumentsTable documents={[membership.letter]} />
                            ) : undefined
                        }
                    />
                </Grid>
            </Card>

            <Card title="Internal details">
                <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                    <Stack direction="row" gap={2} alignItems="flex-start">
                        <TextFieldElement
                            id="participant_reference"
                            name="participant_reference"
                            label="Customer / case reference"
                            helperText="Maximum 16 letters, numbers, and symbols."
                            inputProps={{
                                readOnly: !isEditable,
                            }}
                        />
                        <Grow in={isDirty}>
                            <LoadingButton variant="contained" type="submit">
                                Save changes
                            </LoadingButton>
                        </Grow>
                    </Stack>
                </FormContainer>
            </Card>
        </Stack>
    );
}

export default ClientSpecificContent;
