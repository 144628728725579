import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Stack,
} from '@mui/material';
import { appSettings } from '../../appSettings';

interface DisclaimerDialogProps extends DialogProps {
    onClose: () => void;
}

function DisclaimerDialog({ onClose, ...dialogProps }: DisclaimerDialogProps) {
    const { auth0Enabled } = appSettings;
    return (
        <Dialog {...dialogProps} maxWidth="lg">
            <DialogTitle>IMPORTANT NOTICE​ - CONFIDENTIALITY​ & DISTRIBUTION</DialogTitle>
            <DialogContent>
                <Stack gap={3}>
                    <DialogContentText>
                        This demonstration has been prepared by estateXchange ABN 70 668 082 880
                        (eXc) for the exclusive use of the party to whom eXc delivers this
                        demonstration (the Partner). eXc has not independently verified the
                        information contained herein nor does eXc make any representation or
                        warranty, either expressed or implied, as to the accuracy, completeness or
                        reliability of the information contained therein.
                    </DialogContentText>
                    <DialogContentText>
                        This demonstration should not be regarded by the Partner as a substitution
                        for the exercise of its own judgement and the Partner is expected to rely on
                        its own due diligence if it wishes to proceed further.
                    </DialogContentText>
                    <DialogContentText>
                        This demonstration contains forward-looking features and functions which are
                        based on numerous assumptions which may or may not be realised and are
                        subject to change without notice. As a result, nothing contained herein is
                        or shall be relied upon as a promise or representation as to the past or
                        future.
                    </DialogContentText>
                    <DialogContentText>
                        This demonstration has been prepared on a strictly confidential basis solely
                        for the use and benefit of the Partner as a prospective user of eXc’s
                        product or collaboration partner. Distribution of, or discussions regarding
                        this demonstration and any associated presentation to any person other than
                        the Partner and those persons retained to advise the Partner, who agree to
                        maintain the confidentiality of this material and be bound by the
                        limitations outlined herein, is unauthorized. This material must not be
                        copied, reproduced, distributed, passed to others or discussed with other
                        parties at any time, in whole or in part, without the prior written consent
                        of eXc. eXc specifically prohibits the redistribution or reproduction of
                        this material in whole or in part without the written permission of eXc and
                        eXc accepts no liability whatsoever for the actions of third parties in this
                        respect.
                    </DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                {auth0Enabled ? (
                    <Button variant="contained" href="/api/auth/auth0_login/">
                        I Agree
                    </Button>
                ) : (
                    <Button variant="contained" onClick={onClose}>
                        I Agree
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DisclaimerDialog;
