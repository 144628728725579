// Copyright (C) 2024, Affinda

import { Stack, StackProps, Typography } from '@mui/material';
import { Icon } from '@tabler/icons-react';

export interface PlaceholderProps extends Omit<StackProps, 'title'> {
    IconComponent?: Icon;
    title: React.ReactNode;
    description?: React.ReactNode;
}

function Placeholder(props: PlaceholderProps) {
    const { sx, IconComponent, title, description, children, ...stackProps } = props;

    return (
        <Stack
            gap={1}
            alignItems="center"
            sx={{
                m: 'auto',
                textAlign: 'center',
                p: 4,
                width: '50%',
                ...sx,
            }}
            {...stackProps}
        >
            {IconComponent ? <IconComponent size={48} stroke={1} /> : null}

            <Typography component="p" variant="subtitle1">
                {title}
            </Typography>

            {description ? <Typography component="p">{description}</Typography> : null}

            {children}
        </Stack>
    );
}

export default Placeholder;
