import React from 'react';
import { Permission, hasPermission } from '../api/user';
import NotFoundPlaceholder from '../components/NotFoundPlaceholder';
import useUser from '../hooks/useUser';

interface PermissionGuardProps {
    children: React.ReactNode;
    permission: Permission;
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({ children, permission }) => {
    const user = useUser();

    if (!hasPermission(permission, user)) {
        return <NotFoundPlaceholder />;
    }

    return <>{children}</>;
};

export default PermissionGuard;
