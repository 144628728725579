import { Box, Link, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { userConsentQuery } from '../../api/userConsent';
import EXCLogo from '../../components/EXCLogo';
import HelpMenu from '../../components/HelpMenu';
import useUser from '../../hooks/useUser';
import ConsentForm from './ConsentForm';
import { UserMenu } from './UserMenu';

function MainLayout() {
    const user = useUser();

    const organisationName =
        user?.de_rep_organisation?.internal_display_name ||
        user?.de_rep_organisation?.name ||
        user?.institution_organisation?.internal_display_name ||
        user?.institution_organisation?.name;

    const consentDetailQuery = useQuery(userConsentQuery());
    const showConsentForm = user?.consent_pending && consentDetailQuery.data;

    if (showConsentForm) {
        return <ConsentForm />;
    }

    return (
        <Stack
            sx={{
                flexGrow: 1,
                minWidth: '0',
                minHeight: 0,
                height: '100vh',
                backgroundColor: 'background.default',

                '@supports (height: 100svh)': { height: '100svh' },
            }}
        >
            <Stack
                direction="row"
                component="header"
                sx={{
                    bgcolor: 'AppBar.defaultBg',
                    gap: 1,
                    alignItems: 'center',
                    position: 'sticky',
                    top: 0,
                    zIndex: 'appBar',
                    py: 1,
                    px: 3,
                }}
            >
                <Link component={RouterLink} to="/" sx={{ color: 'inherit', display: 'flex' }}>
                    <EXCLogo mode="dark" style={{ width: 'auto', height: '14px' }} />
                </Link>

                <Box sx={{ flexGrow: 1 }} />

                <Typography sx={{ color: 'white', pr: 0.5 }}>{organisationName}</Typography>

                <HelpMenu />

                <UserMenu />
            </Stack>

            <Stack sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Outlet />
            </Stack>
        </Stack>
    );
}

export default MainLayout;
