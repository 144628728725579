import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type RequestMeta = components['schemas']['Request'];
export type CreateRequestMeta = components['schemas']['RequestRequest'];
export type RequestParams = operations['requests_list']['parameters']['query'];
export type PaginatedRequestList = components['schemas']['PaginatedRequestList'];

// gets all workspaces for the logged in user
export function requestsQuery(options?: RequestParams) {
    return {
        queryKey: ['requests', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedRequestList>({
                url: `/requests`,
                params: options,
                method: 'GET',
                paramsSerializer: () => {
                    try {
                        var p = new URLSearchParams();

                        // Ensure the array gets serialized to multiple query params
                        options?.status?.forEach((status) => p.append('status', status));

                        if (options) {
                            Object.keys(options).forEach((key) => {
                                if (key !== 'status') {
                                    const value = options[key as keyof RequestParams];
                                    if (value !== undefined) {
                                        p.append(key, value);
                                    }
                                }
                            });
                        }

                        return p.toString();
                    } catch (error) {
                        throw new Error(`Failed to serialize params: ${error}`);
                    }
                },
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedRequestList>;
}

export function createRequestMutation() {
    return {
        mutationFn: (data: any) =>
            api<RequestMeta>({
                url: `/requests`,
                method: 'POST',
                data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['requests'] });
        },
    } satisfies UseMutationOptions<RequestMeta, any, CreateRequestMeta, any>;
}

type PatchedRequestRequest = components['schemas']['PatchedRequestRequest'];

export function updateRequestMutation(requestId: string) {
    return {
        mutationFn: (data: PatchedRequestRequest) =>
            api<RequestMeta>({
                url: `/requests/${requestId}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['requests'] });
        },
    } satisfies UseMutationOptions<RequestMeta, any, PatchedRequestRequest, any>;
}

export function deleteRequestMutation(requestId: string) {
    return {
        mutationFn: () =>
            api<RequestMeta>({
                url: `/requests/${requestId}`,
                method: 'DELETE',
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['requests'] });
        },
    } satisfies UseMutationOptions<RequestMeta, any, CreateRequestMeta, any>;
}
