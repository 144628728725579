import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { RelatedPersonMeta } from '../../api/relatedPerson';
import Placeholder from '../Placeholder';
import RelatedPersonRow, { RelatedPersonRowProps } from './RelatedPersonRow';

interface RelatedPeopleTableProps {
    relatedPeople: RelatedPersonMeta[];
    rowProps: Omit<RelatedPersonRowProps, 'person'>;
    placeholder?: React.ReactNode;
}

export const RelatedPeopleTable: FC<RelatedPeopleTableProps> = ({
    relatedPeople,
    rowProps,
    placeholder,
}) => {
    if (!relatedPeople.length) {
        return placeholder ?? <Placeholder title="No Associated People have been added" />;
    }

    return (
        <TableContainer sx={{ overflowY: 'hidden' }}>
            <Table
                sx={{
                    whiteSpace: 'nowrap',
                }}
            >
                <TableHead>
                    <TableRow>
                        {rowProps.onToggleSubscription ? (
                            <TableCell align="center" sx={{ pl: 0 }}>
                                Select
                            </TableCell>
                        ) : null}
                        <TableCell>Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell align="center">Identity Documents</TableCell>
                        <TableCell align="center">Electronic Verification</TableCell>
                        <TableCell sx={{ width: 50, maxWidth: 50 }} />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {relatedPeople.map((person) => (
                        <RelatedPersonRow key={person.id} person={person} {...rowProps} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RelatedPeopleTable;
