import { FormLabel, Stack } from '@mui/material';
import { RadioButtonGroup, RadioButtonGroupProps } from 'react-hook-form-mui';
import InfoTooltip from '../InfoTooltip';

interface RadioButtonGroupFieldprops extends RadioButtonGroupProps {
    infoTooltip?: string;
}

function RadioButtonGroupField({
    infoTooltip,
    label,
    ...radioButtonGroupProps
}: RadioButtonGroupFieldprops) {
    return (
        <Stack sx={{ minWidth: 300 }}>
            <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                {label}
                {infoTooltip ? <InfoTooltip title={infoTooltip} sx={{ ml: 1 }} /> : null}
            </FormLabel>
            <RadioButtonGroup {...radioButtonGroupProps} />
        </Stack>
    );
}

export default RadioButtonGroupField;
