import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { operations } from '../schema';
import { api } from '../utils';

type request = operations['standalone_tools_gor_retrieval_retrieve']['parameters']['query'];

export function retrieveGorMutation() {
    return {
        mutationFn: (data: request) =>
            api<Blob>({
                url: `/standalone_tools/gor_retrieval`,
                method: 'GET',
                params: data,
                responseType: 'blob',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<Blob, AxiosError<any>, request, any>;
}
