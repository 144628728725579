import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

export type ProofOfIdentityTask = components['schemas']['ProofOfIdentityTask'];
export type VerificationOfIdentityDocument = components['schemas']['VoiDocument'];

export function proofOfIdentityTaskQuery(id: string) {
    return {
        queryKey: ['proofOfIdentityTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<ProofOfIdentityTask>({
                url: `/tasks/proof_of_identity/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function updateProofOfIdentityTask(id: string) {
    return {
        mutationFn: (data: Partial<ProofOfIdentityTask>) =>
            api<ProofOfIdentityTask>({
                url: `/tasks/proof_of_identity/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                proofOfIdentityTaskQuery(id).queryKey,
                (oldData: ProofOfIdentityTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(proofOfIdentityTaskQuery(id).queryKey, context.previousData);
        },
    } satisfies UseMutationOptions<ProofOfIdentityTask, any, Partial<ProofOfIdentityTask>, any>;
}
