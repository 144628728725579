import { Chip, ChipProps, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { EVoiStatusEnum } from '../../api/eVoiTransaction';
import useUser from '../../hooks/useUser';
import InfoTooltip from '../InfoTooltip';

interface VoiStatusChipProps extends ChipProps {
    status?: EVoiStatusEnum;
    requested?: boolean;
    showInfoTooltip?: boolean;
    infoIcon?: ReactNode;
}

function VoiStatusChip({
    status,
    requested,
    showInfoTooltip,
    infoIcon,
    ...chipProps
}: VoiStatusChipProps) {
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    const getChipConfig = () => {
        switch (status) {
            case 'pending':
            case 'in_progress':
            case 'submitted':
                return {
                    label: 'PENDING',
                    color: 'warning' as const,
                    description:
                        'A Verification of Identity prompt has been forwarded to this Associated Person. Status will show as Pending until they have completed the verification.',
                };
            case 'completed':
                return {
                    label: 'COMPLETED',
                    color: 'success' as const,
                };
            case 'expired':
                return {
                    label: 'EXPIRED',
                    color: 'error' as const,
                };
            case 'cancelled':
                return {
                    label: 'CANCELLED',
                    color: 'default' as const,
                };
            default:
                if (requested) {
                    return {
                        label: 'REQUESTED',
                        color: isWorkspaceOwner ? ('secondary' as const) : ('warning' as const),
                        description: isWorkspaceOwner
                            ? 'Verification of Identity was requested by a Participant. Please initiate Electronic Verification of Identity.'
                            : 'Electronic Verification of Identity has been requested. This is currently with the Professional Representative to initiate.',
                    };
                }
                return {
                    label: 'NOT REQUESTED',
                    color: 'default' as const,
                };
        }
    };
    const { label, color, description } = getChipConfig();

    return (
        <Stack direction="row" alignItems="center" gap={0.5}>
            <Chip label={label} color={color} {...chipProps} />
            {showInfoTooltip && description && <InfoTooltip title={description} icon={infoIcon} />}
        </Stack>
    );
}

export default VoiStatusChip;
