import { Box, BoxProps, Tooltip } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';

interface InfoTooltipProps extends Omit<BoxProps, 'title'> {
    title: ReactNode;
    size?: number;
    icon?: ReactNode;
}

function InfoTooltip({
    title,
    size,
    icon = <IconInfoCircle size={20} />,
    ...boxProps
}: InfoTooltipProps) {
    return (
        <Tooltip title={title}>
            <Box
                flexShrink={0}
                color="text.secondary"
                alignSelf="-moz-initial"
                {...boxProps}
                sx={{
                    strokeWidth: '1.7px',
                    display: 'flex',
                    alignItems: 'center',
                    ...(size && { width: size, height: size }),
                    ...boxProps.sx,
                }}
            >
                {icon}
            </Box>
        </Tooltip>
    );
}

export default InfoTooltip;
