import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { format, isValid, parseISO } from 'date-fns';

// The number of items to request per page
export const perPage = 100;

let apiBaseUrl: string = window.location.origin + '/api/';
let signature: string;
let csrfToken: string;

// Create a new axios instance
const axiosInstance = axios.create({ baseURL: apiBaseUrl });

// Create a wrapper around axios that adds the CSRF token to every request
export function api<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // if POST or PATCH or DELETE, update the CSRF token
    if (config.method === 'POST' || config.method === 'PATCH' || config.method === 'DELETE') {
        updateCsrfToken();
    }
    return axiosInstance.request(config).finally(updateCsrfToken);
}

function updateCsrfToken() {
    const token = getCookie('csrftoken');
    if (token) {
        axiosInstance.defaults.headers.common['X-CSRFTOKEN'] = token;
        csrfToken = token;
    }
}

function getCookie(name: string) {
    let cookieValue = null;

    if (document.cookie) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }

    return cookieValue;
}

// Add a request interceptor to add the signature to every request
axiosInstance.interceptors.request.use(function (config) {
    if (config.url) {
        config.url = appendSignature(config.url);
    }
    return config;
});

function appendSignature(url: string) {
    if (signature) {
        url += (url.includes('?') ? '&' : '?') + 'signature=' + encodeURIComponent(signature);
    }
    return url;
}

// Add a response interceptor to add the response data to the error message
axiosInstance.interceptors.response.use(undefined, function (error) {
    error.message += error.response?.data ? ' ' + JSON.stringify(error.response.data) : '';
    return Promise.reject(error);
});

// optional string
export function snakeCaseToTitleCase(str: string | null | undefined) {
    return str?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char.toUpperCase());
}

// Convert a date to a string in the format expected by the API
export function dateString(date?: Date | string | null): string | undefined {
    if (date === null || date === undefined) return undefined;

    date = typeof date === 'string' ? parseISO(date) : date;
    if (!isValid(date)) {
        return undefined;
    }

    return format(date, 'yyyy-MM-dd');
}
