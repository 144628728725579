import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type StatementOfPositionTask = components['schemas']['StatementOfPositionTask'];

export function statementOfPositionTaskQuery(id: string) {
    return {
        queryKey: ['statementOfPositionTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<StatementOfPositionTask>({
                url: `/tasks/statement_of_position/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

type listStatementOfPositionTasksRequest =
    operations['tasks_statement_of_position_list']['parameters']['query'];
type listStatementOfPositionTasksResponse =
    components['schemas']['PaginatedStatementOfPositionTaskMetaList'];

export function listSatementOfPositionTasksQuery(options: listStatementOfPositionTasksRequest) {
    return {
        queryKey: ['statementOfPositionTaskList', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<listStatementOfPositionTasksResponse>({
                url: `/tasks/statement_of_position`,
                method: 'GET',
                params: options,
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<listStatementOfPositionTasksResponse>;
}

export function updateStatementOfPositionTask(id: string) {
    return {
        mutationFn: (data) =>
            api<StatementOfPositionTask>({
                url: `/tasks/statement_of_position/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                statementOfPositionTaskQuery(id).queryKey,
                (oldData: StatementOfPositionTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(
                statementOfPositionTaskQuery(id).queryKey,
                context.previousData
            );
        },
    } satisfies UseMutationOptions<
        StatementOfPositionTask,
        any,
        Partial<StatementOfPositionTask>,
        any
    >;
}
