import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Chip, Stack } from '@mui/material';
import { IconArrowBackUp, IconCheck } from '@tabler/icons-react';

interface CompleteTaskButtonProps extends LoadingButtonProps {
    completed: boolean;
    label?: string;
    completedLabel?: string;
    onComplete?: () => void;
    onUndo?: () => void;
}

function CompleteTaskButton({
    completed,
    label = 'Mark Complete',
    completedLabel = 'Completed',
    onComplete,
    onUndo,
    ...loadingButtonProps
}: CompleteTaskButtonProps) {
    if (completed)
        return (
            <Stack direction="row" gap={1} alignItems="center">
                <Chip
                    icon={<Box component={IconCheck} size={16} sx={{ strokeWidth: '3px' }} />}
                    label={completedLabel || 'Completed'}
                    color="success"
                    variant="filled"
                    sx={{ pl: 0.5 }}
                />
                <LoadingButton
                    color="inherit"
                    startIcon={<IconArrowBackUp />}
                    onClick={onUndo}
                    {...loadingButtonProps}
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    Undo
                </LoadingButton>
            </Stack>
        );

    return (
        <LoadingButton
            variant="contained"
            color="primary"
            startIcon={<IconCheck />}
            onClick={onComplete}
            {...loadingButtonProps}
            sx={{
                whiteSpace: 'nowrap',
            }}
        >
            {label}
        </LoadingButton>
    );
}

export default CompleteTaskButton;
