import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { DatePickerElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { dateString } from '../../api/utils';
import { WorkspacesCreate400, createWorkspaceMutation } from '../../api/workspace';
import FormGrid from '../../components/FormGrid';
import InfoTooltip from '../../components/InfoTooltip';
import {
    characterLimit,
    noNumbers,
    noSpecialChars,
    optionalString,
    requiredDate,
    requiredString,
} from '../../validationRules';

interface CreateWorkspaceDialogProps extends DialogProps {
    onClose: () => void;
}

const validationSchema = z
    .object({
        reference: optionalString.and(characterLimit(16)).and(noSpecialChars),
        family_name: requiredString.and(characterLimit(40)).and(noSpecialChars).and(noNumbers),
        given_names: requiredString.and(characterLimit(70)).and(noSpecialChars).and(noNumbers),
        date_of_birth: requiredDate,
        date_of_death: requiredDate,
    })
    .refine((fields) => fields.date_of_birth < fields.date_of_death, {
        message: 'Date of death must be after date of birth',
        path: ['date_of_death'],
    });

function CreateWorkspaceDialog({ onClose, ...dialogProps }: CreateWorkspaceDialogProps) {
    const createWorkspace = useMutation(createWorkspaceMutation());
    const navigate = useNavigate();
    const [duplicateWorkspace, setDuplicateWorkspace] = useState<{
        externalId: string;
        id: string;
    } | null>(null);
    const handleSubmit = () => {
        const values = formMethods.getValues();
        createWorkspace.mutateAsync(
            {
                case_reference: values.reference,
                deceased_family_name: values.family_name,
                deceased_given_names: values.given_names,
                deceased_date_of_birth: dateString(values.date_of_birth) || '',
                deceased_date_of_death: dateString(values.date_of_death) || '',
                bypass_duplicate_warning: duplicateWorkspace ? true : false,
            },
            {
                onError: (error: any) => {
                    if (error.response?.status === 400) {
                        const duplicateWorkspaceError = (
                            error.response.data as WorkspacesCreate400
                        ).errors?.find((e) => e.code.startsWith('duplicate_workspace'));

                        console.log(duplicateWorkspaceError);

                        if (duplicateWorkspaceError) {
                            error.preventAlert?.();
                            console.log(duplicateWorkspaceError.code);
                            setDuplicateWorkspace({
                                externalId: duplicateWorkspaceError.code.split('|')[1],
                                id: duplicateWorkspaceError.code.split('|')[2],
                            });
                        }
                    }
                },
                onSuccess: (workspace) => {
                    onClose();
                    navigate(`/workspace/${workspace.id}/deceased-information`);
                },
            }
        );
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            reference: '',
            family_name: '',
            given_names: '',
            date_of_birth: undefined,
            date_of_death: undefined,
        },
        resolver: zodResolver(validationSchema),
    });

    return (
        <Dialog
            onClose={onClose}
            maxWidth="md"
            fullWidth
            scroll="body"
            {...dialogProps}
            sx={{ overflow: 'auto', ...dialogProps.sx }}
        >
            <Stack direction="row" alignItems="baseline">
                <DialogTitle>Create a new Workspace</DialogTitle>
                <InfoTooltip
                    title="A Workspace is a secure area where you can upload and share documents
                    relating to a deceased person."
                    sx={{ ml: -2 }}
                />
            </Stack>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <Stack sx={{ paddingBottom: 3 }} gap={2}>
                        <Typography variant="subtitle2" sx={{ pl: 0.4 }}>
                            Deceased details
                        </Typography>
                        <Alert severity="info" sx={{ alignItems: 'center' }}>
                            Ensure name detail matches any Death Certificate held, including
                            multiple given names.
                        </Alert>
                    </Stack>
                    <FormGrid sx={{ paddingBottom: 3 }}>
                        <Grid item xs={6}>
                            <TextFieldElement
                                id="family_name"
                                name="family_name"
                                label="Family name"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                id="given_names"
                                name="given_names"
                                label="Given name(s)"
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DatePickerElement
                                name="date_of_birth"
                                label="Date of birth"
                                maxDate={new Date()}
                                sx={{ width: '100%' }}
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DatePickerElement
                                name="date_of_death"
                                label="Date of death"
                                maxDate={new Date()}
                                sx={{ width: '100%' }}
                                required
                            />
                        </Grid>
                    </FormGrid>
                    <Typography variant="subtitle2" sx={{ paddingBottom: 2, pl: 0.4 }}>
                        Workspace details
                    </Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextFieldElement
                                id="reference"
                                name="reference"
                                label="Your internal reference"
                                helperText="Maximum 16 letters, numbers, and symbols."
                                fullWidth
                                sx={{ pr: 2.25 }}
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={duplicateWorkspace !== null}>
                        <Alert severity="warning" sx={{ mt: 2, mb: -2 }}>
                            Potential duplicate with Workspace{' '}
                            <Link
                                component={RouterLink}
                                to={`/workspace/${duplicateWorkspace?.id}`} // TODO get workspace UUID
                                target="_blank"
                            >
                                eXc {duplicateWorkspace?.externalId}
                            </Link>
                            . If unexpected or inaccessible, please review our{' '}
                            <Link
                                component={RouterLink}
                                to="https://customer-hub.estatexchange.com.au/"
                                target="_blank"
                            >
                                Support
                            </Link>{' '}
                            page for next steps.
                        </Alert>
                    </Collapse>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={createWorkspace.status === 'pending'}
                    >
                        Create {duplicateWorkspace ? 'anyway' : 'Workspace'}
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default CreateWorkspaceDialog;
