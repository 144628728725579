import { FormLabel, Grid, GridProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ReadonlyFieldProps extends GridProps {
    label: ReactNode;
    value?: ReactNode;
    // Allows override in the case of no value. This is default set as "Not provided"
    // Implemented as part of https://app.clickup.com/t/86cvgyvwy
    notProvided?: string;
}

export function ReadonlyField({
    label,
    value,
    notProvided = 'Not provided',
    ...gridProps
}: ReadonlyFieldProps) {
    return (
        <Grid item xs={6} {...gridProps}>
            <Stack gap={0.75}>
                <FormLabel>{label}</FormLabel>
                <Typography variant="body2">{value ? value : notProvided}</Typography>
            </Stack>
        </Grid>
    );
}

export default ReadonlyField;
