import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

export type ProofOfDeathTask = components['schemas']['ProofOfDeathTask'];

export function proofOfDeathTaskQuery(id: string) {
    return {
        queryKey: ['proofOfDeathTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<ProofOfDeathTask>({
                url: `/tasks/proof_of_death/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function updateProofOfDeathTask(id: string) {
    return {
        mutationFn: (data) =>
            api<ProofOfDeathTask>({
                url: `/tasks/proof_of_death/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                proofOfDeathTaskQuery(id).queryKey,
                (oldData: ProofOfDeathTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(proofOfDeathTaskQuery(id).queryKey, context.previousData);
        },
    } satisfies UseMutationOptions<ProofOfDeathTask, any, Partial<ProofOfDeathTask>, any>;
}
