import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import { IconShare2 } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { closeWorkspaceMutation } from '../api/workspace';
import { updateWorkspaceMembershipMutation } from '../api/workspaceMembership';
import useUser from '../hooks/useUser';
import { useWorkspace } from '../pages/workspace/workspaceContext';
import ExportDocumentsDialog from './ExportDocumentsDialog';

interface CloseWorkspaceDialogProps extends DialogProps {
    onClose?: () => void;
}

function CloseWorkspaceDialog({ onClose, ...dialogProps }: CloseWorkspaceDialogProps) {
    const navigate = useNavigate();
    const { showModal } = useModal();
    const user = useUser();
    const workspace = useWorkspace();
    const membershipId = workspace.memberships[0];

    const closeMutation = useMutation(closeWorkspaceMutation(workspace.id));
    const exitMutation = useMutation(updateWorkspaceMembershipMutation());

    const handleCloseWorkspace = async () => {
        if (user?.de_rep_organisation) {
            await closeMutation.mutateAsync();
        } else {
            await exitMutation.mutateAsync({ id: membershipId, status: 'closed' });
        }

        onClose?.();
        navigate('/');
        enqueueSnackbar('Workspace closed.', { variant: 'success' });
    };

    return (
        <Dialog maxWidth="sm" fullWidth onClose={onClose} {...dialogProps}>
            <DialogTitle>Are you sure you want to finalise this Workspace?</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Once you finalise this Workspace, it will no longer be accessible. Make sure you
                    have captured all the required data and downloaded copies of all relevant
                    documents.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onClose?.();
                        showModal(ExportDocumentsDialog);
                    }}
                    variant="outlined"
                    color="inherit"
                    startIcon={<IconShare2 />}
                >
                    Export documents
                </Button>
                <Button onClick={handleCloseWorkspace} variant="contained" color="error">
                    Leave Workspace
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CloseWorkspaceDialog;
