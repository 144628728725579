import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api } from './utils';
import { workspaceMembershipQuery } from './workspaceMembership';

export type EstateFinalisationTask = components['schemas']['EstateFinalisationTask'];

export function estateFinalisationTaskQuery(id: string) {
    return {
        queryKey: ['estateFinalisationTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<EstateFinalisationTask>({
                url: `/tasks/estate_finalisation/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

type listEstateFinalisationTasksRequest =
    operations['tasks_estate_finalisation_list']['parameters']['query'];
type listEstateFinalisationTasksResponse =
    components['schemas']['PaginatedEstateFinalisationTaskMetaList'];

export function listEstateFinalisationTasksQuery(options: listEstateFinalisationTasksRequest) {
    return {
        queryKey: ['estateFinalisationTaskList', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<listEstateFinalisationTasksResponse>({
                url: '/tasks/estate_finalisation',
                method: 'GET',
                params: options,
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * 10 : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<listEstateFinalisationTasksResponse>;
}

export function updateEstateFinalisationTask(id: string) {
    return {
        mutationFn: (data) =>
            api<EstateFinalisationTask>({
                url: `/tasks/estate_finalisation/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                estateFinalisationTaskQuery(id).queryKey,
                (oldData: EstateFinalisationTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(workspaceMembershipQuery(id).queryKey, context.previousData);
        },
    } satisfies UseMutationOptions<
        EstateFinalisationTask,
        any,
        Partial<EstateFinalisationTask>,
        any
    >;
}
