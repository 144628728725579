import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Collapse,
    FormHelperText,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { IconArrowRight } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import {
    DatePickerElement,
    FormContainer,
    SelectElement,
    TextFieldElement,
    useForm,
    useFormContext,
} from 'react-hook-form-mui';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { deleteDeathCertificateMutation } from '../../../api/deathCertificate';
import { deceasedDetailQuery, updateDeceasedDetail } from '../../../api/deceasedDetail';
import { deleteGenericDocumentMutation } from '../../../api/genericDocument';
import { queryClient } from '../../../api/queryClient';
import { dateString } from '../../../api/utils';
import { workspaceQuery } from '../../../api/workspace';
import { Card } from '../../../components/Card';
import FormGrid from '../../../components/FormGrid';
import QueryProgress from '../../../components/QueryProgress';
import UploadGenericDocumentDialog from '../../../components/UploadGenericDocumentDialog';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import { DocumentTableRowType } from '../../../components/documentsTable/DocumentsTableCells';
import CountryPicker from '../../../components/fields/CountryAutocompleteField';
import RadioButtonGroupField from '../../../components/fields/RadioButtonGroupField';
import { nullableBooleanToYesNo } from '../../../utils';
import {
    characterLimit,
    noSpecialChars,
    optionalString,
    requiredDate,
    requiredString,
} from '../../../validationRules';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import DeathCertificateUploadDialog from './UploadDeathCertificateDialog';

function ProofOfDeath() {
    return (
        <PageLayout title="Proof of Death">
            <ProofOfDeathContent />
        </PageLayout>
    );
}

export function ProofOfDeathContent() {
    const workspace = useWorkspace();
    const navigate = useNavigate();
    const { showModal } = useModal();

    const query = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const details = query.data;

    const updateMutation = useMutation(updateDeceasedDetail());

    const onSubmit = (values: any) => {
        updateMutation.mutate(
            {
                id: details?.id,
                date_of_death: dateString(values.date_of_death),
                place_of_death: values.place_of_death,
                place_of_death_overseas: values.place_of_death_country,
                has_aus_death_cert:
                    values.has_aus_death_cert === 'yes' || !!workspace.death_certificate,
                no_death_cert_reason: values.no_death_cert_reason,
                has_other_pod:
                    values.other_proof_of_death_held === 'yes' || !!workspace.other_pod_docs.length,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Changes saved.', { variant: 'success' });
                    navigate(`/workspace/${workspace.id}/poa-data-entry`);
                },
            }
        );
    };

    const validationSchema = z
        .object({
            date_of_death: requiredDate,
            place_of_death: requiredString,
            place_of_death_country: optionalString,
            has_aus_death_cert: optionalString,
            no_death_cert_reason: characterLimit(300).and(optionalString).and(noSpecialChars),
            other_proof_of_death_held: optionalString,
            // Put a dummy values here so that we can attach errors that are not related to the fields
            death_cert: optionalString,
            other_proof_of_death: optionalString,
        })
        .refine(
            // Date of death must not be before the date of birth
            (fields) => fields.date_of_death >= parseISO(details?.date_of_birth!),
            {
                message: 'Date of death must not be before the date of birth',
                path: ['date_of_death'],
            }
        )
        .refine(
            // Australian death cert held is required if the location of death is not overseas of if there is not already will
            (fields) =>
                fields.place_of_death === 'overseas' ||
                !!fields.has_aus_death_cert ||
                workspace.will,
            {
                message: 'Response required',
                path: ['has_aus_death_cert'],
            }
        )
        .refine(
            // Other proof of death held is required if the location of death is not overseas
            (fields) => fields.place_of_death === 'overseas' || !!fields.other_proof_of_death_held,
            {
                message: 'Response required',
                path: ['other_proof_of_death_held'],
            }
        )
        .refine(
            // Country is required if location of death is overseas
            (fields) => fields.place_of_death !== 'overseas' || !!fields.place_of_death_country,
            {
                message: 'This field is required',
                path: ['place_of_death_country'],
            }
        )
        .refine(
            // A reason is required if there is no death certificate, so long as the location of death is not overseas
            (fields) =>
                fields.has_aus_death_cert === 'yes' ||
                fields.place_of_death === 'overseas' ||
                !!fields.no_death_cert_reason ||
                !!workspace.death_certificate,
            {
                message: 'This field is required',
                path: ['no_death_cert_reason'],
            }
        )
        .refine(
            // Death certificate must be uploaded if it is held
            (fields) => fields.has_aus_death_cert !== 'yes' || !!workspace.death_certificate,
            {
                message: 'Document upload required',
                path: ['death_cert'],
            }
        )
        .refine(
            // Other proof of death must be uploaded if it is held, or if location of death is overseas
            (fields) =>
                (fields.other_proof_of_death_held !== 'yes' &&
                    fields.place_of_death !== 'overseas') ||
                !!workspace.other_pod_docs.length,
            {
                message: 'Document upload required',
                path: ['other_proof_of_death'],
            }
        )
        .refine(
            // if no death cert is held, then other proof of death held must be yes
            (fields) => {
                return (
                    fields.has_aus_death_cert === 'yes' ||
                    (fields.place_of_death === 'overseas' && !!workspace.other_pod_docs.length) ||
                    (!!workspace.other_pod_docs.length &&
                        fields.other_proof_of_death_held === 'yes')
                );
            },
            {
                message: 'If no Death Certificate is held, please provide other Proof of Death',
                path: ['other_proof_of_death_held'],
            }
        );

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            date_of_death: details?.date_of_death && parseISO(details?.date_of_death),
            place_of_death: details?.place_of_death || '',
            place_of_death_country: details?.place_of_death_overseas || '',
            has_aus_death_cert: nullableBooleanToYesNo(details?.has_aus_death_cert),
            no_death_cert_reason: details?.no_death_cert_reason || '',
            other_proof_of_death_held: nullableBooleanToYesNo(details?.has_other_pod),
            death_cert: '',
            other_proof_of_death: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const watchLocationOfDeath = formMethods.watch('place_of_death');
    const watchDeathCertHeld = formMethods.watch('has_aus_death_cert');
    const watchOtherProofHeld = formMethods.watch('other_proof_of_death_held');

    useUpdateEffect(() => {
        if (watchLocationOfDeath === 'overseas') {
            formMethods.setValue('has_aus_death_cert', 'no');
            formMethods.setValue('other_proof_of_death_held', 'yes');
        } else {
            // Reset fields when location changes from overseas to an Australian state
            if (formMethods.getValues('place_of_death') !== 'overseas') {
                formMethods.setValue('has_aus_death_cert', '');
                formMethods.setValue('other_proof_of_death_held', '');
                formMethods.setValue('place_of_death_country', '');
            }
        }
        // clear form errors
        formMethods.clearErrors();
    }, [watchLocationOfDeath]);

    const { errors } = formMethods.formState;

    return (
        <QueryProgress query={query}>
            <FormContainer formContext={formMethods} onSuccess={onSubmit}>
                <Stack gap={4}>
                    <FormGrid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                name="date_of_death"
                                label="Date of death"
                                required
                                maxDate={new Date()}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                            <SelectElement
                                name="place_of_death"
                                label="Location of death"
                                required
                                fullWidth
                                options={[
                                    { id: 1, label: 'ACT', value: 'ACT' },
                                    { id: 2, label: 'NSW', value: 'NSW' },
                                    { id: 3, label: 'NT', value: 'NT' },
                                    { id: 4, label: 'QLD', value: 'QLD' },
                                    { id: 5, label: 'SA', value: 'SA' },
                                    { id: 6, label: 'TAS', value: 'TAS' },
                                    { id: 7, label: 'VIC', value: 'VIC' },
                                    { id: 8, label: 'WA', value: 'WA' },
                                    {
                                        id: 9,
                                        label: 'Outside of Australia',
                                        value: 'overseas',
                                    },
                                ]}
                                valueKey="value"
                            />
                        </Grid>

                        {watchLocationOfDeath === 'overseas' ? (
                            <Grid item xs={6}>
                                <CountryPicker
                                    name="place_of_death_country"
                                    label="Country of Death"
                                    required
                                />
                            </Grid>
                        ) : null}
                    </FormGrid>

                    <Card title="Death Certificate">
                        {workspace.death_certificate ? (
                            <DeathCertificateTable />
                        ) : (
                            <>
                                <RadioButtonGroupField
                                    name="has_aus_death_cert"
                                    required
                                    label="Has an Australian Death Certificate been issued?"
                                    disabled={watchLocationOfDeath === 'overseas'}
                                    options={[
                                        { id: 1, label: 'Yes', value: 'yes' },
                                        { id: 2, label: 'No', value: 'no' },
                                    ]}
                                    valueKey="value"
                                />

                                {watchDeathCertHeld === 'yes' && (
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            onClick={() => showModal(DeathCertificateUploadDialog)}
                                        >
                                            Upload Death Certificate
                                        </Button>
                                        {errors.death_cert && (
                                            <FormHelperText error>
                                                {errors.death_cert.message}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                )}

                                {watchDeathCertHeld === 'no' &&
                                    watchLocationOfDeath !== 'overseas' && (
                                        <TextFieldElement
                                            name="no_death_cert_reason"
                                            label="Reason for no Death Certificate"
                                            multiline
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            required
                                        />
                                    )}
                            </>
                        )}
                    </Card>

                    <Card title="Other Proof of Death">
                        <RadioButtonGroupField
                            name="other_proof_of_death_held"
                            label="Is other Proof of Death held?"
                            disabled={watchLocationOfDeath === 'overseas'}
                            required
                            options={[
                                { label: 'Yes', id: 1, value: 'yes' },
                                { label: 'No', id: 2, value: 'no' },
                            ]}
                            valueKey="value"
                            infoTooltip="Include here any other independent documents which may be considered a Proof of Death."
                        />
                        {(watchOtherProofHeld === 'yes' || watchLocationOfDeath === 'overseas') && (
                            <OtherProofOfDeathUploadArea />
                        )}
                    </Card>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{
                            bottom: 0,
                            zIndex: 3,
                        }}
                    >
                        {errors.root?.message && (
                            <Typography color="error">{errors.root.message}</Typography>
                        )}
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            size="large"
                            startIcon={<IconArrowRight />}
                        >
                            Next step
                        </LoadingButton>
                    </Stack>
                </Stack>
            </FormContainer>
        </QueryProgress>
    );
}

function DeathCertificateTable() {
    const workspace = useWorkspace();

    const deleteMutation = useMutation(deleteDeathCertificateMutation());

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    if (!workspace.death_certificate) return null;

    return (
        <Grid item xs={12}>
            <DocumentsTable documents={[workspace.death_certificate]} onDelete={handleDelete} />

            <Collapse in={!!workspace.workspace_with_duplicate_death_cert}>
                <Alert severity="warning" sx={{ mt: 2 }}>
                    Death Certificate duplicated from Workspace{' '}
                    <Link
                        component={RouterLink}
                        to={`/workspace/${workspace?.workspace_with_duplicate_death_cert?.external_id}`} // TODO get workspace UUID
                        target="_blank"
                        color="inherit"
                    >
                        eXc {workspace.workspace_with_duplicate_death_cert?.external_id}
                    </Link>
                    . If unexpected or inaccessible, please review our{' '}
                    <Link
                        component={RouterLink}
                        to="https://customer-hub.estatexchange.com.au/"
                        target="_blank"
                        color="inherit"
                    >
                        Support
                    </Link>{' '}
                    page for next steps.
                </Alert>
            </Collapse>
        </Grid>
    );
}

function OtherProofOfDeathUploadArea() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    const {
        trigger,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        trigger('other_proof_of_death_held');
    }, [workspace.other_pod_docs.length]);

    return (
        <Box>
            <Button
                variant="outlined"
                color="inherit"
                onClick={() =>
                    showModal(UploadGenericDocumentDialog, {
                        title: 'Upload Proof of Death',
                        taskType: 'proof_of_death',
                    })
                }
            >
                Upload other Proof of Death
            </Button>
            {errors.other_proof_of_death && (
                <FormHelperText error>{`${errors.other_proof_of_death.message}`}</FormHelperText>
            )}
            <Stack gap={1} sx={{ pt: 4 }}>
                {workspace.other_pod_docs.length > 0 ? (
                    <DocumentsTable
                        documents={[...workspace.other_pod_docs]}
                        onDelete={handleDelete}
                        extraColumns={[
                            {
                                id: 'type',
                                label: 'Type',
                                Component: DocumentTableRowType,
                            },
                        ]}
                    />
                ) : null}
            </Stack>
        </Box>
    );
}

export default ProofOfDeath;
