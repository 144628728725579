// Copyright (C) 2024, Affinda

import { Button, ButtonProps } from '@mui/material';

export interface UploadButtonProps extends ButtonProps {
    onUpload?: (files: File) => void;
    multiple?: boolean;
}

export default function UploadButton(props: UploadButtonProps) {
    const { onUpload, multiple, ...buttonProps } = props;

    const handleChooseFiles = () => {
        const input = document.createElement('input');

        input.type = 'file';
        input.multiple = !!multiple;
        input.hidden = true;

        document.body.appendChild(input);

        input.addEventListener('change', () => {
            for (let i = 0; i < input.files!.length; i++) {
                onUpload?.(input.files![i]);
            }
            input.remove();
        });

        input.click();
    };

    return <Button {...buttonProps} onClick={handleChooseFiles} />;
}
