import { SelectElement, SelectElementProps } from 'react-hook-form-mui';

interface StateSelectFieldProps extends Omit<SelectElementProps, 'options'> {}

const StateSelectField = (props: StateSelectFieldProps) => {
    return (
        <SelectElement
            options={[
                { id: 1, label: 'ACT', value: 'ACT' },
                { id: 2, label: 'NSW', value: 'NSW' },
                { id: 3, label: 'NT', value: 'NT' },
                { id: 4, label: 'QLD', value: 'QLD' },
                { id: 5, label: 'SA', value: 'SA' },
                { id: 6, label: 'TAS', value: 'TAS' },
                { id: 7, label: 'VIC', value: 'VIC' },
                { id: 8, label: 'WA', value: 'WA' },
            ]}
            valueKey="value"
            {...props}
        />
    );
};

export default StateSelectField;
