import ModalProvider from 'mui-modal-provider';
import { useState } from 'react';
import { LoaderFunction, useLoaderData, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { DeceasedDetail, deceasedDetailQuery } from '../../api/deceasedDetail';
import { queryClient } from '../../api/queryClient';
import { WorkspaceDetail, workspaceQuery } from '../../api/workspace';
import { workspaceMembershipQuery } from '../../api/workspaceMembership';
import UploadsSnackbar from '../../components/UploadsSnackbar';
import { StoreProvider } from '../../store/storeContext';
import { UploadStore } from '../../store/uploadStore';
import WorkspaceLayout from './WorkspaceLayout';
import { WorkspaceProvider } from './workspaceContext';

export const loader: LoaderFunction = async ({ params: { workspaceId } }) => {
    invariant(workspaceId);

    const workspaceQ = workspaceQuery(workspaceId);
    const workspace: WorkspaceDetail =
        queryClient.getQueryData(workspaceQ.queryKey) ?? (await queryClient.fetchQuery(workspaceQ));

    await queryClient.prefetchQuery(workspaceMembershipQuery(workspace.memberships[0]));

    const detailsQuery = deceasedDetailQuery(workspace.deceased_detail.id);

    const details =
        queryClient.getQueryData(detailsQuery.queryKey) ??
        (await queryClient.fetchQuery(detailsQuery));

    return {
        workspace,
        details,
    };
};

function Workspace() {
    const { workspaceId } = useParams();
    const [store] = useState(new UploadStore());

    invariant(workspaceId);

    const initialWorkspaceData = useLoaderData() as {
        workspace: WorkspaceDetail;
        deceasedDetail: DeceasedDetail;
    };

    return (
        <WorkspaceProvider value={initialWorkspaceData.workspace}>
            <StoreProvider value={store}>
                <ModalProvider>
                    <WorkspaceLayout />
                    <UploadsSnackbar />
                </ModalProvider>
            </StoreProvider>
        </WorkspaceProvider>
    );
}

export default Workspace;
