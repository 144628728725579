import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { FinalisationFormDocument, finalisationFormQuery } from '../../../api/document';
import {
    deleteFinalisationFormReplyMutation,
    uploadFinlisationFormReply,
} from '../../../api/formsAndIndemnities';
import { queryClient } from '../../../api/queryClient';
import DateTimeString from '../../../components/DateTimeString';
import DeleteDocumentDialog from '../../../components/DeleteDocumentDialog';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import TruncatedTableCell from '../../../components/TruncatedTableCell';
import UploadButton from '../../../components/UploadButton';
import UploadDropArea from '../../../components/UploadDropArea';
import { useStore } from '../../../store/storeContext';
import { invalidateWorkspace } from '../../../utils';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

function AccountInstructions() {
    const workspace = useWorkspace();

    const [searchParams] = useSearchParams();
    const membershipParam = searchParams.get('membership') || '';

    const query = useQuery({
        ...finalisationFormQuery(workspace.id, { membership: membershipParam }),
        placeholderData: (previousData, _) => previousData,
    });

    const memberFilterOptions: SearchParamSelectOption[] =
        query.data?.results
            .filter((result) => !!result.membership)
            .map((result) => ({
                id: result.membership || '',
                label:
                    result.uploaded_by.organisation_external_display_name ||
                    result.uploaded_by.organisation_name ||
                    '',
            }))
            .sort((org1, org2) => org1.label.localeCompare(org2.label))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    return (
        <PageLayout title="Account Instructions">
            <QueryProgress query={query}>
                <SearchParamSelect
                    sx={{ mt: -2 }}
                    parameter={'membership'}
                    options={memberFilterOptions}
                    placeholder="Filter by Participant"
                />
                {!!query.data?.results.length ? (
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ whiteSpace: 'nowrap' }}>
                                <TableCell>Participant</TableCell>
                                <TableCell>Sector</TableCell>
                                <TableCell>Template</TableCell>
                                <TableCell>Uploaded</TableCell>
                                <TableCell>Uploaded By</TableCell>
                                <TableCell>Response</TableCell>
                            </TableHead>
                            <TableBody>
                                {query.data.results?.map((form) => (
                                    <FormsResponsesRow key={form.id} form={form} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Placeholder
                        title="No files yet"
                        description="Account Instructions uploaded by Workspace Participants will appear here"
                    />
                )}
            </QueryProgress>
        </PageLayout>
    );
}

function FormsResponsesRow({ form }: { form: FinalisationFormDocument }) {
    const store = useStore();
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const [searchParams] = useSearchParams();
    const membershipParam = searchParams.get('membership') || '';

    const onUpload = (file: File) =>
        store.uploadDocument(
            file,
            (file: File) => uploadFinlisationFormReply(workspace.id, form.id, file),
            () => {
                invalidateWorkspace(workspace.id);
                queryClient.invalidateQueries(
                    finalisationFormQuery(workspace.id, { membership: membershipParam })
                );
            }
        );

    const deleteMutation = useMutation(deleteFinalisationFormReplyMutation());

    const handleDelete = () => {
        deleteMutation.mutate(form.reply!.id, {
            onSuccess: () => {
                invalidateWorkspace(workspace.id);
                queryClient.invalidateQueries(
                    finalisationFormQuery(workspace.id, { membership: membershipParam })
                );
            },
        });
    };
    return (
        <TableRow
            sx={{
                whiteSpace: 'nowrap',
            }}
        >
            <TruncatedTableCell maxWidth="20ch">
                {form?.uploaded_by.organisation_external_display_name ||
                    form?.uploaded_by.organisation_name}
            </TruncatedTableCell>
            <TruncatedTableCell>{form?.uploaded_by.organisation_industry}</TruncatedTableCell>
            <TruncatedTableCell>
                <FileName file_name={form.file_name || ''} file={form.file || ''} />
            </TruncatedTableCell>
            <TableCell>{DateTimeString(form.created_at)}</TableCell>
            <TruncatedTableCell>{form.uploaded_by.name}</TruncatedTableCell>
            <TableCell>
                {form.reply ? (
                    <FileName
                        file_name={form.reply.file_name || ''}
                        file={form.reply.file || ''}
                        onDelete={() => {
                            showModal(DeleteDocumentDialog, {
                                onDelete: handleDelete,
                            });
                        }}
                    />
                ) : (
                    <UploadDropArea onUpload={onUpload}>
                        <Stack direction="row" gap={2} alignItems="center">
                            <UploadButton
                                onUpload={onUpload}
                                variant="inverse"
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Upload completed form
                            </UploadButton>
                        </Stack>
                    </UploadDropArea>
                )}
            </TableCell>
        </TableRow>
    );
}

interface FileNameProps {
    file_name: string;
    file: string;
    onDelete?: () => void;
}

function FileName({ file_name, file, onDelete }: FileNameProps) {
    const [hover, setHover] = useState(false);
    return (
        <Stack
            direction="row"
            gap={1}
            alignItems="center"
            component={RouterLink}
            target="_blank"
            to={file || ''}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                color: 'inherit',
                maxWidth: '20ch',
                '&:hover': { textDecoration: 'underline' },
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    flexShrink: 1,
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                {file_name}
            </Typography>

            {onDelete ? (
                <Tooltip title="Delete response">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete();
                        }}
                        sx={{ visibility: hover ? 'visible' : 'hidden' }}
                    >
                        <IconTrash size={22} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Stack>
    );
}

export default AccountInstructions;
