import { Container, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';

interface ToolPageLayoutProps extends PropsWithChildren {
    title: React.ReactNode;
    description?: React.ReactNode;
    right?: React.ReactNode;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const ToolPageLayout = ({
    children,
    description,
    right,
    title,
    maxWidth = 'lg',
}: ToolPageLayoutProps) => {
    return (
        <Container
            maxWidth={maxWidth}
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        >
            <Stack
                sx={{
                    backgroundColor: 'background.default',
                    zIndex: 'appBar',
                    position: 'sticky',
                    top: 0,
                    pt: 3,
                    pb: 1,
                    mb: 2,
                }}
            >
                <Breadcrumbs>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="inherit"
                        to="/standalone-tools"
                    >
                        Standalone Tools
                    </Link>
                    ,
                    <Typography key="3" color="text.primary">
                        {title}
                    </Typography>
                </Breadcrumbs>
                <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Tooltip title="Back to Standalone Tools">
                            <IconButton component={RouterLink} to="/standalone-tools">
                                <IconArrowLeft size={24} />
                            </IconButton>
                        </Tooltip>
                        <Stack>
                            <Typography variant="h3">{title}</Typography>
                            <Typography variant="body2" sx={{ mt: -0.25, pl: 0.2 }}>
                                {description}
                            </Typography>
                        </Stack>
                    </Stack>
                    {right}
                </Stack>
            </Stack>

            {children}
        </Container>
    );
};

export default ToolPageLayout;
