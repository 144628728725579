import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import { Issue, ThemesIssuesMap } from '.';
import { createRequestMutation } from '../../../api/requests';
import { workspaceMembershipsQuery } from '../../../api/workspaceMembership';
import FormGrid from '../../../components/FormGrid';
import useUser from '../../../hooks/useUser';
import { characterLimit, noSpecialChars, requiredString } from '../../../validationRules';
import { useWorkspace } from '../workspaceContext';

type Recipients = Array<{ label: string; id: string }>;
interface SendRequestDialogProps extends DialogProps {
    onClose?: () => void;
    sentIssues: ThemesIssuesMap;
}

const validationSchema = z.object({
    recipient: requiredString,
    theme: requiredString,
    issue: requiredString,
    comment: requiredString.and(characterLimit(300)).and(noSpecialChars),
});

function SendRequestDialog({ onClose, sentIssues, ...dialogProps }: SendRequestDialogProps) {
    const workspace = useWorkspace();
    const user = useUser();
    const createRequest = useMutation(createRequestMutation());
    const [activeIssues, setActiveIssues] = useState<Array<Issue> | null>(null);
    const [activeIssue, setActiveIssue] = useState<Issue | null>(null);

    // Turns our issues map above into a usable list of
    //  [{label: '....', value: '....'}]
    const themes = Object.entries(sentIssues).reduce(
        (acc, i) => acc.concat([{ label: i[1].label, value: i[0] }]),
        [] as Array<{ label: string; value: string }>
    );

    const recipientsQuery = useQuery(workspaceMembershipsQuery({ workspace: workspace.id }));
    const recipients =
        recipientsQuery.data?.map((r) => ({
            label: r.organisation_name,
            id: r.organisation,
        })) || [];

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            recipient: '',
            theme: '',
            issue: '',
            comment: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const handleSuccess = (values: any) => {
        createRequest
            .mutateAsync({
                workspace: workspace.id,
                recipient: values.recipient,
                theme: values.theme,
                issue: values.issue,
                comment: values.comment,
            })
            .then(() => {
                onClose?.();
            });
    };

    const updateActiveIssues = (value: keyof typeof sentIssues) => {
        const details = sentIssues[value];
        if (details) {
            setActiveIssues(details.issues);
        } else {
            setActiveIssues([]);
        }
    };

    const updateActiveIssue = (value: string) => {
        const issue = activeIssues?.find((i) => i.label === value) as Issue;
        setActiveIssue(issue);
    };

    // Here we need to do some data munging.
    // We set it so that if the user ISNT a `re_rep` type we only allow
    // the data to be sent to the workspace owner.
    // If it is an owner we allow them to access all participants

    const workspaceOwner: Recipients = [
        {
            label: workspace.created_by.organisation_name || '',
            id: workspace.created_by.organisation || '',
        },
    ];

    const recipientList: Recipients =
        user?.user_type === 'de_representative' ? recipients : workspaceOwner;

    useEffect(() => {
        if (recipientList.length === 1) {
            formMethods.setValue('recipient', recipientList?.[0].id);
        }
    }, [recipientList.length]);

    return (
        <Dialog maxWidth="md" fullWidth onClose={onClose} scroll="body" {...dialogProps}>
            <FormContainer formContext={formMethods} onSuccess={handleSuccess}>
                <DialogTitle>New Request</DialogTitle>
                <DialogContent>
                    <FormGrid>
                        <Grid item xs={12}>
                            <SelectElement
                                name="recipient"
                                label="Recipient"
                                required
                                fullWidth
                                options={recipientList}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SelectElement
                                name="theme"
                                label="Theme"
                                required
                                fullWidth
                                options={themes}
                                valueKey="value"
                                labelKey="label"
                                onChange={(e: keyof typeof sentIssues) => updateActiveIssues(e)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SelectElement
                                name="issue"
                                label="Issue"
                                required
                                fullWidth
                                options={activeIssues ?? []}
                                valueKey="label"
                                labelKey="label"
                                disabled={activeIssues === null}
                                onChange={updateActiveIssue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldElement
                                multiline
                                name="comment"
                                label="Comments/Requests"
                                fullWidth
                                required
                                minRows={5}
                                maxRows={10}
                                placeholder={activeIssue?.guidance || ''}
                            />
                        </Grid>
                    </FormGrid>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'end' }}>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="success" type="submit">
                        Send
                    </Button>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default SendRequestDialog;
