// Copyright (C) 2024, Affinda

import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { IconAlertCircle } from '@tabler/icons-react';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import LoadingArea from './LoadingArea';
import Placeholder from './Placeholder';

type Query = UseQueryResult | UseMutationResult;

export interface QueryProgressProps extends PropsWithChildren {
    query: Query | Query[];
    variant?: 'block' | 'inline';
}

const QueryProgress: FC<QueryProgressProps> = ({ query, variant = 'block', children }) => {
    const queries = Array.isArray(query) ? query : [query];
    const errored = queries.filter((query) => query.isError);

    if (errored.length) {
        const tryAgain = (
            <Button
                onClick={() =>
                    errored.forEach((query) => {
                        if ('refetch' in query) {
                            query.refetch();
                        } else if ('mutate' in query) {
                            query.mutate(query.variables);
                        }
                    })
                }
            >
                Try Again
            </Button>
        );

        return variant === 'inline' ? (
            <Stack direction="row" gap={1} alignItems="center" sx={{ color: 'error.main' }}>
                <IconAlertCircle /> <Typography fontWeight="bold">Something went wrong</Typography>{' '}
                {tryAgain}
            </Stack>
        ) : (
            <Placeholder IconComponent={IconAlertCircle} title="Something went wrong">
                {tryAgain}
            </Placeholder>
        );
    }

    if (queries.some((query) => query.isPending)) {
        return variant === 'inline' ? <CircularProgress size={20} /> : <LoadingArea />;
    }

    return <>{children}</>;
};

export default QueryProgress;
