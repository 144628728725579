import { Alert, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import { deceasedDetailQuery } from '../../../api/deceasedDetail';
import { DeathCertificateDocument, GenericDocument } from '../../../api/document';
import { proofOfDeathTaskQuery, updateProofOfDeathTask } from '../../../api/proofOfDeath';
import { queryClient } from '../../../api/queryClient';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import DateString from '../../../components/DateString';
import QueryProgress from '../../../components/QueryProgress';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import { DocumentTableRowType } from '../../../components/documentsTable/DocumentsTableCells';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

function ProofOfDeath() {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    const podTask = workspace.tasks.proof_of_death;
    invariant(podTask);

    const query = useQuery(proofOfDeathTaskQuery(podTask));

    const updateMutation = useMutation(updateProofOfDeathTask(podTask));

    const handleAccept = () => {
        updateMutation.mutate(
            { status: 'completed' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Proof of Death accepted.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoAccept = () => {
        updateMutation.mutate(
            {
                status: 'ready_for_action',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    return (
        <QueryProgress query={query}>
            <PageLayout
                title="Proof of Death"
                actions={
                    <Stack direction="row" gap={1.5} alignItems="center">
                        <CompleteTaskButton
                            completed={query.data?.status === 'completed'}
                            onComplete={handleAccept}
                            onUndo={handleUndoAccept}
                            sx={{ pl: 2 }}
                        />
                    </Stack>
                }
            >
                <Stack gap={4}>
                    <ReadonlyField
                        label="Date of death"
                        value={DateString(details?.date_of_death)}
                    />

                    <ReadonlyField
                        label="Location of death"
                        value={details?.place_of_death_overseas || details?.place_of_death}
                    />

                    <ReadonlyField
                        label="Death Certificate"
                        value={
                            workspace.death_certificate ? (
                                <DeathCertificateTable
                                    deathCertificate={workspace.death_certificate}
                                />
                            ) : undefined
                        }
                    />

                    {!workspace.death_certificate && (
                        <ReadonlyField
                            label="Reason for no Death Certificate"
                            value={details?.no_death_cert_reason}
                        />
                    )}

                    <ReadonlyField
                        label="Other Proof of Death"
                        value={
                            workspace.other_pod_docs.length > 0 && (
                                <OtherDocumentsTable
                                    otherDocuments={[...workspace.other_pod_docs]}
                                />
                            )
                        }
                    />
                </Stack>
            </PageLayout>
        </QueryProgress>
    );
}

function DeathCertificateTable({
    deathCertificate,
}: {
    deathCertificate: DeathCertificateDocument;
}) {
    return (
        <Stack gap={1.5}>
            <DocumentsTable documents={[deathCertificate]} />
            <Alert severity="info" sx={{ alignItems: 'center', mb: 2 }}>
                Ensure you compare the attached Death Certificate document with the data points
                shown in the above digital certificate. Australian Death Check verifies key data
                from the Death Certificate, but not the uploaded document itself.
            </Alert>
        </Stack>
    );
}

function OtherDocumentsTable({ otherDocuments }: { otherDocuments?: GenericDocument[] }) {
    return (
        <DocumentsTable
            documents={otherDocuments}
            extraColumns={[
                {
                    id: 'type',
                    label: 'Type',
                    Component: DocumentTableRowType,
                },
            ]}
        />
    );
}

export default ProofOfDeath;
