import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
} from '@tanstack/react-query';
import { GenericDocument } from './document';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type TaskTypeEnum = components['schemas']['TaskTypeEnum'];
export type PaginatedGenericDocumentList = components['schemas']['PaginatedGenericDocumentList'];
export type PaginatedGenericDocumentListRequestParams =
    operations['documents_generic_list']['parameters']['query'];

// BUG: We need to pass in `task_type` explicitly because currently the way that the client
// generates the response isn't accepted by django'
export function getGenericDocuments(
    task_type: TaskTypeEnum,
    options: PaginatedGenericDocumentListRequestParams
) {
    return {
        queryKey: ['workspace', 'documents', task_type, options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedGenericDocumentList>({
                url: `/documents/generic?task_type=${task_type}`,
                method: 'GET',
                params: options,
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedGenericDocumentList>;
}

export function uploadGenericDocumentMutation() {
    return {
        mutationFn: (data) =>
            uploadGenericDocument(data.workspace, data.file, data.documentType, data.taskType),
    } satisfies UseMutationOptions<
        GenericDocument,
        any,
        { workspace: string; file: File; documentType: string; taskType: TaskTypeEnum },
        any
    >;
}

export function uploadGenericDocument(
    workspace: string,
    file: File,
    documentType: string,
    taskType: TaskTypeEnum
) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('workspace', workspace.toString());
    formData.append('document_type', documentType);
    formData.append('task_type', taskType);

    return api<GenericDocument>({
        url: `/documents/generic`,
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deleteGenericDocumentMutation() {
    return {
        mutationFn: (id: string) => {
            return api<string>({
                url: `/documents/generic/${id}`,
                method: 'DELETE',
            }).then(() => id);
        },
    } satisfies UseMutationOptions<string, any, string, any>;
}
