import { Grid, GridProps } from '@mui/material';

function FormGrid({ children, ...gridProps }: GridProps) {
    return (
        <Grid container columnSpacing={4} rowSpacing={1} {...gridProps} sx={{ ...gridProps.sx }}>
            {children}
        </Grid>
    );
}

export default FormGrid;
