import { BreadcrumbsProps, Link, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Breadcrumbs({ children, ...breadcrumbProps }: BreadcrumbsProps) {
    return (
        <MuiBreadcrumbs
            separator="›"
            {...breadcrumbProps}
            sx={{ pl: 0.35, mb: -0.5, ...breadcrumbProps.sx }}
        >
            <Link component={RouterLink} underline="hover" key="1" color="inherit" to="/">
                Home
            </Link>
            ,{children}
        </MuiBreadcrumbs>
    );
}

export default Breadcrumbs;
