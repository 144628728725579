import { Button, IconButton, InputLabel, Stack, StackProps } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';

interface TextFieldArrayProps extends StackProps {
    name: string;
    label?: React.ReactNode;
    itemLabel?: (index: number) => string;
    addMessage: string;
    textFieldElementProps?: Partial<TextFieldElementProps>;
}

function TextFieldArray({
    name,
    label,
    itemLabel,
    addMessage,
    textFieldElementProps,
    ...stackProps
}: TextFieldArrayProps) {
    const { control, trigger } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    return (
        <Stack gap={1} sx={{ pb: 3 }}>
            {label ? <InputLabel>{label}</InputLabel> : null}
            <Stack gap={0} {...stackProps}>
                {fields.map((field, index) => (
                    <Stack direction="row" alignItems="flex-start" key={field.id}>
                        <Stack width="100%">
                            <TextFieldElement
                                fullWidth
                                autoFocus
                                label={itemLabel?.(index)}
                                name={`${name}[${index}]`}
                                type="text"
                                required
                                {...textFieldElementProps}
                            />
                        </Stack>
                        <IconButton
                            size="small"
                            onClick={() => remove(index)}
                            sx={{ ml: 1, mt: 1.75 }}
                        >
                            <IconTrash size={20} />
                        </IconButton>
                    </Stack>
                ))}
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                        trigger(name);
                        append('');
                    }}
                    sx={{ width: 'fit-content' }}
                >
                    {addMessage}
                </Button>
            </Stack>
        </Stack>
    );
}

export default TextFieldArray;
