import {
    Autocomplete,
    CircularProgress,
    Grid,
    GridProps,
    InputAdornment,
    TextField,
} from '@mui/material';
import { useDebouncedEffect } from '@react-hookz/web';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, TextFieldElement, get, useFormContext } from 'react-hook-form-mui';
import { api } from '../../api/utils';
import { appSettings } from '../../appSettings';

interface AddressAutocompleteResponse {
    completions: AddressAutocompleteCompletion[];
}

interface AddressAutocompleteCompletion {
    canonical_address_id: string;
    id: string;
    full_address: string;
}

interface AddressMetadata {
    id: string;
    full_address: string;
    address_line_1: string;
    address_line_2: string;
    locality_name: string;
    state_territory: string;
    postcode: string;
}

interface AddressFieldProps {
    name: string;
    label?: string;
    required?: boolean;
    disableClearable?: boolean;
    gridProps?: GridProps;
}

function AddressField({ name, required, disableClearable, gridProps }: AddressFieldProps) {
    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const { addressfinderKey } = appSettings;

    const fieldError = get(errors, name);
    const streetError = fieldError?.street;

    // Input and query state must be controlled separately so that the
    // query can be debounced without causing delayed input updates when typing
    const [input, setInput] = useState('');
    const [query, setQuery] = useState('');

    useDebouncedEffect(
        () => {
            setQuery(input);
        },
        [input],
        250
    );

    const addressAutocompleteQuery = useQuery({
        queryKey: ['addressAutocomplete', query],
        queryFn: () =>
            api<AddressAutocompleteResponse>({
                url: 'https://api.addressfinder.io/api/au/address/autocomplete/',
                method: 'GET',
                params: {
                    key: addressfinderKey,
                    q: query,
                    format: 'json',
                },
            }).then((response) => response.data),
        staleTime: Infinity,
    });

    const options = addressAutocompleteQuery.data?.completions ?? [];

    const addressMetadataMutation = useMutation({
        mutationFn: (id: string) =>
            api<AddressMetadata>({
                url: 'https://api.addressfinder.io/api/au/address/metadata/',
                method: 'GET',
                params: {
                    key: addressfinderKey,
                    id: id,
                    format: 'json',
                },
            }).then((response) => response.data),
    });

    const handleSelectAddress = async (id: string) =>
        addressMetadataMutation.mutateAsync(id).then((data) => {
            setValue(
                `${name}.street`,
                data.address_line_1 + (data.address_line_2 ? ', ' + data.address_line_2 : ''),
                {
                    shouldValidate: true,
                }
            );
            // no need to revalidate full address since it is a hidden field
            setValue(`${name}.city`, data.locality_name, { shouldValidate: true });
            setValue(`${name}.state`, data.state_territory, { shouldValidate: true });
            setValue(`${name}.postcode`, data.postcode, { shouldValidate: true });
        });

    const watchStreet = watch(`${name}.street`);
    const watchCity = watch(`${name}.city`);
    const watchState = watch(`${name}.state`);
    const watchPostcode = watch(`${name}.postcode`);

    useEffect(() => {
        setValue(
            `${name}.full_address`,
            `${watchStreet}, ${watchCity} ${watchState} ${watchPostcode}`
        );
    }, [watchStreet, watchCity, watchState, watchPostcode]);

    return (
        <Grid container columnSpacing={3} rowSpacing={3} {...gridProps}>
            <Grid item xs={12}>
                <Controller
                    name={`${name}.street`}
                    control={control}
                    defaultValue={''}
                    rules={required ? { required: 'This field is required' } : {}}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Autocomplete
                            ref={ref}
                            value={value}
                            freeSolo
                            filterOptions={(options, params) => {
                                // force the dropdown to show in freeSolo mode when there are no options
                                const { inputValue } = params;

                                if (inputValue !== '' && addressAutocompleteQuery.isLoading) {
                                    return [
                                        {
                                            canonical_address_id: '',
                                            id: 'disabled',
                                            full_address: 'Searching...',
                                        },
                                    ];
                                }

                                if (inputValue !== '' && options.length === 0) {
                                    return [
                                        {
                                            canonical_address_id: '',
                                            id: 'disabled',
                                            full_address: 'No results found',
                                        },
                                    ];
                                }

                                return options;
                            }}
                            getOptionDisabled={(option) => option?.id === 'disabled'}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.full_address
                            }
                            isOptionEqualToValue={(option, value) => option.full_address === value}
                            options={options}
                            onChange={(_, value) => {
                                if (value) {
                                    handleSelectAddress(value.id);
                                }
                            }}
                            onBlur={onBlur}
                            inputValue={input}
                            onInputChange={(_, value) => {
                                setInput(value);
                                onChange(value);
                            }}
                            disableClearable={disableClearable}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Street"
                                    required={required}
                                    error={!!streetError}
                                    helperText={streetError ? `${streetError.message}` : ''}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {addressAutocompleteQuery.isLoading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                        sx={{ mb: 2, mr: 0.5 }}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldElement
                    fullWidth
                    required={required}
                    name={`${name}.city`}
                    label="City"
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldElement
                    fullWidth
                    required={required}
                    name={`${name}.state`}
                    label="State"
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldElement
                    fullWidth
                    required={required}
                    name={`${name}.postcode`}
                    label="Postcode"
                />
            </Grid>
            <TextField sx={{ display: 'none' }} name={`${name}.full_address`} />
        </Grid>
    );
}

export default AddressField;
