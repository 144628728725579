// Copyright (C) 2024, Affinda

import { Box, Stack, StackProps } from '@mui/material';
import React from 'react';

export interface IconLabelProps extends StackProps {
    icon: React.ReactNode;
    label: React.ReactNode;
}

export default React.forwardRef<HTMLDivElement, IconLabelProps>(function IconLabel(props, ref) {
    const { icon, label, children, ...stackProps } = props;

    return (
        <Stack
            component="span"
            direction="row"
            gap={0.5}
            alignItems="center"
            {...stackProps}
            sx={{
                display: 'inline-flex',
                verticalAlign: 'middle',
                overflow: 'hidden',
                ['& > :first-child' + ignoreSsrWarning]: { flexShrink: 0 },
                ...stackProps.sx,
            }}
            ref={ref}
        >
            {icon}
            <Box
                className="IconLabel-label"
                component="span"
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {label}
            </Box>
            {children}
        </Stack>
    );
});

// See https://github.com/emotion-js/emotion/pull/1209
// We don't do SSR so the warning can be safely ignored.
export const ignoreSsrWarning =
    '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';
