import { QueryFunctionContext, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type InstitutionOrganisation = components['schemas']['InstitutionOrganisation'];
export type InstitutionOrganisationRequestParams =
    operations['auth_institution_orgs_list']['parameters']['query'];
export type PaginatedInstitutionOrganisationList =
    components['schemas']['PaginatedInstitutionOrganisationList'];

export function institutionsOrganisationsQuery(options?: InstitutionOrganisationRequestParams) {
    return {
        queryKey: ['institutions', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<PaginatedInstitutionOrganisationList>({
                url: `/auth/institution_orgs/`,
                params: options,
                method: 'GET',
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<PaginatedInstitutionOrganisationList>;
}
