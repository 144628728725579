import { Box, Stack } from '@mui/material';
import { IconCircleFilled, IconClock } from '@tabler/icons-react';
import { RequestMeta } from '../../../api/requests';
import useUser from '../../../hooks/useUser';

interface RequestStatusProps {
    request: RequestMeta;
}

const getStatusLabel = (isRequestCreatedByUser: boolean, request: RequestMeta): string => {
    if (isRequestCreatedByUser) {
        switch (request.status) {
            case 'awaiting_response':
                return 'Await response';
            case 'response_provided':
                return 'Review';
            case 'completed':
                return 'Completed';
            default:
                return request.status_label;
        }
    } else {
        switch (request.status) {
            case 'awaiting_response':
                return 'Respond';
            case 'response_provided':
                return 'No action required';
            case 'completed':
                return 'Completed';
            default:
                return request.status_label;
        }
    }
};

function RequestStatus({ request }: RequestStatusProps) {
    const user = useUser();
    const isRequestCreatedByUser = user?.de_rep_organisation
        ? request.sender === user?.de_rep_organisation.id
        : request.sender === user?.institution_organisation.id;

    const statusLabel = getStatusLabel(isRequestCreatedByUser, request);
    return (
        <Stack direction="row" gap={1} alignContent="center">
            {request.action_required && (
                <Box color="info.main">
                    <IconCircleFilled size={10} />
                </Box>
            )}

            {statusLabel.startsWith('Await') && (
                <Box color="inherit" sx={{ opacity: 0.5, pt: 0.1 }}>
                    <IconClock size={15} />
                </Box>
            )}

            {statusLabel}
        </Stack>
    );
}

export default RequestStatus;
