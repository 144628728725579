import {
    Button,
    FormHelperText,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { IconPencil } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useFormContext } from 'react-hook-form';
import { relatedPeopleQuery, relatedPersonRoleLabels } from '../../../../api/relatedPerson';
import { Card } from '../../../../components/Card';
import QueryProgress from '../../../../components/QueryProgress';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import { useWorkspace } from '../../workspaceContext';

function LegalPersonalRepresentativesCard() {
    const { showModal } = useModal();
    const workspace = useWorkspace();

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const personalRepresentatives =
        peopleQuery.data?.results.filter((person) => person.is_lpr) || [];

    const {
        trigger,
        formState: { errors },
    } = useFormContext();

    // Retrigger field validation when the list of personal representatives changes
    useUpdateEffect(() => {
        trigger('personal_representatives');
    }, [personalRepresentatives?.length]);

    return (
        <Card title="Personal Representatives">
            <Stack gap={1}>
                <QueryProgress query={peopleQuery}>
                    {personalRepresentatives?.length ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell sx={{ width: '3.5rem' }} />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {personalRepresentatives.map((person) => (
                                        <TableRow
                                            key={person.id}
                                            sx={{
                                                // position: relative so that a button pseudo-element can cover
                                                // the entire row. But this is buggy in Safari and requires a
                                                // workaround using clip-path.
                                                // https://github.com/w3c/csswg-drafts/issues/1899#issuecomment-1232707455
                                                position: 'relative',
                                                clipPath: 'inset(0)',
                                            }}
                                        >
                                            <TableCell>{person.full_name}</TableCell>
                                            <TableCell>
                                                {person.role === 'other'
                                                    ? person.role_detail
                                                    : relatedPersonRoleLabels[person.role]}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    disableRipple
                                                    sx={{
                                                        position: 'static',
                                                        'tr:not(:hover) &:not(:focus-visible)': {
                                                            opacity: 0,
                                                        },
                                                        '&::before': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            inset: 0,
                                                            zIndex: 1,
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        showModal(RelatedPersonDrawer, {
                                                            person,
                                                            workspace,
                                                            assumeLpr: true,
                                                        })
                                                    }
                                                >
                                                    <IconPencil />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                </QueryProgress>
                <Stack>
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ maxWidth: 'fit-content' }}
                        onClick={() =>
                            showModal(RelatedPersonDrawer, {
                                workspace,
                                assumeLpr: true,
                            })
                        }
                    >
                        Add Personal Representative
                    </Button>

                    {errors.personal_representatives && (
                        <FormHelperText
                            error
                            sx={{ ml: 1.5 }}
                        >{`${errors.personal_representatives?.message}`}</FormHelperText>
                    )}
                </Stack>
            </Stack>
        </Card>
    );
}

export default LegalPersonalRepresentativesCard;
