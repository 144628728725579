import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Collapse,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { IconGavel, IconShare2 } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import { retrieveGorMutation } from '../../api/standaloneTools/GorRetrieval';
import DateTimeString from '../../components/DateTimeString';
import FormGrid from '../../components/FormGrid';
import { characterLimit, onlyNumbers, requiredString } from '../../validationRules';
import ToolPageLayout from './ToolPageLayout';

const validationSchema = z.object({
    application_number: requiredString
        .and(characterLimit(10))
        .and(onlyNumbers)
        .or(z.string().regex(/^\d+(\s\d+)*$/, 'Only numbers are allowed.')),
    unique_identifier: requiredString.and(characterLimit(10)).and(onlyNumbers),
});

type GorRetrievalFields = z.infer<typeof validationSchema>;

function GorRetrievalTool() {
    const [retrieveGorError, setRetrieveGorError] = useState<string | null>(null);

    const retrieveGor = useMutation({
        ...retrieveGorMutation(),
        onError: async (e: any) => {
            if (e.response.status === 404) {
                e.preventAlert?.();
            }
            const errorData = e.response?.data;
            if (e.request.responseType === 'blob' && errorData instanceof Blob) {
                const errorJSON = JSON.parse(await errorData?.text());
                setRetrieveGorError(errorJSON.errors[0].detail);
            }
        },
    });

    const formMethods = useForm<GorRetrievalFields>({
        mode: 'onChange',
        defaultValues: {
            application_number: '',
            unique_identifier: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const handleSubmit = () => {
        const values = formMethods.getValues();

        retrieveGor.mutate({
            application_number: 'S PRB ' + values.application_number,
            unique_identifier: values.unique_identifier,
        });
    };

    const {
        watch,
        setValue,
        reset,
        formState: { isDirty },
    } = formMethods;

    const watchApplicationNumber = watch('application_number');

    useEffect(() => {
        var formatted = watchApplicationNumber?.replace(' ', '');

        if (formatted?.length > 4) {
            formatted = `${formatted.slice(0, 4)} ${formatted.slice(4)}`;
        }

        setValue('application_number', formatted);
    }, [watchApplicationNumber]);

    const handleReset = () => {
        reset();
        setRetrieveGorError(null);
        retrieveGor.reset();
    };

    return (
        <ToolPageLayout
            title="Grant of Representation Retrieval (VIC)"
            description="Access digital court-issued grant of representation"
        >
            <Paper
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    borderRadius: 5,
                    p: 7,
                }}
            >
                <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                    <FormGrid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                name="application_number"
                                label="Application number"
                                autoFocus
                                fullWidth
                                inputProps={{
                                    maxLength: 10,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">S PRB </InputAdornment>
                                    ),
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                name="unique_identifier"
                                label="Unique identifier"
                                inputProps={{
                                    maxLength: 10,
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                    </FormGrid>

                    <Stack
                        direction="row"
                        alignItems="baseline"
                        justifyContent="space-between"
                        gap={1}
                        sx={{ mt: 4 }}
                    >
                        <Stack direction="row" gap={1}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={retrieveGor.status === 'pending'}
                                disabled={retrieveGor.status === 'pending'}
                            >
                                Submit
                            </LoadingButton>
                            {isDirty ? (
                                <Button color="inherit" onClick={handleReset}>
                                    Reset
                                </Button>
                            ) : null}
                        </Stack>
                        <Typography variant="body3">Source: Redcrest</Typography>
                    </Stack>
                    <Collapse in={!!retrieveGor.data}>
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{ mt: 5 }}
                            icon={<IconGavel />}
                            action={
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    startIcon={<IconShare2 />}
                                    onClick={() => {
                                        const blob = retrieveGor.data;
                                        if (!blob) return;

                                        // Create a URL for the Blob
                                        const url = URL.createObjectURL(blob);

                                        // Create an anchor element and trigger a download
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = 'file.pdf'; // Set the file name
                                        document.body.appendChild(a);
                                        a.click();

                                        // Open the PDF in a new tab
                                        window.open(url, '_blank');

                                        // Clean up
                                        document.body.removeChild(a);
                                        URL.revokeObjectURL(url);
                                    }}
                                >
                                    Open document
                                </Button>
                            }
                        >
                            Grant of representation retrieved
                            <Typography variant="body3">
                                Lookup conducted {DateTimeString(new Date())}
                            </Typography>
                        </Alert>
                    </Collapse>
                    <Collapse in={!!retrieveGorError}>
                        <Alert variant="filled" severity="warning" sx={{ mt: 5 }}>
                            {retrieveGorError}
                            <Typography variant="body3">
                                Lookup conducted {DateTimeString(new Date())}
                            </Typography>
                        </Alert>
                    </Collapse>
                </FormContainer>
            </Paper>
        </ToolPageLayout>
    );
}

export default GorRetrievalTool;
