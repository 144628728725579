import {
    Button,
    DialogContent,
    DialogTitle,
    Drawer,
    DrawerProps,
    IconButton,
    Stack,
    Tab,
    Tabs,
} from '@mui/material';
import { IconTrash, IconX } from '@tabler/icons-react';
import { useModal } from 'mui-modal-provider';
import { FC, useEffect, useState } from 'react';
import { RelatedPersonMeta } from '../../api/relatedPerson';
import { WorkspaceDetail } from '../../api/workspace';
import useUser from '../../hooks/useUser';
import { RelatedPersonDetails } from './RelatedPersonDetail';
import RelatedPersonIdentityDocuments from './RelatedPersonIdentityDocuments';
import RelatedPersonVoI from './RelatedPersonVoI';
import RemoveRelatedPersonDialog from './RemoveRelatedPersonDialog';

export interface TabProps {
    workspace: WorkspaceDetail;
    person?: RelatedPersonMeta;
    assumeLpr?: boolean;
    readOnly?: boolean;
    onCreated?: (person: RelatedPersonMeta) => void;
    onClose?: () => void;
}

export type ReleatedPersonDrawerProps = DrawerProps & TabProps;

const RelatedPersonDrawer: FC<ReleatedPersonDrawerProps> = ({
    workspace,
    person: initialPerson,
    onCreated,
    onClose,
    assumeLpr = false,
    ...props
}) => {
    const { showModal } = useModal();

    const [tab, setTab] = useState<'details' | 'voi' | 'id_docs'>('details');

    const [person, setPerson] = useState<RelatedPersonMeta | undefined>(initialPerson);

    const user = useUser();
    const readOnly = user?.de_rep_organisation === null;

    const tabProps: TabProps = {
        workspace,
        person,
        onClose,
        assumeLpr: assumeLpr,
        readOnly: readOnly,
        onCreated: (person) => {
            onCreated?.(person);
            setPerson(person);
            setTab('voi');
        },
    };

    useEffect(() => {
        if (!person) {
            setTab('details');
        } else {
            setTab('voi');
        }
    }, [person?.id]);

    // mui-modal-provider sets this but it doesn't apply to Drawer
    delete (props as any).TransitionProps;

    return (
        <Drawer anchor="right" onClose={onClose} SlideProps={{ appear: true }} {...props}>
            <Stack
                sx={{
                    flexGrow: 1,
                    width: '40rem',
                }}
            >
                <DialogTitle component={Stack} direction="row" gap={2} alignItems="center" noWrap>
                    <IconButton edge="start" aria-label="close" onClick={() => onClose?.()}>
                        <IconX />
                    </IconButton>

                    {person
                        ? `${person.full_name}`
                        : assumeLpr
                          ? 'Add Personal Representative'
                          : 'Add Person'}

                    {person && !readOnly ? (
                        <Button
                            sx={{ color: 'error.main', ml: 'auto' }}
                            startIcon={<IconTrash />}
                            onClick={() =>
                                showModal(RemoveRelatedPersonDialog, {
                                    person,
                                    onConfirm: onClose,
                                })
                            }
                        >
                            Remove
                        </Button>
                    ) : null}
                </DialogTitle>

                <DialogContent>
                    <Stack gap={4} height="100%">
                        {person ? (
                            <Tabs
                                className="underlined"
                                value={tab}
                                onChange={(_, value) => setTab(value)}
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab className="underlined" value="details" label="Details" />
                                <Tab
                                    className="underlined"
                                    value="voi"
                                    label="Verification of Identity"
                                />
                                <Tab className="underlined" value="id_docs" label="ID documents" />
                            </Tabs>
                        ) : null}

                        {tab === 'details' ? (
                            <RelatedPersonDetails {...tabProps} />
                        ) : tab === 'voi' && !!person ? (
                            <RelatedPersonVoI {...tabProps} person={person} />
                        ) : tab === 'id_docs' && !!person ? (
                            <RelatedPersonIdentityDocuments {...tabProps} person={person} />
                        ) : null}
                    </Stack>
                </DialogContent>
            </Stack>
        </Drawer>
    );
};

export default RelatedPersonDrawer;
