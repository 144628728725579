import { Box, Button, Checkbox, Stack, Switch, TableCell, TableRow } from '@mui/material';
import { IconArrowBackUp } from '@tabler/icons-react';
import { FC } from 'react';
import { RelatedPersonMeta, relatedPersonRoleLabels } from '../../api/relatedPerson';
import { preventClickthrough } from '../../utils';
import VoiStatusChip from '../verificationOfIdentity/VoIStatusChip';

export interface RelatedPersonRowProps {
    person: RelatedPersonMeta;
    onClick?: (person: RelatedPersonMeta) => void;
    onToggleSubscription?: (person: RelatedPersonMeta) => void;
    isPersonSelectedForRequest?: (person: RelatedPersonMeta) => boolean;
    onRequest?: (person: RelatedPersonMeta) => void;
    onCancelVoIRequest?: (person: RelatedPersonMeta) => void;
}

const RelatedPersonRow: FC<RelatedPersonRowProps> = ({
    person,
    onClick,
    onToggleSubscription,
    onRequest,
    isPersonSelectedForRequest,
    onCancelVoIRequest,
}) => {
    const isSubscribed = person.is_subscribed || !onToggleSubscription;

    return (
        <TableRow
            hover={isSubscribed}
            onClick={() => (isSubscribed ? onClick?.(person) : undefined)}
            sx={{
                '& > *': {
                    py: isSubscribed ? '0.75rem !important' : '0rem !important',
                    transition: 'padding 0.3s ease-in-out',
                    boxSizing: 'border-box',
                },
                ...(!isSubscribed
                    ? {
                          bgcolor: 'action.selected',
                          '&:hover': {
                              bgcolor: 'action.selected !important',
                          },
                      }
                    : {
                          cursor: 'pointer',
                      }),
            }}
        >
            {onToggleSubscription ? (
                <TableCell
                    align="center"
                    onClick={(e) => {
                        preventClickthrough(e);
                        onToggleSubscription(person);
                    }}
                    sx={{ pl: 0 }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Switch
                            id={`related-person-${person.id}`}
                            checked={person.is_subscribed}
                            size="small"
                        />
                    </Box>
                </TableCell>
            ) : null}
            <TableCell>{person.full_name}</TableCell>
            <TableCell>
                {person.role === 'other'
                    ? person.role_detail
                    : relatedPersonRoleLabels[person.role]}
            </TableCell>
            <TableCell align="center">{person.has_scanned_id_documents ? 'Yes' : 'No'} </TableCell>
            <TableCell
                align="center"
                onClick={(e) => {
                    if (isSubscribed && !person?.voi_requested && onRequest) {
                        preventClickthrough(e);
                        onRequest(person);
                    }
                }}
            >
                {person.is_professional_trustee ? (
                    'NOT APPLICABLE'
                ) : (
                    <Stack direction="row" width="100%" justifyContent="center">
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            width="fit-content"
                            justifyContent="center"
                            position="relative"
                        >
                            <VoiStatusChip
                                status={person?.e_voi_status || undefined}
                                requested={person?.voi_requested || undefined}
                                sx={{
                                    width: 150,
                                    backgroundColor: !isSubscribed ? 'transparent' : undefined,
                                }}
                            />
                            {isSubscribed && person?.e_voi_status === null ? (
                                <>
                                    {!person?.voi_requested && onRequest ? (
                                        <Button
                                            size="small"
                                            color="inherit"
                                            startIcon={
                                                <Checkbox
                                                    size="small"
                                                    checked={isPersonSelectedForRequest?.(person)}
                                                    sx={{
                                                        p: 0,
                                                        '& .MuiSvgIcon-root': {
                                                            height: '0.9em',
                                                            width: '0.9em',
                                                        },
                                                    }}
                                                />
                                            }
                                            sx={{
                                                visibility: !isSubscribed ? 'hidden' : undefined,
                                                fontSize: '0.75rem',
                                                fontWeight: '400',
                                                position: 'absolute',
                                                px: 1.25,
                                                right: '0',
                                                transform: 'translateX(110%)',
                                            }}
                                        >
                                            Request
                                        </Button>
                                    ) : onCancelVoIRequest ? (
                                        <Button
                                            size="small"
                                            color="error"
                                            startIcon={<IconArrowBackUp size={20} />}
                                            onClick={(e) => {
                                                preventClickthrough(e);
                                                onCancelVoIRequest(person);
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                px: 1.25,
                                                right: '0',
                                                transform: 'translateX(110%)',
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                        </Stack>
                    </Stack>
                )}
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export default RelatedPersonRow;
