import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    List,
    ListItem,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { AutocompleteElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { z } from 'zod';
import { TaskTypeEnum, uploadGenericDocumentMutation } from '../api/genericDocument';
import { queryClient } from '../api/queryClient';
import { workspaceQuery } from '../api/workspace';
import { useWorkspace } from '../pages/workspace/workspaceContext';
import {
    characterLimit,
    noSpecialChars,
    optionalString,
    requiredFile,
    requiredString,
} from '../validationRules';
import FormGrid from './FormGrid';
import DragAndDropFileField from './fields/DragAndDropFileField';

const PoAGenericDocumentTypes = [
    'Will',
    'Grant of probate',
    'Grant of letters of admin.',
    'Death Certificate (intestacy)',
    "SA Registrar's certificate",
    'Codicil',
    'Renunciation',
    'Court judgement',
    'Proof of relationship',
    'Foreign language translation',
    'Other',
];

const PoDGenericDocumentTypes = [
    'Overseas Death Certificate',
    'Medical Cause of Death Certificate',
    'Report to Coroner',
    'Grant of representation',
    'Funeral invoice/receipt',
    'Foreign language translation',
    'Other',
];

const PoIGenericDocumentTypes: string[] = [];

const DocumentTypeOptions: Record<TaskTypeEnum, string[]> = {
    proof_of_authority: PoAGenericDocumentTypes,
    proof_of_death: PoDGenericDocumentTypes,
    proof_of_identity: PoIGenericDocumentTypes,
    account_instructions: ['Other'],
    statement_of_position: ['Other'],
    estate_finalisation: ['Other'],
};

const requireConsent = [
    'Renunciation',
    'Court judgement',
    'Proof of relationship',
    'Foreign language translation',
    'Other',
];

interface uploadGenericDocumentDialogProps extends DialogProps {
    onClose?: () => void;
    title: string;
    taskType: TaskTypeEnum;
    forceRequireConsent?: boolean;
    onUpload?: (file: File) => Promise<void>;
}

const validationSchema = z.object({
    type: requiredString,
    otherType: optionalString.and(characterLimit(30)).and(noSpecialChars),
    file: requiredFile,
});

function UploadGenericDocumentDialog({
    onClose,
    title,
    taskType,
    forceRequireConsent,
    onUpload,
    ...dialogProps
}: uploadGenericDocumentDialogProps) {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            type: taskType === 'proof_of_identity' ? 'Identity document' : '',
            otherType: '',
            file: null,
        },
        resolver: zodResolver(validationSchema),
    });

    const uploadMutation = useMutation(uploadGenericDocumentMutation());
    const uploadDocument = async () => {
        const values = formMethods.getValues();
        if (!values.file) return;

        if (onUpload) {
            await onUpload(values.file);
            onClose?.();
            return;
        }

        uploadMutation
            .mutateAsync({
                workspace: workspace.id,
                taskType: taskType,
                documentType: values.otherType || values.type,
                file: values.file,
            })
            .then(() => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
                enqueueSnackbar('Document uploaded', { variant: 'success' });
                onClose?.();
            });
    };

    const handleSubmit = () => {
        const values = formMethods.getValues();
        if (requireConsent.includes(values.type) || forceRequireConsent) {
            showModal(ConsentDialog, {
                onConfirm: uploadDocument,
            });
        } else {
            uploadDocument();
        }
    };

    const watchFileType = formMethods.watch('type');

    const options = DocumentTypeOptions[taskType];

    return (
        <Dialog onClose={onClose} {...dialogProps} maxWidth="md" fullWidth>
            <DialogTitle>{title}</DialogTitle>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    {taskType === 'proof_of_authority' || forceRequireConsent ? (
                        <Alert severity="info" sx={{ mb: 4 }}>
                            Please ensure you hold the right consents to share any documents
                            containing personal and/or sensitive information.
                        </Alert>
                    ) : null}
                    <FormGrid>
                        <Grid item xs={6}>
                            <AutocompleteElement
                                name="type"
                                label="Document type"
                                options={options}
                                autocompleteProps={{
                                    sx: {
                                        display: !options.length ? 'none' : 'block',
                                    },
                                }}
                                required
                            />
                        </Grid>
                        {watchFileType === 'Other' && (
                            <Grid item xs={6}>
                                <TextFieldElement
                                    name="otherType"
                                    label="Other type"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <DragAndDropFileField name="file" required />
                        </Grid>
                    </FormGrid>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" color="primary">
                        Save
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

interface ConsentDialogProps extends DialogProps {
    onClose?: () => void;
    onConfirm: () => void;
}

function ConsentDialog({ onClose, onConfirm, ...dialogProps }: ConsentDialogProps) {
    return (
        <Dialog maxWidth="sm" fullWidth onClose={onClose} {...dialogProps}>
            <DialogTitle>Consent Guidance</DialogTitle>
            <DialogContent>
                Before uploading this document, please make sure it either:
                <List
                    sx={{
                        listStyleType: 'disc',
                        pl: 2.5,
                        '& > li': {
                            display: 'list-item',
                        },
                    }}
                >
                    <ListItem>Does not contain personal/sensitive information; or</ListItem>
                    <ListItem>You hold the right consents to share the document.</ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onConfirm();
                        onClose?.();
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadGenericDocumentDialog;
