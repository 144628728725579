import { FilledInput, FilledInputProps, IconButton, Stack, Tooltip } from '@mui/material';
import { useDebouncedEffect } from '@react-hookz/web';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

export interface SearchInputProps extends FilledInputProps {
    value?: string;
    forceOpen?: boolean;
    onUpdate?: (value: string) => void;
}

export default function SearchInput({
    value,
    forceOpen,
    onUpdate,
    ...inputProps
}: SearchInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [query, setQuery] = useState(value || '');
    const firstRef = useRef(true);

    const showSearchInput = isFocused || value || forceOpen;

    useDebouncedEffect(
        () => {
            if (!firstRef.current) onUpdate?.(query);
            firstRef.current = false;
        },
        [query],
        200
    );

    useEffect(() => setQuery(value || ''), [value]);

    return (
        <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
            <FilledInput
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder="Search"
                endAdornment={
                    <IconButton
                        onClick={() => setQuery('')}
                        size="small"
                        sx={{
                            visibility: query ? 'visible' : 'hidden',
                            mr: 1,
                        }}
                        data-test="clearSearch"
                    >
                        <IconX size={18} />
                    </IconButton>
                }
                size="small"
                hiddenLabel
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== 'Escape' || query) e.stopPropagation();
                    if (e.key === 'Escape') {
                        setQuery('');
                        onUpdate?.('');
                    }
                }}
                {...inputProps}
                sx={{
                    backgroundColor: 'background.default',
                    width: showSearchInput ? '300px' : '0px',
                    transition: 'width 400ms ease-in-out',
                    p: 0,

                    ...(!forceOpen && {
                        ':before': {
                            borderBottom: '2px solid !important',
                            borderColor: 'text.primary',
                        },
                        '&:hover': {
                            ':before': {
                                borderBottom: '2px solid',
                                borderColor: 'text.primary',
                            },
                        },
                        ':after': {
                            borderBottom: 'none',
                        },
                    }),

                    ...inputProps.sx,
                }}
                inputProps={{
                    ref: inputRef,
                    style: { paddingLeft: 36 },
                }}
            />
            <Tooltip title="search">
                <IconButton
                    size="small"
                    sx={{
                        position: 'absolute',
                        left: 4,
                    }}
                    disabled={forceOpen}
                    onClick={() => {
                        setIsFocused(!isFocused);
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }}
                >
                    <IconSearch size={20} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
