import ModalProvider from 'mui-modal-provider';
import { Outlet, createBrowserRouter, redirect } from 'react-router-dom';
import { logout } from '../api/user';
import Error from '../components/Error';
import ProgressBar from '../components/ProgressBar';
import ConsentForm from '../pages/main/ConsentForm';
import authRoutes from './authRoutes';
import mainRoutes from './mainRoutes';

export const router = createBrowserRouter(
    [
        {
            path: '/',
            element: (
                <ModalProvider>
                    <ProgressBar />
                    <Outlet />
                </ModalProvider>
            ),
            errorElement: <Error />,
            children: [
                authRoutes,
                {
                    path: '/consent',
                    element: <ConsentForm />,
                },
                {
                    path: 'logout',
                    action: async () => {
                        await logout();
                        return redirect('/auth/login');
                    },
                },
                mainRoutes,
            ],
        },
    ],
    { basename: '/' }
);
