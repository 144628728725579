import { UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { components } from '../schema';
import { api, perPage } from '../utils';

export type DeathCheckSingleRequest = components['schemas']['DeathCheckSingleRequestRequest'];
export type DeathCheckSingleResponse = components['schemas']['DeathCheckSingleResponse'];
export type DeachCheckDataSet = components['schemas']['DeathCheckDataSetEnum'];

export function deathCheckMutation() {
    return {
        mutationFn: (data: DeathCheckSingleRequest) =>
            api<DeathCheckSingleResponse>({
                url: `/standalone_tools/death_check_single`,
                method: 'POST',
                data,
            }).then((response) => response.data),
    } satisfies UseMutationOptions<
        DeathCheckSingleResponse,
        AxiosError<any>,
        DeathCheckSingleRequest,
        any
    >;
}

export type DeathCheckBulkTransaction = components['schemas']['DeathCheckBulkTransaction'];
export type DeathCheckTransactionCreateRequest = {
    dataSet: DeachCheckDataSet;
    uploadedFile: File;
    customIdentifier?: string;
    outputFileName?: string;
    description?: string;
};

export function createDeathCheckTransactionMutation() {
    function _(data: DeathCheckTransactionCreateRequest): FormData {
        const formdata = new FormData();
        formdata.append('data_set', data.dataSet);
        formdata.append('uploaded_file', data.uploadedFile);
        formdata.append('custom_identifier', data.customIdentifier || '');
        formdata.append('output_file_name', data.outputFileName || '');
        formdata.append('description', data.description || '');
        return formdata;
    }

    return {
        mutationFn: (data: DeathCheckTransactionCreateRequest) =>
            api<DeathCheckBulkTransaction>({
                url: `/standalone_tools/death_check_bulk_transactions`,
                method: 'POST',
                data: _(data),
            }).then((response) => response.data),
    } satisfies UseMutationOptions<
        DeathCheckBulkTransaction,
        AxiosError<any>,
        DeathCheckTransactionCreateRequest,
        any
    >;
}

export type DeathCheckBulkTransactionList =
    components['schemas']['PaginatedDeathCheckBulkTransactionList'];

export function deathCheckTransactionListQuery() {
    return {
        queryKey: ['death_check_bulk'],
        queryFn: () =>
            api<DeathCheckBulkTransactionList>({
                url: `/standalone_tools/death_check_bulk_transactions`,
                method: 'GET',
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<DeathCheckBulkTransactionList>;
}
