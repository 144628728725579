import { format, isValid } from 'date-fns';

// Format datetime as DD Mon YY HH:MM:SS (e.g. 20 Mar 2024 07:30:00 pm)
function DateTimeString(date?: Date | string | number | null): string {
    if (date === null || date === undefined) return '';

    date = new Date(date);
    if (!isValid(date)) {
        return '';
    }

    return format(date, 'd MMM yyyy hh:mm aaa');
}

export default DateTimeString;
