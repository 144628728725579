import { LoadingButton } from '@mui/lab';
import { Box, formLabelClasses, Link, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { queryClient } from '../../api/queryClient';
import { components } from '../../api/schema';
import { api } from '../../api/utils';
import { appSettings } from '../../appSettings';
import DisclaimerDialog from './DisclaimerDialog';

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

// @ts-ignore: When auth0 is not enabled, this type is not available,
// but that's fine because the mutation that uses it won't be called anyway.
type AuthLoginCreateValidationError = components['schemas']['AuthLoginCreateValidationError'];

function Login() {
    const navigate = useNavigate();
    const { auth0Enabled, showDisclaimer } = appSettings;

    const mutation = useMutation<
        any,
        AxiosError<AuthLoginCreateValidationError, any>,
        FormElements,
        unknown
    >({
        mutationFn: (formElements: FormElements) =>
            api({
                url: '/auth/login/',
                method: 'POST',
                data: {
                    email: formElements.email.value,
                    password: formElements.password.value,
                },
            }).then(() => {
                queryClient.clear();
                const next = localStorage.getItem('next');
                localStorage.removeItem('next');
                return redirect(next || '/');
            }),
        onSuccess: () => {
            navigate('/');
        },
        onError: (e: any) => {
            if (e.response.status === 400) {
                e.preventAlert?.();
            }
        },
    });

    const errors = mutation.error?.response?.data?.errors.reduce(
        (acc: Record<string, string>, error: any) => {
            acc[error.attr] = error.detail;
            return acc;
        },
        {}
    );

    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(showDisclaimer ?? true);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxWidth: '100%',
                px: 2,
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    my: 'auto',
                    p: 5,
                    pb: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '25em',
                    maxWidth: '100%',
                    mx: 'auto',
                    borderRadius: '30px',
                    [`& .${formLabelClasses.asterisk}`]: {
                        visibility: 'hidden',
                    },
                }}
            >
                <Stack gap={4}>
                    <DisclaimerDialog
                        open={isDisclaimerOpen}
                        onClose={() => setIsDisclaimerOpen(false)}
                    />

                    {!auth0Enabled && (
                        <>
                            <Typography component="h1" variant="h3" textAlign="center">
                                Sign In
                            </Typography>
                            <Stack
                                component="form"
                                gap={4}
                                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                                    event.preventDefault();
                                    mutation.mutate(event.currentTarget.elements);
                                }}
                                noValidate
                            >
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    disabled={mutation.isPending}
                                    error={!!errors?.email}
                                    helperText={errors?.email}
                                    sx={{ mb: -3 }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    disabled={mutation.isPending}
                                    error={!!errors?.password || !!errors?.non_field_errors}
                                    helperText={errors?.password || errors?.non_field_errors}
                                    sx={{ mb: -3 }}
                                />

                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    loading={mutation.isPending}
                                >
                                    Sign In
                                </LoadingButton>
                                <Stack alignItems="center" textAlign="center" gap={1}>
                                    <Link href="#" variant="body2">
                                        Forgot Your Password?
                                    </Link>

                                    <Typography variant="body2">
                                        Don't have an account?{' '}
                                        <Link href="#" variant="body2">
                                            Sign Up
                                        </Link>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

export default Login;
