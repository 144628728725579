import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

export type ProofOfAuthorityTask = components['schemas']['ProofOfAuthorityTask'];

export function proofOfAuthorityTaskQuery(id: string) {
    return {
        queryKey: ['proofOfAuthorityTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<ProofOfAuthorityTask>({
                url: `/tasks/proof_of_authority/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function updateProofOfAuthorityTask(id: string) {
    return {
        mutationFn: (data) =>
            api<ProofOfAuthorityTask>({
                url: `/tasks/proof_of_authority/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                proofOfAuthorityTaskQuery(id).queryKey,
                (oldData: ProofOfAuthorityTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(proofOfAuthorityTaskQuery(id).queryKey, context.previousData);
        },
    } satisfies UseMutationOptions<ProofOfAuthorityTask, any, Partial<ProofOfAuthorityTask>, any>;
}

export type lookupGoRRequest = {
    workspace: string;
    application_number: string;
    unique_identifier: string;
};

export type CreateLookupGoRError =
    components['schemas']['DocumentsProofOfAuthorityCreateValidationError'];

export function lookupGoRMutation() {
    return {
        mutationFn: ({ workspace, application_number, unique_identifier }) =>
            api<ProofOfAuthorityTask>({
                url: '/documents/grant_of_representation',
                method: 'POST',
                data: {
                    workspace,
                    jurisdiction: 'VIC',
                    application_number: application_number,
                    unique_identifier: unique_identifier,
                },
            }).then((response) => response.data),
    } satisfies UseMutationOptions<
        ProofOfAuthorityTask,
        AxiosError<CreateLookupGoRError, any>,
        lookupGoRRequest,
        any
    >;
}

export type uploadGoRRequest = {
    workspace: string;
    file: File;
    jurisdiction: components['schemas']['PoAJurisdictionEnum'];
};

export function uploadGoRMutation() {
    return {
        mutationFn: ({ workspace, file, jurisdiction }) =>
            uploadPoADocument(
                {
                    workspace,
                    poa_type: 'grant_of_representation',
                    jurisdiction,
                },
                file
            ),
    } satisfies UseMutationOptions<
        ProofOfAuthorityTask,
        AxiosError<CreateLookupGoRError, any>,
        uploadGoRRequest,
        any
    >;
}

type ProofOfAuthorityDocumentRequest = {
    workspace: string;
    poa_type: components['schemas']['PoaTypeEnum'];
    jurisdiction: components['schemas']['PoAJurisdictionEnum'];
};

export function uploadPoADocument(request: ProofOfAuthorityDocumentRequest, file: File) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('workspace', request.workspace);
    formData.append('poa_type', request.poa_type);
    formData.append('jurisdiction', request.jurisdiction);

    return api<ProofOfAuthorityTask>({
        url: '/documents/proof_of_authority',
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deletePoADocumentMutation() {
    return {
        mutationFn: (id) =>
            api<void>({
                url: `/documents/proof_of_authority/${id}`,
                method: 'DELETE',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<void, any, string, any>;
}
