import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';
import { workspaceMembershipQuery } from './workspaceMembership';

export type FormsAndIndemnitiesTask = components['schemas']['FormsAndIndemnitiesTask'];

export function formsAndIndemnitiesTaskQuery(id: string) {
    return {
        queryKey: ['formsAndIndemnitiesTask', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<FormsAndIndemnitiesTask>({
                url: `/tasks/forms_and_indemnities/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function updateFormsAndIndemnitiesTask(id: string) {
    return {
        mutationFn: (data) =>
            api<FormsAndIndemnitiesTask>({
                url: `/tasks/forms_and_indemnities/${id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                formsAndIndemnitiesTaskQuery(id).queryKey,
                (oldData: FormsAndIndemnitiesTask) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(workspaceMembershipQuery(id).queryKey, context.previousData);
        },
    } satisfies UseMutationOptions<
        FormsAndIndemnitiesTask,
        any,
        Partial<FormsAndIndemnitiesTask>,
        any
    >;
}

type FinalisationFormDocument = components['schemas']['FinalisationFormDocument'];

export function uploadFinalisationFormDocument(
    workspace: string,
    useDefault: boolean,
    file?: File
) {
    const formData = new FormData();

    if (!!file) {
        formData.append('file', file);
        formData.append('file_name', file.name);
    }
    formData.append('use_default', useDefault.toString());
    formData.append('workspace', workspace);

    return api<FinalisationFormDocument>({
        url: `/documents/finalisation_form`,
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deleteFinalisationFormDocumentMutation() {
    return {
        mutationFn: (form) =>
            api<FinalisationFormDocument>({
                url: `/documents/finalisation_form/${form}`,
                method: 'DELETE',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<FinalisationFormDocument, any, string, any>;
}

export function uploadFinlisationFormReply(workspace: string, form: string, file: File) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('workspace', workspace);
    formData.append('finalisation_form', form);

    return api<FinalisationFormDocument>({
        url: `/documents/finalisation_form_reply`,
        method: 'POST',
        data: formData,
    }).then((response) => response.data);
}

export function deleteFinalisationFormReplyMutation() {
    return {
        mutationFn: (reply) =>
            api<FinalisationFormDocument>({
                url: `/documents/finalisation_form_reply/${reply}`,
                method: 'DELETE',
            }).then((response) => response.data),
    } satisfies UseMutationOptions<FinalisationFormDocument, any, string, any>;
}
