import { Box, Fade, RadioGroup, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import {
    estateFinalisationTaskQuery,
    updateEstateFinalisationTask,
} from '../../../api/estateFinalisation';
import { deleteGenericDocumentMutation, uploadGenericDocument } from '../../../api/genericDocument';
import { queryClient } from '../../../api/queryClient';
import { components } from '../../../api/schema';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import { Card } from '../../../components/Card';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import LoadingArea from '../../../components/LoadingArea';
import QueryProgress from '../../../components/QueryProgress';
import SmallRadio from '../../../components/SmallRadio';
import UploadDropArea from '../../../components/UploadDropArea';
import UploadPlaceholder from '../../../components/UploadPlaceholder';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import { useStore } from '../../../store/storeContext';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

type DistributionMethod = components['schemas']['DistributionMethodEnum'];

function EstateFinalisation() {
    const store = useStore();
    const workspace = useWorkspace();

    const efTask = workspace.tasks.estate_finalisation;
    invariant(efTask);

    const query = useQuery(estateFinalisationTaskQuery(efTask));

    const onUpload = (file: File) =>
        store.uploadDocument(
            file,
            (file: File) =>
                uploadGenericDocument(
                    workspace.id,
                    file,
                    'Account Finalisation',
                    'estate_finalisation'
                ),
            () => {
                enqueueSnackbar('Document upload successful.', {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                queryClient.invalidateQueries(estateFinalisationTaskQuery(efTask));
            }
        );

    const updateMutation = useMutation(updateEstateFinalisationTask(efTask));

    const handleDistributionMethodChange = (value: DistributionMethod) => {
        updateMutation.mutate(
            { distribution_method: value },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(estateFinalisationTaskQuery(efTask));
                },
            }
        );
    };

    const handleMarkComplete = () => {
        updateMutation.mutate(
            { status: 'completed' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Account Finalisation accepted.', {
                        variant: 'success',
                    });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoComplete = () => {
        updateMutation.mutate(
            { status: 'ready_for_action' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(estateFinalisationTaskQuery(efTask));
            },
        });
    };

    if (!query.data) return <LoadingArea />;

    return (
        <QueryProgress query={query}>
            <PageLayout
                title={'Account Finalisation'}
                actions={
                    <CompleteTaskButton
                        completed={query.data?.status === 'completed'}
                        onComplete={handleMarkComplete}
                        onUndo={handleUndoComplete}
                        sx={{ pl: 2 }}
                    />
                }
            >
                <Card
                    disablePadding
                    title="Information sharing"
                    description="Indicate how the Account Finalisation documents will be shared"
                >
                    <RadioGroup
                        value={query.data.distribution_method}
                        onChange={(e) => {
                            const value = e.target.value as DistributionMethod;
                            handleDistributionMethodChange(value);
                        }}
                    >
                        <SmallRadio value="on_platform" label="Share via estateXchange" />
                        <SmallRadio value="off_platform" label="Send outside of estateXchange" />
                    </RadioGroup>
                </Card>
                <Fade in={query.data.distribution_method === 'on_platform'}>
                    <Box mt={3}>
                        <Card
                            disablePadding
                            title="Account Finalisation documents"
                            description="Upload Account Finalisation documents and any supporting documents"
                        >
                            <Stack gap={1}>
                                <UploadDropArea onUpload={onUpload} multiple>
                                    <UploadPlaceholder onUpload={onUpload} multiple />
                                </UploadDropArea>
                                <DocumentsTable
                                    documents={Array.from(query.data?.other_documents || [])}
                                    onDelete={handleDelete}
                                />
                            </Stack>
                        </Card>
                    </Box>
                </Fade>
            </PageLayout>
        </QueryProgress>
    );
}

export default EstateFinalisation;
