import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

type UserConsentDetail = components['schemas']['UserConsentDetail'];

export function userConsentQuery() {
    return {
        queryKey: ['userConsent'],
        queryFn: ({ signal }) =>
            api<UserConsentDetail>({
                url: '/auth/user_consent_detail/',
                signal,
            }).then((response) => response.data),
        staleTime: Infinity,
    } satisfies UseQueryOptions;
}

type UserConsentRequest = components['schemas']['UserConsentRequest'];

export function updateUserConsentMutation() {
    return {
        mutationFn: (data) =>
            api({
                url: '/auth/user_consent/',
                method: 'POST',
                data: data,
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: userConsentQuery().queryKey });
        },
    } satisfies UseMutationOptions<any, any, UserConsentRequest, any>;
}
