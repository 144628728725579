import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { components } from '../schema';
import { api } from '../utils';

type request = components['schemas']['DeathCertificateVerificationRequest'];
type response = components['schemas']['DeathCertificateVerification'];
type error = components['schemas']['StandaloneToolsDeathCertificateVerificationCreateError'];

export function createDeathCertificateVerificationMutation() {
    return {
        mutationFn: (data: request) =>
            api<response>({
                url: `/standalone_tools/death_certificate_verification`,
                method: 'POST',
                data,
            }).then((response) => response.data),
    } satisfies UseMutationOptions<response, AxiosError<error, any>, request, any>;
}
