import { UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

export type User = components['schemas']['User'];

export function userQuery() {
    return {
        queryKey: ['user'],
        queryFn: ({ signal }) =>
            api<User>({ url: '/auth/user/', signal })
                .then((response) => response.data)
                .catch((error) => {
                    // No need to panic on a 401 because it just means that no user
                    // is logged in.
                    if (error.response?.status === 401) return null;
                    throw error;
                }),
        staleTime: Infinity,
    } satisfies UseQueryOptions;
}

export function fetchUser() {
    return queryClient.fetchQuery(userQuery());
}

export function isLoggedIn(user: User | null): boolean {
    return !!user && !user.is_staff;
}

export function updateUser(data: Partial<User> | FormData) {
    return api<User>({
        url: '/auth/user/',
        method: 'PATCH',
        data,
    }).then((response) => queryClient.setQueryData(userQuery().queryKey, response.data));
}

export function logout() {
    return api({
        url: '/auth/logout/',
        method: 'POST',
    }).then(() => queryClient.invalidateQueries({ queryKey: userQuery().queryKey }));
}

export type Permission =
    | 'workspaces'
    | 'death_cert_verification'
    | 'death_check_single'
    | 'death_check_bulk'
    | 'gor_retrieval'
    | 'e_voi';

export function canViewWorkspaces(user?: User | null) {
    return hasPermission('workspaces', user);
}

export function canViewStandaloneTools(user?: User | null) {
    return (
        hasPermission('death_cert_verification', user) ||
        hasPermission('death_check_single', user) ||
        hasPermission('death_check_bulk', user) ||
        hasPermission('gor_retrieval', user) ||
        hasPermission('e_voi', user)
    );
}

export function hasPermission(permission: Permission, user?: User | null): boolean {
    return !!user?.computed_permissions.includes(permission);
}
