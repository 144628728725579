import { Avatar, AvatarProps } from '@mui/material';
import * as React from 'react';
import { components } from '../api/schema';

type User = components['schemas']['User'];

export interface UserAvatarProps extends AvatarProps<React.ElementType> {
    user: User;
    size?: number;
}

export default React.forwardRef<HTMLDivElement, UserAvatarProps>(function UserAvatar(props, ref) {
    const { user, size, ...avatarProps } = props;

    const name = user.name || undefined;

    const initials = name
        ?.split(' ')
        .slice(0, 2)
        .map((word) => word.charAt(0))
        .join('')
        .toUpperCase();

    return (
        <Avatar
            ref={ref}
            {...avatarProps}
            sx={{
                width: size,
                height: size,
                color: 'background.paper',
                fontSize: size ? size * 0.4 + 'px' : undefined,
                lineHeight: 1.2,
                fontWeight: 'medium',
                cursor: 'inherit',
                verticalAlign: 'middle',
                ...avatarProps.sx,
            }}
            alt={name}
        >
            {initials}
        </Avatar>
    );
});
