import { createContext, useContext } from 'react';
import { UploadStore } from './uploadStore';

const StoreContext = createContext<UploadStore | undefined>(undefined);

export const StoreProvider = StoreContext.Provider;

export function useStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}
