import { QueryFunctionContext, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components } from './schema';
import { api } from './utils';

export type DeceasedDetail = components['schemas']['DeceasedDetail'];

export function deceasedDetailQuery(id: string) {
    return {
        queryKey: ['deceasedDetail', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<DeceasedDetail>({
                url: `/deceased_details/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export function updateDeceasedDetail() {
    return {
        mutationFn: (data: Partial<DeceasedDetail>) =>
            api<DeceasedDetail>({
                url: `/deceased_details/${data.id}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onMutate: (data) => {
            queryClient.setQueryData(
                deceasedDetailQuery(data.id!).queryKey,
                (oldData: DeceasedDetail) => {
                    return {
                        ...oldData,
                        ...data,
                    };
                }
            );
        },
    } satisfies UseMutationOptions<DeceasedDetail, any, Partial<DeceasedDetail>, any>;
}
