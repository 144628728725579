import {
    Paper,
    PaperProps,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useMemo } from 'react';
import { DocumentUnion } from '../../api/document';
import { components } from '../../api/schema';
import TableSortLabel from '../TableSortLabel';
import {
    CellComponentProps,
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowStatus,
    DocumentTableRowUploadedByUser,
} from './DocumentsTableCells';
import DocumentTableRow from './DocumentsTableRow';

import useSort from '../../hooks/useSort';
export interface BaseDocument {
    id: string;
    workspace: string;
    membership: string | null;
    file?: string;
    file_name?: string | null;
    task_type?: components['schemas']['TaskTypeEnum'] | components['schemas']['NullEnum'] | null;
    created_at: string;
    uploaded_by: components['schemas']['CreatedByUser'];
    document_type?: string;
    is_infected: boolean | null;
}

export interface Column {
    id: string;
    label?: string;
    sortable?: string;
    sx?: SxProps;
    Component: React.ComponentType<CellComponentProps>;
}

interface DocumentsTableProps extends PaperProps {
    documents?: DocumentUnion[];
    /**
     * Defaults to file, createdAt and uploadedBy.
     */
    columns?: Column[];
    /**
     * In addition to the default columns.
     */
    extraColumns?: Column[];
    defaultSort?: string;
    placeholder?: React.ReactNode;
    onDelete?: (id: string) => void;
}

const defaultColumns = [
    {
        id: 'file',
        label: 'File',
        Component: DocumentTableRowFile,
    },
    {
        id: 'createdAt',
        label: 'Uploaded',
        Component: DocumentTableRowCreatedAt,
    },
    {
        id: 'uploadedBy',
        label: 'Uploaded by',
        Component: DocumentTableRowUploadedByUser,
    },
];

function DocumentsTable({
    documents,
    columns: columnsOverride,
    extraColumns,
    defaultSort,
    placeholder,
    onDelete,
    ...paperProps
}: DocumentsTableProps) {
    const { sortColumn, sortDirection, sortLink } = useSort(defaultSort);

    const isSortActive = (column: Column) => sortColumn === column.sortable;

    const columns = useMemo(() => {
        const baseColumns: Column[] = columnsOverride ? columnsOverride : defaultColumns;

        return [
            ...baseColumns,
            ...(extraColumns || []),
            {
                id: 'status',
                label: '',
                sx: { width: 0, p: 0 },
                Component: DocumentTableRowStatus,
            },
        ] as Column[];
    }, [columnsOverride, extraColumns]);

    return (
        <TableContainer
            component={Paper}
            {...paperProps}
            sx={{ display: 'flex', overflow: 'hidden', borderRadius: 0, ...paperProps.sx }}
        >
            {documents?.length ? (
                <Table
                    stickyHeader
                    sx={{
                        whiteSpace: 'nowrap',
                        '& .MuiTableCell-stickyHeader': {
                            bgcolor: 'background.paper',
                        },
                        '& tr > *:first-child': { pl: 2 },
                        '& tr > *:last-child': { pr: 2 },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index} sx={column.sx}>
                                    {column.sortable ? (
                                        <TableSortLabel
                                            label={column.label}
                                            sortLink={sortLink(column.sortable)}
                                            sortDirection={sortDirection}
                                            isActive={isSortActive(column)}
                                        />
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                            <TableCell sx={{ width: 0 }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document, index) => (
                            <DocumentTableRow
                                key={index}
                                columns={columns}
                                document={document}
                                onDelete={onDelete}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                placeholder
            )}
        </TableContainer>
    );
}

export default DocumentsTable;
