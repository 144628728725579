import {
    DialogContent,
    DialogTitle,
    Drawer,
    DrawerProps,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { NoteMeta } from '../../../api/notes';
import useUser from '../../../hooks/useUser';

interface NoteDrawerProps extends DrawerProps {
    onClose?: () => void;
    note: NoteMeta;
}

export default function NoteDrawer({ onClose, note, ...drawerProps }: NoteDrawerProps) {
    const user = useUser();

    return (
        <Drawer anchor="right" SlideProps={{ appear: true }} onClose={onClose} {...drawerProps}>
            <Stack sx={{ flexGrow: 1, width: '40rem' }}>
                <DialogTitle component={Stack} direction="row" gap={2} alignItems="center">
                    <IconButton edge="start" aria-label="close" onClick={() => onClose?.()}>
                        <IconX />
                    </IconButton>
                    Note
                </DialogTitle>
                <DialogContent>
                    <Stack gap={4}>
                        <Stack gap={0.2}>
                            <Typography variant="body1">Note left by</Typography>
                            <Typography variant="body2">{note.created_by.name}</Typography>
                        </Stack>
                        <Stack gap={0.2}>
                            <Typography variant="body1">Theme</Typography>
                            <Typography variant="body2">{note.theme}</Typography>
                        </Stack>
                        <Stack gap={0.2}>
                            <Typography variant="body1">Content</Typography>
                            <Typography variant="body2">{note.content}</Typography>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Stack>
        </Drawer>
    );
}
