import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    alpha,
    useTheme,
} from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
    RequestMeta,
    RequestParams,
    deleteRequestMutation,
    requestsQuery,
} from '../../../api/requests';
import { User } from '../../../api/user';
import DateTimeString from '../../../components/DateTimeString';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchInput from '../../../components/SearchInput';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import TableRowControls from '../../../components/TableRowControls';
import TableSortLabel from '../../../components/TableSortLabel';
import TruncatedTableCell from '../../../components/TruncatedTableCell';
import useSort from '../../../hooks/useSort';
import useUser from '../../../hooks/useUser';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import { ThemesIssuesMap } from './';
import RequestDrawer from './RequestDrawer';
import RequestStatus from './RequestStatus';
import SendRequestDialog from './SendRequestDialog';

type RequestsProps = {
    issues: ThemesIssuesMap;
    userType: Exclude<User['user_type'], 'admin'>;
};

function Requests({ issues }: RequestsProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const workspace = useWorkspace();
    const user = useUser();
    const { showModal } = useModal();

    const search = searchParams.get('search') || '';

    const status = searchParams.get('status') || 'open';

    function statusLink(status?: string) {
        const params = new URLSearchParams(searchParams);
        status ? params.set('status', status) : params.delete('status');
        return `?${params}`;
    }

    const defaultSort = '-updated_at';
    const { sortParam, sortColumn, sortDirection, sortLink } = useSort(defaultSort);

    const isActive = (column: string) => sortColumn === column;

    const organisation = searchParams.get('organisation') || undefined;

    const queryOptions: RequestParams = {
        organisation: organisation,
        workspace: workspace.id,
        is_active: status === 'open' ? true : false,
        search: search,
        ordering:
            sortColumn === 'description'
                ? sortDirection === 'desc'
                    ? '-theme_label,-issue'
                    : 'theme_label,issue'
                : sortParam,
    };

    const query = useQuery({
        ...requestsQuery(queryOptions),
        placeholderData: (previousData, _) => previousData,
    });

    const senders: SearchParamSelectOption[] =
        query.data?.results
            .map((result) => ({
                id: result.sender,
                label: result.sender_organisation_name,
            }))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    const recipients: SearchParamSelectOption[] =
        query.data?.results
            .map((result) => ({
                id: result.recipient,
                label: result.recipient_organisation_name,
            }))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    // Combine and remove duplicates
    const organisations: SearchParamSelectOption[] = [...senders, ...recipients].filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    return (
        <PageLayout
            title={
                <Stack direction="row" gap={3} alignItems="flex-end">
                    Requests
                    <Tabs value={status} className="underlined" sx={{ pb: 1 }}>
                        <Tab
                            label={'Open'}
                            value={'open'}
                            className="underlined"
                            component={RouterLink}
                            to={statusLink('open')}
                            state={{ keepPreviousData: 'false' }}
                        />
                        <Tab
                            label={'Completed'}
                            value={'resolved'}
                            className="underlined"
                            component={RouterLink}
                            to={statusLink('resolved')}
                            state={{ keepPreviousData: 'false' }}
                        />
                    </Tabs>
                </Stack>
            }
            actions={
                <Button
                    variant="contained"
                    onClick={() =>
                        showModal(SendRequestDialog, {
                            sentIssues: issues,
                        })
                    }
                >
                    Create Request
                </Button>
            }
        >
            <Stack direction="row" gap={2} alignItems="center">
                <SearchParamSelect
                    placeholder="Filter by organisation"
                    options={organisations}
                    parameter="organisation"
                />

                <SearchInput
                    placeholder="Search..."
                    value={search}
                    sx={{
                        backgroundColor: 'background.paper',
                    }}
                    onUpdate={(q) => {
                        const params = new URLSearchParams(searchParams);
                        q ? params.set('search', q) : params.delete('search');
                        setSearchParams(params);
                    }}
                />
                {query.isRefetching ? <CircularProgress size={20} /> : null}
            </Stack>
            <QueryProgress query={query}>
                {!query.data?.results.length ? (
                    <Placeholder
                        title="No Requests yet"
                        description={`Request additional information from ${
                            user?.user_type === 'de_representative'
                                ? 'Workspace Participants'
                                : 'the Professional Representative'
                        }`}
                    />
                ) : (
                    <Stack gap={2} sx={{ mt: -1 }}>
                        <TableContainer sx={{ overflowX: 'auto' }}>
                            <Table sx={{ whiteSpace: 'nowrap' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                label="Description"
                                                sortLink={sortLink('description')}
                                                sortDirection={sortDirection}
                                                isActive={isActive('description')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                label="Raised by"
                                                sortLink={sortLink('sender_organisation_name')}
                                                sortDirection={sortDirection}
                                                isActive={isActive('sender_organisation_name')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                label="Recipient"
                                                sortLink={sortLink('recipient_organisation_name')}
                                                sortDirection={sortDirection}
                                                isActive={isActive('recipient_organisation_name')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                label="Last updated"
                                                sortLink={sortLink('updated_at')}
                                                sortDirection={sortDirection}
                                                isActive={isActive('updated_at')}
                                            />
                                        </TableCell>
                                        <TableCell>Next action</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {query.data?.results.map((request) => (
                                        <RequestRow key={request.id} request={request} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                )}
            </QueryProgress>
        </PageLayout>
    );
}

interface DeleteRequestDialogProps extends DialogProps {
    request: RequestMeta;
    onClose?: () => void;
}

function DeleteRequestDialog({ request, open, onClose, ...dialogProps }: DeleteRequestDialogProps) {
    const deleteRequest = useMutation(deleteRequestMutation(request.id));
    const handleDelete = () => {
        deleteRequest.mutateAsync().then(() => {
            onClose?.();
        });
    };
    return (
        <Dialog open={open} onClose={onClose} {...dialogProps}>
            <DialogTitle>Delete Request</DialogTitle>
            <DialogContent>
                <Card>Are you sure you want to delete this Request?</Card>
            </DialogContent>

            <DialogActions>
                <Button color="inherit" onClick={onClose}>
                    Close
                </Button>
                <Button variant="contained" color="error" onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface RequestRow {
    request: RequestMeta;
}

function RequestRow({ request }: RequestRow) {
    const theme = useTheme();
    const user = useUser();
    const isRequestCreatedByUser = user?.de_rep_organisation
        ? request.sender === user?.de_rep_organisation.id
        : request.sender === user?.institution_organisation.id;

    const isRequestHighlighted = isRequestCreatedByUser
        ? request.status === 'response_provided'
        : request.status === 'awaiting_response';

    const { showModal } = useModal();

    return (
        <>
            <TableRow
                key={request.id}
                onClick={() => showModal(RequestDrawer, { request })}
                hover
                sx={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    backgroundColor: isRequestHighlighted
                        ? alpha(theme.palette.secondary.light, 0.3)
                        : 'inherit',
                    '&:hover': {
                        backgroundColor: isRequestHighlighted
                            ? 'secondary.light !important'
                            : 'background.paper',
                    },
                }}
            >
                <TruncatedTableCell>
                    <Stack gap={0.5}>
                        <Typography variant="body2">{request.theme_label}</Typography>
                        <Typography variant="body3">{request.issue}</Typography>
                    </Stack>
                </TruncatedTableCell>
                <TruncatedTableCell>{request.sender_organisation_name}</TruncatedTableCell>
                <TruncatedTableCell>{request.recipient_organisation_name}</TruncatedTableCell>
                <TableCell>{DateTimeString(request.updated_at)}</TableCell>
                <TableCell>
                    <RequestStatus request={request} />
                </TableCell>
                <TableRowControls>
                    {request.has_delete_permission ? (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                showModal(DeleteRequestDialog, {
                                    request,
                                });
                            }}
                        >
                            <IconTrash />
                        </IconButton>
                    ) : null}
                </TableRowControls>
            </TableRow>
        </>
    );
}

export default Requests;
