import { Alert, AlertProps, Box, Slide, SlideProps } from '@mui/material';
import { colors } from '../theme/palette';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export interface ActionsSnackbarProps extends AlertProps {
    open: boolean;
}

function ActionsSnackbar({ open, ...alertProps }: ActionsSnackbarProps) {
    return (
        // To center the slide within it's relatively positioned parent, ensure parent has display: flex and justify-content: center
        <Box sx={{ position: 'fixed', bottom: 14, marginLeft: 0 }}>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <Alert
                    variant="filled"
                    elevation={1}
                    {...alertProps}
                    sx={{
                        backgroundImage: 'none',
                        alignItems: 'center',
                        fontWeight: 300,
                        borderRadius: 5,
                        p: 0.75,
                        px: 2,
                        pl: 3,
                        backgroundColor: colors.charcoal,
                        color: colors.white,
                        '& .MuiAlert-action': { pt: 0, pl: 6 },
                        ...alertProps.sx,
                    }}
                />
            </Slide>
        </Box>
    );
}

export default ActionsSnackbar;
