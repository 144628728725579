import { TableCell, TableCellProps } from '@mui/material';

interface TruncatedTableCellProps extends TableCellProps {
    maxWidth?: string | number;
}

function TruncatedTableCell({ maxWidth = '30ch', ...props }: TruncatedTableCellProps) {
    return (
        <TableCell
            {...props}
            sx={{
                maxWidth,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...props.sx,
            }}
        />
    );
}

export default TruncatedTableCell;
