import { Button, ButtonProps } from '@mui/material';
import { IconShare2 } from '@tabler/icons-react';
import { Link as RouterLink } from 'react-router-dom';

export interface DownloadButtonProps extends ButtonProps {
    url: string;
    filename?: string;
}

export default function DownloadButton({ url, filename, ...buttonProps }: DownloadButtonProps) {
    return (
        <Button
            variant="outlined"
            color="inherit"
            component={RouterLink}
            to={url}
            target="_blank"
            startIcon={<IconShare2 />}
            {...buttonProps}
        />
    );
}
