import { Box, GlobalStyles } from '@mui/material';
import { PropsWithChildren } from 'react';
import backgroundImage from '../../assets/Aerial-full.png';
import EXCLogo from '../../components/EXCLogo';

function AuthLayout({ children }: PropsWithChildren) {
    return (
        <div>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    // transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    // backdropFilter: 'blur(12px)',
                    backgroundColor: 'background.main',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        p: 4,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                    }}
                >
                    <EXCLogo mode="flat" style={{ width: 'auto', height: '15px' }} />
                </Box>

                {children}
            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100vw',

                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${backgroundImage})`,
                }}
            />
        </div>
    );
}

export default AuthLayout;
