import { Box, Link } from '@mui/material';
import { IconFileSearch } from '@tabler/icons-react';
import { Link as RouterLink } from 'react-router-dom';
import Placeholder from './Placeholder';

function NotFoundPlaceholder() {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                backgroundColor: 'background.main',
            }}
        >
            <Placeholder
                IconComponent={IconFileSearch}
                title="Page not found"
                description="It looks like nothing was found at this location."
            >
                <Link component={RouterLink} to="/">
                    Go Home
                </Link>
            </Placeholder>
        </Box>
    );
}

export default NotFoundPlaceholder;
