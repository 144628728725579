import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { queryClient } from '../../api/queryClient';
import {
    RelatedPersonMeta,
    deleteVoIDocumentMutation,
    relatedPeopleQuery,
    relatedPersonQuery,
    uploadVoIDocumentMutation,
} from '../../api/relatedPerson';
import QueryProgress from '../QueryProgress';
import UploadGenericDocumentDialog from '../UploadGenericDocumentDialog';
import DocumentsTable from '../documentsTable/DocumentsTable';
import { TabProps } from './RelatedPersonDrawer';

interface RelatedPersonVoIProps extends Omit<TabProps, 'person'> {
    person: RelatedPersonMeta;
}

const RelatedPersonIdentityDocuments: FC<RelatedPersonVoIProps> = ({ person, readOnly }) => {
    const { showModal } = useModal();

    const query = useQuery(relatedPersonQuery(person.id));
    const personDetail = query.data;

    const uploadDocumentMutation = useMutation({
        ...uploadVoIDocumentMutation(),
        onSuccess: () => {
            enqueueSnackbar('Document uploaded', { variant: 'success' });
            queryClient.invalidateQueries(relatedPersonQuery(person.id));
            queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
        },
    });

    const handleUpload = async (file: File) => {
        if (!personDetail) return;

        await uploadDocumentMutation.mutateAsync({
            id: personDetail.id,
            file,
        });
    };

    const deleteDocumentMutation = useMutation(deleteVoIDocumentMutation());

    const handleDeleteDocument = (id: string) => {
        deleteDocumentMutation.mutateAsync(id).then(() => {
            queryClient.invalidateQueries(relatedPersonQuery(person.id));
            queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
        });
    };

    return (
        <Stack gap={4} flexGrow={1}>
            {!readOnly ? (
                <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ width: 'fit-content' }}
                    onClick={() =>
                        showModal(UploadGenericDocumentDialog, {
                            title: 'Upload Identity Documents',
                            taskType: 'proof_of_identity',
                            forceRequireConsent: true,
                            onUpload: handleUpload,
                        })
                    }
                >
                    Upload identity documents
                </Button>
            ) : null}
            <QueryProgress query={query}>
                {!!personDetail?.scanned_id_documents?.length ? (
                    <DocumentsTable
                        documents={[...personDetail?.scanned_id_documents]}
                        onDelete={!readOnly ? handleDeleteDocument : undefined}
                    />
                ) : readOnly ? (
                    <Typography>Not provided</Typography>
                ) : null}
            </QueryProgress>
        </Stack>
    );
};

export default RelatedPersonIdentityDocuments;
