import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { RelatedPersonMeta, deleteRelatedPersonMutation } from '../../api/relatedPerson';

interface RemoveRelatedPersonDialogProps extends DialogProps {
    person: RelatedPersonMeta;
    onClose?: () => void;
    onConfirm?: () => void;
}

const RemoveRelatedPersonDialog: FC<RemoveRelatedPersonDialogProps> = ({ person, ...props }) => {
    const mutation = useMutation(deleteRelatedPersonMutation(person.id));

    const handleRemove = () => {
        mutation.mutateAsync().then(() => {
            props.onClose?.();
            props.onConfirm?.();
            enqueueSnackbar('Person removed.', { variant: 'success' });
        });
    };

    return (
        <Dialog maxWidth="xs" fullWidth {...props}>
            <DialogTitle>Are you sure you want to remove this person?</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {person.full_name} will be removed. This action cannot be undone.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="inherit">
                    Cancel
                </Button>
                <Button onClick={handleRemove} variant="contained" color="error">
                    Remove person
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveRelatedPersonDialog;
