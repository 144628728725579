import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

const typography: TypographyOptions = {
    fontFamily: [
        'Lexend',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    h1: {
        fontWeight: 500,
        fontSize: '8.5rem',
        lineHeight: '100%',
        letterSpacing: '-2px',
    },
    h2: {
        fontWeight: 400,
        fontSize: '2.75rem',
        lineHeight: '120%',
        letterSpacing: '-0.02em',
        marginInlineStart: '0rem',
    },
    h3: {
        fontWeight: 300,
        fontSize: '2rem',
        lineHeight: '160%',
        letterSpacing: '-0.02em',
    },
    h4: {
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: '120%',
        letterSpacing: '-0.02em',
    },
    h5: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '120%',
        letterSpacing: '-0.02em',
    },
    h6: {
        fontWeight: 300,
        fontSize: '1.5rem',
        lineHeight: '160%',
    },
    subtitle1: {
        fontWeight: 300,
        fontSize: '1.5rem',
        lineHeight: '160%',
    },
    subtitle2: {
        fontWeight: 300,
        fontSize: '1.125rem',
        lineHeight: '160%',
    },
    body1: {
        fontWeight: 300,
        fontSize: '1rem',
        lineHeight: '160%',
    },
    body2: {
        fontWeight: 300,
        fontSize: '0.875rem',
        lineHeight: '120%',
    },
    body3: {
        fontWeight: 300,
        fontSize: '.75rem',
        lineHeight: '130%',
    },
    button: {
        textTransform: 'none',
    },
};

export default typography;
