import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
// import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import { createNoteMutation } from '../../../api/notes';
import FormGrid from '../../../components/FormGrid';
import useUser from '../../../hooks/useUser';
import { characterLimit, noSpecialChars, requiredString } from '../../../validationRules';
import { useWorkspace } from '../workspaceContext';

interface CreateNoteDialogProps extends DialogProps {
    onClose?: () => void;
}

type Theme = {
    label: string;
};

const validationRules = z.object({
    theme: requiredString,
    comment: requiredString.and(characterLimit(500)).and(noSpecialChars),
});

function CreateNoteDialog({ onClose, ...dialogProps }: CreateNoteDialogProps) {
    const workspace = useWorkspace();
    const user = useUser();
    const createNote = useMutation(createNoteMutation());

    const themeList: Theme[] = [
        { label: 'Estate Details' },
        { label: 'Proof of Death' },
        { label: 'Proof of Authority' },
        { label: 'Proof of Identity' },
        { label: 'Account Instructions' },
        { label: 'Statement of Position' },
        { label: 'Account Finalisation' },
    ];

    // const createNote = useMutation(createNoteMutation());
    // Handles form values
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            theme: null,
            comment: null,
        },
        resolver: zodResolver(validationRules),
    });

    function handleSuccess(values: any) {
        createNote
            .mutateAsync({
                workspace: workspace.id,
                theme: values.theme,
                content: values.comment,
            })
            .then(() => {
                // reset all of the form values on successful submit
                formMethods.setValue('theme', null);
                formMethods.setValue('comment', null);
                onClose?.();
            });
    }

    return (
        <Dialog maxWidth="md" fullWidth onClose={onClose} scroll="body" {...dialogProps}>
            <FormContainer formContext={formMethods} onSuccess={handleSuccess}>
                <DialogTitle>New Note</DialogTitle>
                <DialogContent>
                    <FormGrid>
                        <Grid item xs={6}>
                            <SelectElement
                                name="theme"
                                label="Theme"
                                required
                                fullWidth
                                options={themeList}
                                valueKey="label"
                                labelKey="label"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldElement
                                multiline
                                name="comment"
                                label="Comments"
                                required
                                fullWidth
                                minRows={5}
                                maxRows={10}
                            />
                        </Grid>
                    </FormGrid>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'end' }}>
                    <Button color="inherit" onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="contained" color="success" type="submit">
                        Save
                    </Button>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default CreateNoteDialog;
