import { Button, FormLabel, IconButton, Stack, StackProps } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddressField from './AddressField';

interface AddressFieldArrayProps extends StackProps {
    name: string;
    label: ReactNode;
    addMessage?: string;
}

function AddressFieldArray({
    name,
    label,
    addMessage = 'Add an address',
    ...stackProps
}: AddressFieldArrayProps) {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    return (
        <Stack gap={1} sx={{ pb: 3 }}>
            <FormLabel>{label}</FormLabel>
            <Stack {...stackProps}>
                {fields.map((field, index: number) => (
                    <Stack direction="row" key={field.id} alignItems="flex-start">
                        <Stack width="100%">
                            <AddressField name={`${name}[${index}]`} required disableClearable />
                        </Stack>
                        <IconButton onClick={() => remove(index)} sx={{ ml: 1, mt: 6 }}>
                            <IconTrash size={20} />
                        </IconButton>
                    </Stack>
                ))}
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() =>
                        append({
                            street: '',
                            city: '',
                            state: '',
                            postcode: '',
                        })
                    }
                    sx={{ width: 'fit-content' }}
                >
                    {addMessage}
                </Button>
            </Stack>
        </Stack>
    );
}

export default AddressFieldArray;
